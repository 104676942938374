import axios from "axios";
import React from "react";
import { useState } from "react";
import { baseUrl } from "../../baseUrl";
import Layout from "../Layout/Layout";
import swal from 'sweetalert';

function AgentRegistration() {
  const [message, setmessage] = useState("");
  const [errorMessage, setErrorMess] = useState("");
//const [loading set]

  console.log(errorMessage, message);

  const submitHandle = async (event) => {

    event.preventDefault();

    const f_name = event.target.f_name.value;
    const l_name = event.target.l_name.value;
    const email = event.target.email.value;
    const phone = event.target.phone.value;
    const company = event.target.company.value;
    const location = event.target.location.value;
    const details = event.target.message.value;

    const data = { f_name, l_name, email, phone, company, location, details };

    if (f_name !== "" && email !== "") {
      await axios
        .post(baseUrl + "/agent/enquiry", data)
        .then((res) => {
          console.log(res.data);
          event.target.reset();
          
          if (res.status == 200) {
              setmessage("Thanks for your enquiry, We will get back to you soon!");
              swal({
                title:"Bella Islas says",
                text:  res.data.message,
                 icon: "success",
                button: "OK",
                // timer: 3000,
              });
            setErrorMess("");
          }
        })
        .catch((error) => {
          console.log(error);
          if(
            error
          ){

              setErrorMess(error.message)
          }
          else{
            setErrorMess("")
          }
        });
    } else {
      setErrorMess("Please Fill required Field !");
    }
  };
  setTimeout(() => {
    setmessage("");
    setErrorMess("");
  }, 3000);

  return (
    <Layout>
      {/* <!-- login section start here --> */}
      <section class="login-section registration">
        <div class="container-sm">
          <div class="wrapper">
            <h2>Agent Signup Form</h2>
            <form onSubmit={submitHandle} class="user-login">
              <div class="form_group">
                <input type="text" id="f_name" placeholder="First Name *" />
              </div>
              <div class="form_group">
                <input type="text" id="l_name" placeholder="Last Name *" />
              </div>
              <div class="form_group">
                <input type="email" id="email" placeholder="Email Id *" />
              </div>
              <div class="form_group">
                <input type="text" id="phone" placeholder="Phone No *" />
              </div>
              <div class="form_group">
                <input type="text" id="company" placeholder="Company *" />
              </div>
              <div class="form_group">
                <input type="text" id="location" placeholder="Location *" />
              </div>
              <div class="form_group">
                <textarea
                  id="message"
                  placeholder="Enter Your Details *"
                ></textarea>
              </div>
              <div class="form_group">
                <button type="submit">Submit</button>
              </div>
            </form>
            <div style={{ fontSize: "15px", textAlign: "center" , fontWeight:"400" }}>
              {message}
            </div>
            <div style={{ fontSize: "15px", textAlign: "center" ,fontWeight:"400" }}>
              {errorMessage}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- login section end here --> */}
    </Layout>
  );
}

export default AgentRegistration;
