import React from "react";
import Layout from "../Layout/Layout";
import "../../Styles/mainstyle/style.css";
import img1 from "../../assets/images/property-gallery-img1.jpg";
import img2 from "../../assets/images/property-gallery-img2.jpg";
import img3 from "../../assets/images/property-gallery-img3.jpg";
import img4 from "../../assets/images/property-gallery-img4.jpg";
import loader from "../../assets/images/loader.svg";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Slider from "react-slick";
import { toast } from 'react-toastify'
import swal from 'sweetalert';
import GoogleMaps from "../Sections/LocationCoponents/GoogleMaps";
import MapsComp2 from "../Sections/LocationCoponents/MapsComp2";

const img = [
  require("../../assets/images/bookmark-img1.jpg"),
  require("../../assets/images/bookmark-img2.jpg"),
  require("../../assets/images/bookmark-img3.jpg"),
];

const emailRegex = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);
function PropertyDetails() {
  const propertyId = useParams();
  // console.log(propertyId);
  const navigate = useNavigate();
  const [popup, setpopup] = useState(false);
  const [details, setDetails] = useState();
  const [error, setError] = useState("");
  const [success, setsuccess] = useState("");
  const [errorCall, seterrorCall] = useState("");
  const [company, setCompany] = useState("");
  const [relatedProperty, setRelatedProperty] = useState([]);
  const [loading, setloading] = useState(false);
  const [addSucces, setaddSucces] = useState("");
  const islogin = JSON.parse(localStorage.getItem("login"));
  const [emptyMessage, setEmptyMessage] = useState("");
  const [userInformation, setuserInformation] = useState()
  const userinfo = JSON.parse(localStorage.getItem("user"));
  const [Message, setMessage] = useState("");
  const [check, setcheck] = useState(true);
  const [CallAgentName, setCallAgentName] = useState("")
  const [CallAgentEmail, setCallAgentEmail] = useState("")
  const [CallAgentPhone, setCallAgentPhone] = useState("")
  const [AddFav, setAddFav] = useState(false);

   console.log("for loading"  ,details?.data?.type );
  const [imageGallery, setImageGallery] = useState([])
  var data = details?.data?.town?.replace(/[\[,"\]']+/g, "  ");
  var propertyType = details?.data?.type?.toString().replace(/[\[,"\]']+/g, " ");

  var outside_spacedata = details?.data?.outside_space?.toString().replace(/[\[,"\]']+/g, ",  ");
  var near_bydata =  details?.near_by?.toString().replace(/[\[,"\]']+/g, " , ")
  
  //?.data.replace(/[\[,"\]']+/g, " ");
  var pooldata = details?.pool?.data?.replace(/[\[,"\]']+/g, "  ");
  console.log(details, ">>>>>>>>>>>>>>>>>>" ,details?.pool);
  let result = data;
  //const { beds } = details;
  const [error2, seterror2] = useState({
    username: "",
    password: "",
    resetemailadd: ""
  });
  const logins_field = (e) => {

    const { name, value } = e.target;
    switch (name) {
      case 'name':
        error2.username =
          value.length > 4
            ? "" : "Email not valid."
          ;
        break;
      case 'phone':
        error2.password =
          value.length < 13
            ? "Minimum 8 characters required"
            : "";
        break;
      case 'email':
        error2.resetemailadd =
          emailRegex.test(value) ? "" : "Email not valid.";
        break;
      default:
        break;
    }
    if (name == "name") {
      setCallAgentName(value)
    }
    if (name == "phone") {
      setCallAgentPhone(value)
    }
    if (name == "email") {
      setCallAgentEmail(value)
    }
    seterror2(error2)
  }
  useEffect(() => {
    if (check) {
      setuserInformation(JSON.parse(localStorage.getItem("user")))
      setCallAgentName(JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))?.name : "")
      setCallAgentEmail(JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))?.email : "")
      setCallAgentPhone(JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))?.phone : "")
      setcheck(false)
    }
  }, [userInformation]);

  console.log(">>>>>>>>>>>>>>>", details?.english_description);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  console.log("res  === ", details?.price);
  const fetchproperty = async () => {
    try {
      const res = await axios.get(
        baseUrl + `/property?property_id=${propertyId.propertyId}`
      );
      console.log(res)
      const propertydetails = res.data;
      if (res.status == 200) {
        setloading(true);

      }

      // console.log("filtered >>", res.data?.data);
      if (propertydetails) {
        setDetails(propertydetails?.data?.xml);
        setRelatedProperty(propertydetails.data.related_property);
        setCompany(propertydetails.data.xml);

      }
    } catch (error) {
      if (error) {
        setloading(true);
      }
      // console.log(error.message);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchproperty();
  }, [propertyId.propertyId]);

  var num2 = "2"

  const callAgent = async (e) => {
    e.preventDefault();
    const name = CallAgentName;
    const email = CallAgentEmail;
    const contact = CallAgentPhone;
    const property_id = propertyId.propertyId;
    const token = JSON.parse(localStorage.getItem("token"));
    const agent_id = company?.user_id ? company?.user_id : "1"
    const request_viewing = "1"

    const data = { name, email, contact, property_id, agent_id, request_viewing };

    if (CallAgentName !== "" && CallAgentEmail !== "" && contact !== "" || error2.password == "" || error2.username == "" || error2.resetemailadd == "") {
      try {
        const res = await axios.post(baseUrl + `/property/call-agent`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const details = res;
        if (res) {
          e.target.reset()
          setCallAgentName("")
          setCallAgentPhone("")
          setCallAgentEmail("")

          swal({
            title:"Bella Islas says",
            text:  res.data.message,
            icon: "success",
            button: "OK",
            // timer: 3000,
          });
        }
        console.log("callagent res ", res.data);
        setMessage(res.data.message)

      } catch (error) {
        console.log(error.message);
        setEmptyMessage(error.message)
      }
    } else {
      setEmptyMessage("Fill All Field");
    }
  };

  setTimeout(() => {
    setEmptyMessage("");
    setMessage("")

  }, 5000);

  const callAgentForm = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = CallAgentEmail;
    const contact = CallAgentPhone;
    const property_id = propertyId.propertyId;
    const subject = e.target.subject.value;
    const message = e.target.message.value;
    const request_viewing = "2"
    const agent_id = company?.user_id ? company?.user_id : "1"


    const data = { name, email, contact, property_id, subject, message, request_viewing, agent_id };
    const token = JSON.parse(localStorage.getItem("token"));
    if (name !== "" && email !== "" && contact !== "" && subject !== "" && message !== "") {
      try {
        const res = await axios.post(
          baseUrl + `/property/call-agent-form`,
          data,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const details = res;
        if ((res.status = 200)) {
          setsuccess(res.data.message);
          e.target.reset();
          setCallAgentPhone("")
          setCallAgentEmail("")
          swal({
            title:"Bella Islas says",
            text:  res.data.message,
            icon: "success",
            button: "OK",
            // timer: 3000,
          });


          setTimeout(() => {
            setsuccess("");
          }, 5000);
        }
        //console.log("callagent res ", details);
        //setMessage(details)
      } catch (error) {
        // console.log(error.message);

        seterrorCall(error.message);
        swal({
          title:"Bella Islas says",
          text:  error.message,
          icon: "error",
          button: "OK",
          // timer: 3000,
        });
      }
    } else {
      seterrorCall("Please Fill All field");
    }
  };

  const token = JSON.parse(localStorage.getItem("token"))
    ? JSON.parse(localStorage.getItem("token"))
    : "";

  const postFavourite = async (property_id) => {
    // e.preventDefault();

    const body = { property_id: property_id };
    const res = await axios.post(
      baseUrl + "/Add/favourite-property",
      body,

      { headers: { Authorization: `Bearer ${token}` } }
    );
    const data = await res.data;
    if(res.status== 200){
      setAddFav(true)
           
swal({
  title:"Bella Islas says",
  text:  res.data.message,
   icon: "success",
  button: "OK",
  // timer: 3000,
});
    }
    // console.log(data,islogin, property_id);
  };
  const [imagesbox, setimagesbox] = useState()
  const [openModal, setopenModal] = useState(false)
  const [sliderNumber, setsliderNumber] = useState(0)

  console.log(imagesbox, "<<<<<<,", sliderNumber, details?.images?.image.length)

  console.log(openModal);
  useEffect(() => {
    setimagesbox(details?.images.image?.map((img) => img))
    // postFavourite();

  }, []);



  const handleOpenModal = (index) => {
    setsliderNumber(index)
    setopenModal(true)
  }



  const prevSlide = () => {
    sliderNumber === 0 ? setsliderNumber(details?.data.images?.image.length - 1) : setsliderNumber(sliderNumber - 1)
  }

  const nextSlide = () => {
    sliderNumber + 1 === details?.data?.images?.image.length ? setsliderNumber(0) : setsliderNumber(sliderNumber + 1)
  }


  let nf = new Intl.NumberFormat();


const [PicBighandle ,setPicBighandle] =useState(false)



  return (
    <>

      {openModal == true ?

        <div className='sliderWrap'>
          <div className="light_head">
            <span className="total_image">
              {`${sliderNumber}/${details?.data.images?.image.length}`}
            </span>
            <span className="right_btns">

              <span style={{ padding: "0 12px" }} onClick={()=>setPicBighandle(!PicBighandle)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z" /><path d="M16 3h6v6h-2V5h-4V3zM2 3h6v2H4v4H2V3zm18 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z" fill="rgba(236,240,241,1)" /></svg>
              </span>
              {/* <span style={{ padding: "0 12px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z" /><path d="M13 14h-2a8.999 8.999 0 0 0-7.968 4.81A10.136 10.136 0 0 1 3 18C3 12.477 7.477 8 13 8V3l10 8-10 8v-5z" fill="rgba(236,240,241,1)" /></svg>
              </span> */}

              <span className="" onClick={() => setopenModal(false)}>

                <span className="">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z" /><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" fill="rgba(236,240,241,1)" /></svg> </span>


              </span>
            </span>

          </div>

          <span className="next_btn" onClick={nextSlide}>
            <span className="arrow_n">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="rgba(236,240,241,1)" /></svg>  </span>
          </span>
          <span className="prev_btn" onClick={prevSlide}>
            <span className="arrow_n"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z" fill="rgba(236,240,241,1)" /></svg></span>

          </span>
          <div className='fullscreen'>
            <img className="show_image"
             style={{height:`${PicBighandle == true ? "100%" :"125vh"}` ,width:`${PicBighandle == true ? "100%" :"125vh"}`}}
              src={details?.data.images?.image[sliderNumber].url} />
          </div>

        </div> :

        <Layout >
          {loading !== true ? (
            <div className="loader_set">
              {" "}
              <img src={loader} alt="loading..." />{" "}
            </div>
          ) : (
            <>




              <section className="banner-gallery" >
                {details?.data?.images.image.length !== 0 ? (
                  <div className="container-sm">
                    <div className="back_home">
                      <p onClick={() => navigate(-1)}>
                        ← Back to search results
                      </p>
                    </div>
                    <div id="demo-test-gallery" className="demo-gallery">




                      {details?.data.images.image[0] ? (
                        <>
                          <a
                            //href={details?.images.image[0]?.url}
                            onClick={() => handleOpenModal(0)}
                            data-size="1600x1600"
                            //data-med={details?.images.image[0]?.url}
                            data-med-size="1024x1024"
                            data-author="Folkert Gorter"
                            className="galleryImg"
                          >
                            <figure >
                              <img
                                src={details?.data.images.image[0]?.url}
                                alt="no image"
                              />
                            </figure>
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                      {details?.data.images.image[1] ? (
                        <>
                          <a
                            // href={details?.images.image[1]?.url}
                            data-size="1600x1068"
                            onClick={() => handleOpenModal(1)}

                            // data-med={details?.images.image[1]?.url}
                            data-med-size="1024x683"
                            data-author="Samuel Rohl"
                            className="galleryImg"
                          >
                            <figure>
                              <img src={details?.data.images.image[1]?.url} alt="" />
                            </figure>
                          </a>
                        </>
                      ) : (
                        ""
                      )}

                      <a
                        // href={details?.images.image[2]?.url}
                        data-size="1600x1067"
                        onClick={() => handleOpenModal(2)}

                        // data-med={details?.images.image[2]?.url}
                        data-med-size="1024x683"
                        data-author="Ales Krivec"
                        className="galleryImg"
                      >
                        {details?.data.images.image[2] ? (
                          <figure>
                            <img src={details?.data?.images.image[2]?.url} alt="" />
                          </figure>
                        ) : (
                          ""
                        )}
                      </a>

                      {details?.data.images.image[4] ? (
                        <a
                          // href={details?.images.image[4]?.url}
                          data-size="1600x1067"
                          onClick={() => handleOpenModal(4)}

                          // data-med={details?.images.image[4]?.url}
                          data-med-size="1024x683"
                          data-author="Michael Hull"
                          className="galleryImg"
                        >
                          <figure>
                            <img src={details?.data.images.image[4]?.url} alt="" />
                          </figure>
                        </a>
                      ) : (
                        ""
                      )}

                      {details?.data.images.image[5] ? (
                        <a
                          // href={details?.images.image[5]?.url}
                          data-size="1600x1067"
                          onClick={() => handleOpenModal(5)}

                          // data-med={details?.images.image[5]?.url}
                          data-med-size="1024x683"
                          data-author="Thomas Lefebvre"
                          className="galleryImg"
                        >
                          <figure>
                            <img src={details?.data?.images.image[5]?.url} alt="" />
                          </figure>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>No Imeges</div>
                )}
              </section>
              {/* <!-- banner gallery section end here -->

    <!-- section property inner section start here --> */}
              <section className="property-inner">
                <div className="container-sm">
                  <div className="price_map flex">
                    <div className="col">
                      <h3>€{details?.price ? nf.format(details?.price) : ""}</h3>
                      <p style={{ marginBottom: "0" }}>
                        {data ? data : details?.town ? details?.town : ""}

                        {/* {details?.town.replace(/[\[,"\]']+/g," | " ) ? details?.town?.replace(/[\[,"\]']+/g," | " ) :"Not Added"} */}
                      </p>
                      <a href="#location">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
                        </svg>
                        See map
                      </a>
                    </div>
                    <div
                      className="col"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <span
                        onClick={() => {
                          if (islogin) {
                            postFavourite(propertyId?.propertyId);
                          } else {
                            swal({
                              title:"Bella Islas says",
                              text:  "Please Log In First !",
                              icon: "error",
                              button: "OK",
                              // timer: 3000,
                            });
                          }
                        }}
                        style={{ paddingRight: "12px" }}
                      >
                        {" "}
                        {addSucces == propertyId?.propertyId && AddFav== true ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0H24V24H0z" />
                            <path
                              d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z"
                              fill="rgba(255,140,0,1)"
                            />
                          </svg>
                        ) : (
                          // <svg
                          //   xmlns="http://www.w3.org/2000/svg"
                          //   onClick={() => setaddSucces(propertyId.propertyId)}
                          //   viewBox="0 0 24 24"
                          //   width="24"
                          //   height="24"
                          // >
                          //   <path fill="none" d="M0 0H24V24H0z" />
                          //   <path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z" />
                          // </svg>

                          <span
                            className="heart_hover"
                            onClick={() => setaddSucces(propertyId.propertyId)}
                          >
                            <i class="far fa-heart"></i>
                          </span>
                        )}
                        {/* <i onClick={()=> setaddSucces(true)} className="far fa-heart"></i>{" "} */}
                      </span>

                      {/* <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z" />
                      </svg>
                    </a> */}
                    </div>
                  </div>
                  <div className="wrap flex">
                    <div className="col">
                      <div className="heading-line">
                        <h3>Highlights</h3>
                      </div>
                      <ul className="highlight multyside">
                        {details?.town ?
                          <li className="flex">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="21"
                                viewBox="0 0 384 512"
                              >
                                <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
                              </svg>
                            </div>
                            <div className="content">
                              <h4
                                style={{ lineHeight: "10px", paddingTop: "25px" }}
                              >
                                Location
                              </h4>
                              <p>
                                {data ? data : details?.town ? details?.town : ""}
                              </p>
                            </div>
                          </li>
                          : ""
                        }
                        {details?.beds ?
                          <li className="flex">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="21"
                                viewBox="0 0 640 512"
                              >
                                <path d="M176 256c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm352-128H304c-8.84 0-16 7.16-16 16v144H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v352c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-48h512v48c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V240c0-61.86-50.14-112-112-112z" />
                              </svg>
                            </div>
                            <div className="content">
                              <h4>Bedrooms</h4>
                              <p>{details?.beds}</p>
                            </div>
                          </li>
                          : ""
                        }
                        {details?.data?.baths ?
                          <li className="flex">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="21"
                                viewBox="0 0 512 512"
                              >
                                <path d="M32,384a95.4,95.4,0,0,0,32,71.09V496a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V480H384v16a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V455.09A95.4,95.4,0,0,0,480,384V336H32ZM496,256H80V69.25a21.26,21.26,0,0,1,36.28-15l19.27,19.26c-13.13,29.88-7.61,59.11,8.62,79.73l-.17.17A16,16,0,0,0,144,176l11.31,11.31a16,16,0,0,0,22.63,0L283.31,81.94a16,16,0,0,0,0-22.63L272,48a16,16,0,0,0-22.62,0l-.17.17c-20.62-16.23-49.83-21.75-79.73-8.62L150.22,20.28A69.25,69.25,0,0,0,32,69.25V256H16A16,16,0,0,0,0,272v16a16,16,0,0,0,16,16H496a16,16,0,0,0,16-16V272A16,16,0,0,0,496,256Z" />
                              </svg>
                            </div>
                            <div className="content">
                              <h4>Bathrooms</h4>
                              <p>{details?.data?.baths}</p>
                            </div>
                          </li>
                          : ""
                        }
                        {/* <li className="flex">
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="21"
                              viewBox="0 0 512 512"
                            >
                              <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" />
                            </svg>
                          </div>
                          <div className="content">
                            <h4>Information</h4>
                          </div>
                        </li> */}
                        {propertyType
                          ? <li className="flex">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="21"
                                viewBox="0 0 576 512"
                              >
                                <path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z" />
                              </svg>
                            </div>
                            <div className="content">
                              <h4>Type</h4>
                              <p>
                                {" "}
                                {propertyType
                                  ? propertyType
                                  : details?.type
                                    ? details?.type
                                    : ""}
                              </p>
                            </div>
                          </li>
                          : details?.type
                            ?
                            <li className="flex">
                              <div className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="21"
                                  viewBox="0 0 576 512"
                                >
                                  <path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z" />
                                </svg>
                              </div>
                              <div className="content">
                                <h4>Type</h4>
                                <p>
                                  {" "}
                                  {propertyType
                                    ? propertyType
                                    : details?.type
                                      ? details?.type
                                      : ""}
                                </p>
                              </div>
                            </li>
                            : ""}
                        {details?.price ?
                          <li className="flex">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="21"
                                viewBox="0 0 320 512"
                              >
                                <path d="M310.706 413.765c-1.314-6.63-7.835-10.872-14.424-9.369-10.692 2.439-27.422 5.413-45.426 5.413-56.763 0-101.929-34.79-121.461-85.449h113.689a12 12 0 0 0 11.708-9.369l6.373-28.36c1.686-7.502-4.019-14.631-11.708-14.631H115.22c-1.21-14.328-1.414-28.287.137-42.245H261.95a12 12 0 0 0 11.723-9.434l6.512-29.755c1.638-7.484-4.061-14.566-11.723-14.566H130.184c20.633-44.991 62.69-75.03 117.619-75.03 14.486 0 28.564 2.25 37.851 4.145 6.216 1.268 12.347-2.498 14.002-8.623l11.991-44.368c1.822-6.741-2.465-13.616-9.326-14.917C290.217 34.912 270.71 32 249.635 32 152.451 32 74.03 92.252 45.075 176H12c-6.627 0-12 5.373-12 12v29.755c0 6.627 5.373 12 12 12h21.569c-1.009 13.607-1.181 29.287-.181 42.245H12c-6.627 0-12 5.373-12 12v28.36c0 6.627 5.373 12 12 12h30.114C67.139 414.692 145.264 480 249.635 480c26.301 0 48.562-4.544 61.101-7.788 6.167-1.595 10.027-7.708 8.788-13.957l-8.818-44.49z" />
                              </svg>
                            </div>
                            <div className="content">
                              <h4>Price</h4>
                              <p>€  {details?.price ? nf.format(details?.price) : ""}</p>
                            </div>
                          </li>
                          : ""}
                        {details?.data?.surface_area?.plot != "0" ?

                          <li className="flex">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="21"
                                viewBox="0 0 512 512"
                              >
                                <path d="M512 128V32c0-17.67-14.33-32-32-32h-96c-17.67 0-32 14.33-32 32H160c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v96c0 17.67 14.33 32 32 32v192c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32h192c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32V160c17.67 0 32-14.33 32-32zm-96-64h32v32h-32V64zM64 64h32v32H64V64zm32 384H64v-32h32v32zm352 0h-32v-32h32v32zm-32-96h-32c-17.67 0-32 14.33-32 32v32H160v-32c0-17.67-14.33-32-32-32H96V160h32c17.67 0 32-14.33 32-32V96h192v32c0 17.67 14.33 32 32 32h32v192z" />
                              </svg>
                            </div>
                            <div className="content">
                              <h4>Plot Size</h4>
                              {
                                details?.surface_area ?
                                  <p>{details?.data?.surface_area?.plot} m2</p> : ""
                              }
                            </div>
                          </li>
                          : ""
                        }
                        {details?.data?.surface_area?.built !="0" ?
                          <li className="flex">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="21"
                                viewBox="0 0 512 512"
                              >
                                <path d="M512 128V32c0-17.67-14.33-32-32-32h-96c-17.67 0-32 14.33-32 32H160c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v96c0 17.67 14.33 32 32 32v192c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32h192c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32V160c17.67 0 32-14.33 32-32zm-96-64h32v32h-32V64zM64 64h32v32H64V64zm32 384H64v-32h32v32zm352 0h-32v-32h32v32zm-32-96h-32c-17.67 0-32 14.33-32 32v32H160v-32c0-17.67-14.33-32-32-32H96V160h32c17.67 0 32-14.33 32-32V96h192v32c0 17.67 14.33 32 32 32h32v192z" />
                              </svg>
                            </div>
                            <div className="content">
                              <h4>Build Size</h4>
                              {
                                details?.data?.surface_area ?
                                  <p>{details?.data?.surface_area?.built} m2</p> : ""
                              }
                            </div>
                          </li>
                          : ""}
                      </ul>
                      <div className="heading-line">
                        <h3>Property Description</h3>
                      </div>
                      <p>
                        {company?.english_description
                          ? company?.english_description
                          :""}
                      </p>

                      <div className="heading-line">
                        <h3>Features</h3>
                      </div>
                      <div className="features-row flex">
                        {details?.amenities == "null" ?
                          <div className="col_">
                            <h4>Amenities</h4>
                            <ul>
                              {details?.amenities !== "null" ? (
                                <li className="flex">
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      fill="currentColor"
                                      className="bi bi-cast"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="m7.646 9.354-3.792 3.792a.5.5 0 0 0 .353.854h7.586a.5.5 0 0 0 .354-.854L8.354 9.354a.5.5 0 0 0-.708 0z" />
                                      <path d="M11.414 11H14.5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h3.086l-1 1H1.5A1.5 1.5 0 0 1 0 10.5v-7A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-2.086l-1-1z" />
                                    </svg>
                                  </div>
                                  <div className="content">
                                    <h5>
                                      {details?.amenities
                                        ? details?.amenities[0]
                                        : details?.features?.feature[0] ? details?.features?.feature[0] : "0"}
                                    </h5>
                                  </div>
                                </li>
                              ) : (
                                ""
                              )}
                              <li className="flex">
                                <div className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="14"
                                    viewBox="0 0 640 512"
                                  >
                                    <path d="M115.38 136.9l102.11 37.18c35.19-81.54 86.21-144.29 139-173.7-95.88-4.89-188.78 36.96-248.53 111.8-6.69 8.4-2.66 21.05 7.42 24.72zm132.25 48.16l238.48 86.83c35.76-121.38 18.7-231.66-42.63-253.98-7.4-2.7-15.13-4-23.09-4-58.02.01-128.27 69.17-172.76 171.15zM521.48 60.5c6.22 16.3 10.83 34.6 13.2 55.19 5.74 49.89-1.42 108.23-18.95 166.98l102.62 37.36c10.09 3.67 21.31-3.43 21.57-14.17 2.32-95.69-41.91-187.44-118.44-245.36zM560 447.98H321.06L386 269.5l-60.14-21.9-72.9 200.37H16c-8.84 0-16 7.16-16 16.01v32.01C0 504.83 7.16 512 16 512h544c8.84 0 16-7.17 16-16.01v-32.01c0-8.84-7.16-16-16-16z" />
                                  </svg>
                                </div>
                                <div className="content">
                                  <h5>
                                    {details?.amenities
                                      ? details?.amenities[1]
                                      : details?.features?.feature[1] ? details?.features?.feature[1] : "0"}
                                  </h5>
                                </div>
                              </li>
                              <li className="flex">
                                <div className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="14"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z" />
                                  </svg>
                                </div>
                                <div className="content">

                                  <h5>
                                    {details?.amenities
                                      ? details?.amenities[2]
                                      : details?.features?.feature[2] ? details?.features?.feature[2] : "0"}
                                  </h5>
                                </div>
                              </li>
                              <li className="flex">
                                <div className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 576 512"
                                  >
                                    <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                                  </svg>
                                </div>
                                <div className="content">
                                  <h5>
                                    {details?.amenities
                                      ? details?.amenities[3]
                                      : details?.features?.feature[3] ? details?.features?.feature[3] : "0"}
                                  </h5>
                                </div>
                              </li>
                            </ul>
                          </div> : ""}

                          {
                            console.log(pooldata , ">>>>>>>>>>>>>>>")
                          }
                        {
                           pooldata === undefined || pooldata === "" ? "" :
 
                            <div className="col_">
                              <h4>Pool</h4>
                              <ul>
                                <li className="flex">
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="14"
                                      viewBox="0 0 640 512"
                                    >
                                      <path d="M189.61 310.58c3.54 3.26 15.27 9.42 34.39 9.42s30.86-6.16 34.39-9.42c16.02-14.77 34.5-22.58 53.46-22.58h16.3c18.96 0 37.45 7.81 53.46 22.58 3.54 3.26 15.27 9.42 34.39 9.42s30.86-6.16 34.39-9.42c14.86-13.71 31.88-21.12 49.39-22.16l-112.84-80.6 18-12.86c3.64-2.58 8.28-3.52 12.62-2.61l100.35 21.53c25.91 5.53 51.44-10.97 57-36.88 5.55-25.92-10.95-51.44-36.88-57L437.68 98.47c-30.73-6.58-63.02.12-88.56 18.38l-80.02 57.17c-10.38 7.39-19.36 16.44-26.72 26.94L173.75 299c5.47 3.23 10.82 6.93 15.86 11.58zM624 352h-16c-26.04 0-45.8-8.42-56.09-17.9-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C461.8 343.58 442.04 352 416 352s-45.8-8.42-56.09-17.9c-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C269.8 343.58 250.04 352 224 352s-45.8-8.42-56.09-17.9c-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C77.8 343.58 58.04 352 32 352H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h16c38.62 0 72.72-12.19 96-31.84 23.28 19.66 57.38 31.84 96 31.84s72.72-12.19 96-31.84c23.28 19.66 57.38 31.84 96 31.84s72.72-12.19 96-31.84c23.28 19.66 57.38 31.84 96 31.84h16c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm-512-96c44.18 0 80-35.82 80-80s-35.82-80-80-80-80 35.82-80 80 35.82 80 80 80z" />
                                    </svg>
                                  </div>
                                  <div className="content">
                                    <h5>{pooldata ? pooldata : "0"}</h5>
                                  </div>
                                </li>
                              </ul>
                            </div>
                        }
                        {
                          outside_spacedata ?
                            <div className="col_">
                              <h4>Outside Space</h4>
                              <ul>
                                <li className="flex">
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="11"
                                      height="14"
                                      viewBox="0 0 384 512"
                                    >
                                      <path d="M378.31 378.49L298.42 288h30.63c9.01 0 16.98-5 20.78-13.06 3.8-8.04 2.55-17.26-3.28-24.05L268.42 160h28.89c9.1 0 17.3-5.35 20.86-13.61 3.52-8.13 1.86-17.59-4.24-24.08L203.66 4.83c-6.03-6.45-17.28-6.45-23.32 0L70.06 122.31c-6.1 6.49-7.75 15.95-4.24 24.08C69.38 154.65 77.59 160 86.69 160h28.89l-78.14 90.91c-5.81 6.78-7.06 15.99-3.27 24.04C37.97 283 45.93 288 54.95 288h30.63L5.69 378.49c-6 6.79-7.36 16.09-3.56 24.26 3.75 8.05 12 13.25 21.01 13.25H160v24.45l-30.29 48.4c-5.32 10.64 2.42 23.16 14.31 23.16h95.96c11.89 0 19.63-12.52 14.31-23.16L224 440.45V416h136.86c9.01 0 17.26-5.2 21.01-13.25 3.8-8.17 2.44-17.47-3.56-24.26z" />
                                    </svg>
                                  </div>
                                  <div className="content">
                                    <h5>
                                      {outside_spacedata ? outside_spacedata : "0"}
                                    </h5>
                                  </div>
                                </li>
                              </ul>
                            </div> : ""
                        }
                        {
                          near_bydata ?
                            <div className="col_">
                              <h4>Near By</h4>
                              <ul>
                                <li className="flex">
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="14"
                                      viewBox="0 0 640 512"
                                    >
                                      <path d="M115.38 136.9l102.11 37.18c35.19-81.54 86.21-144.29 139-173.7-95.88-4.89-188.78 36.96-248.53 111.8-6.69 8.4-2.66 21.05 7.42 24.72zm132.25 48.16l238.48 86.83c35.76-121.38 18.7-231.66-42.63-253.98-7.4-2.7-15.13-4-23.09-4-58.02.01-128.27 69.17-172.76 171.15zM521.48 60.5c6.22 16.3 10.83 34.6 13.2 55.19 5.74 49.89-1.42 108.23-18.95 166.98l102.62 37.36c10.09 3.67 21.31-3.43 21.57-14.17 2.32-95.69-41.91-187.44-118.44-245.36zM560 447.98H321.06L386 269.5l-60.14-21.9-72.9 200.37H16c-8.84 0-16 7.16-16 16.01v32.01C0 504.83 7.16 512 16 512h544c8.84 0 16-7.17 16-16.01v-32.01c0-8.84-7.16-16-16-16z" />
                                    </svg>
                                  </div>
                                  <div className="content">
                                    <h5>
                                      {/* {near_bydata
                                  ? near_bydata
                                  : details?.amenities[0]
                                  ? details?.amenities[0]
                                  : ""} */}
                                      {near_bydata ? near_bydata : ""}
                                    </h5>
                                  </div>
                                </li>

                                {details?.features?.feature ? (
                                  <li className="flex">
                                    <div className="icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="14"
                                        viewBox="0 0 416 512"
                                      >
                                        <path d="M96 416h224c0 17.7-14.3 32-32 32h-16c-17.7 0-32 14.3-32 32v20c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-20c0-17.7-14.3-32-32-32h-16c-17.7 0-32-14.3-32-32zm320-208c0 74.2-39 139.2-97.5 176h-221C39 347.2 0 282.2 0 208 0 93.1 93.1 0 208 0s208 93.1 208 208zm-180.1 43.9c18.3 0 33.1-14.8 33.1-33.1 0-14.4-9.3-26.3-22.1-30.9 9.6 26.8-15.6 51.3-41.9 41.9 4.6 12.8 16.5 22.1 30.9 22.1zm49.1 46.9c0-14.4-9.3-26.3-22.1-30.9 9.6 26.8-15.6 51.3-41.9 41.9 4.6 12.8 16.5 22.1 30.9 22.1 18.3 0 33.1-14.9 33.1-33.1zm64-64c0-14.4-9.3-26.3-22.1-30.9 9.6 26.8-15.6 51.3-41.9 41.9 4.6 12.8 16.5 22.1 30.9 22.1 18.3 0 33.1-14.9 33.1-33.1z" />
                                      </svg>
                                    </div>
                                    <div className="content">
                                      {/* <h5>{details ? details?.amenities[1] : ""}</h5> */}
                                    </div>
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </div> : ""
                        }
                      </div>
                      <div id="location">
                        <div className="heading-line">
                          <h3>Location</h3>
                        </div>

                        {/* <iframe
                        src={`https://maps.google.com/maps?q=10.88,17.908/lo&hl=es;&output=embed`}
                        width="720"
                        height="400"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe> */}
                      <div className="Map_postion" >

                      <MapsComp2 lat={details?.latitude ? details?.latitude :"234"} long={details?.longitude ? details?.longitude :"-342"}/>
                      </div>
                       
                        <p>
                          {details?.town.replace(/[\["\]']+/g, "  ")
                            ? details?.town?.replace(/[\["\]']+/g, "  ")
                            : "Not Added"}
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="agent-profile" >
                        <h3>Agent Details</h3>
                        <div id="user-details" onClick={()=> navigate(`/author/${company?.company_name}`)} style={{cursor:"pointer"}}>
                          <div className="user-name">
                            <p>{company?.company_name} </p>
                          </div>
                          <div className="user-img">
                            
<a  className=" firstalpha2 ">
                            {company?.company_name?.charAt(0)}
                          </a>
                    
                            {/* <img src="https://secure.gravatar.com/avatar/dd1e2a20756c31be0a38bd96fa3b8d9c?s=96&amp;d=https%3A%2F%2Fui-avatars.com%2Fapi%2FHusmanHagbergHusmanHagberg%2F96%2F546de5%2Ffff%2F1%2F0.4%2F%2F1%2F1&amp;r=g" /> */}
                          </div>
                        </div>
                        <ul>
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="16"
                              viewBox="0 0 576 512"
                            >
                              <path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path>
                            </svg>
                            Reference: {details?.ref}
                          </li>
                          <li>
                            <NavLink to={`/author/${company?.company_name}`}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="16"
                                viewBox="0 0 576 512"
                              >
                                <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
                              </svg>
                              View more properties from this agent
                            </NavLink>
                          </li>
                        </ul>
                        <a
                          href="#"
                          onClick={() => setpopup(true)}
                          className="btn_custom"
                          id="call_pop_btn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 512 512"
                          >
                            <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" />
                          </svg>
                          <span>Call Now</span>
                        </a>
                        <a href="#contact_agent" className="btn_custom">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 512 512"
                          >
                            <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
                          </svg>
                          <span>Contact</span>
                        </a>
                        <ul>
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 512 512"
                            >
                              <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z" />
                            </svg>
                            Arrange a viewing, 24/7
                          </li>
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 448 512"
                            >
                              <path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" />
                            </svg>
                            Choose a date & time
                          </li>
                        </ul>
                        <NavLink
                          to={`/view-request/${propertyId.propertyId}`}
                          className="btn_custom"
                        >
                          <span>Request a viewing</span>
                        </NavLink>
                        {/* <h4>Follow Agent</h4> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <!-- section property inner section end here -->

    <!-- section Related Properties start here --> */}
              <section className="related-properties">
                <div className="container-sm">
                  <div className="heading-line">
                    <h3>Related Properties</h3>
                  </div>
                  <ul className="flex">
                    {relatedProperty?.map((item, index) => {
                      return (
                        <li>
                          <div className="related_pop_slider">
                            <div className="bookmark-slider_mob ">
                              <div className="bookmark-slider_mob bookmark-slider list-img-slider">
                                <Slider {...settings}>
                                  {item.data.images?.image.map((img, i) => {
                                    return (
                                      <div key={i}>
                                        <figure
                                          onClick={() =>
                                            navigate(
                                              `/property-details/${item.property_id}`
                                            )
                                          }
                                          className="hover_effect"
                                        >
                                          <img
                                            src={img?.url}
                                            style={{ height: "240px" }}
                                            alt="loading img..."
                                          />
                                        </figure>
                                      </div>
                                    );
                                  })}
                                </Slider>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <a href="#">
                              <h5>€ {item.data?.price ? nf.format(item.data?.price) : ""}</h5>
                              <p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="16"
                                  viewBox="0 0 384 512"
                                >
                                  <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                                </svg>{" "}
                                {item.data?.town ? item.data.town?.replace(/[\[,"\]']+/g, "  ") : ""}
                              </p>
                            </a>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
              {/* <!-- section Related Properties end here -->

    <!-- contact agent section start here --> */}

              <section className="contact-agent" id="contact_agent">
                <div className="container-sm">
                  <div className="heading-line">
                    <h3>Contact Agent</h3>
                  </div>
                  <form action="" onSubmit={(e) => callAgentForm(e)}>
                    <div className="form_group">
                      <label for="name">Your name</label>
                      <input type="text" name="name" id="name"  defaultValue={userInformation?.name} />
                    </div>
                    <div className="form_group">
                      <label for="email">Your email</label>
                      <input type="email" name="email" id="email" onChange={(e) => logins_field(e)} value={CallAgentEmail} style={error2.resetemailadd != "" ? { borderBottom: "2px solid red" } : {}} />
                    </div>
                    <div className="form_group">
                      <label for="phone" >Phone</label>
                      <input type="text" name="phone" id="phone" onChange={(e) => logins_field(e)} value={CallAgentPhone} style={error2.password != "" ? { borderBottom: "2px solid red" } : {}} />
                    </div>
                    <div className="form_group">
                      <label for="subject">Subject</label>
                      <input type="text" name="subject" id="subject" />
                    </div>
                    <div className="form_group">
                      <label for="massage">Your message</label>
                      <textarea name="message" id="message"></textarea>
                    </div>
                    <div style={{ fontSize: "12px", paddingBottom: "10px" }}>
                      {errorCall}
                    </div>
                    <div style={{ fontSize: "12px", paddingBottom: "10px" }}>
                      {success}
                    </div>
                    <div className="form_group">
                      <button type="submit">Submit</button>
                    </div>
                  </form>
                </div>
              </section>
              {/* <!-- contact agent section end here -->

    <!-- call now popup form section start here --> */}
              {popup == true ? (
                <section className="call_popup active">
                  <div className="closeBtn" onClick={() => setpopup(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="21"
                      viewBox="0 0 352 512"
                    >
                      <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                    </svg>
                  </div>
                  <div className="wrapper">
                    <div className="head">
                      Please enter your details to call the agent
                    </div>
                    <div className="content">
                      <form action="" onSubmit={(e) => callAgent(e)}>
                        <div className="form_group">
                          <label for="name">Your name</label>
                          <input type="text" onChange={(e) => logins_field(e)} defaultValue={userInformation?.name} value={CallAgentName} name="name" style={error2.username != "" ? { borderBottom: "2px solid red" } : {}} />
                        </div>
                        <div className="form_group">
                          <label for="email">Your email</label>
                          <input type="email" onChange={(e) => logins_field(e)} value={CallAgentEmail} name="email" style={error2.resetemailadd != "" ? { borderBottom: "2px solid red" } : {}} />
                        </div>
                        <div className="form_group">
                          <label for="phone">Your Phone</label>
                          <input type="text" maxLength={13} onChange={(e) => logins_field(e)} value={CallAgentPhone} name="phone" style={error2.password != "" ? { borderBottom: "2px solid red" } : {}} />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "12px" }}>
                          {emptyMessage}
                        </div>
                        <div style={{ textAlign: "center", fontSize: "12px" }}>
                          {Message}
                        </div>
                        <div className="form_group">
                          <button type="submit">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              ) : (
                ""
              )}

              {/* <!-- call now popup form section end here --> */}
            </>
          )}
        </Layout>
      }
    </>
  );
}

export default PropertyDetails;
