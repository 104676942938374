import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Layout from "../../Layout/Layout";
import bed from "../../../assets/images/single-bed.png";
import bathroom_size from "../../../assets/images/bathrooms_size.png";
import bath from "../../../assets/images/bath.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../../baseUrl";
import PremiumSection from "../SearchSections/PremiumSection";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'

import swal from 'sweetalert';

const img = [
  require("../../../assets/images/bookmark-img1.jpg"),
  require("../../../assets/images/bookmark-img2.jpg"),
  require("../../../assets/images/bookmark-img3.jpg"),
];
function Buy(props) {
  const detials = props.details;
  const town = props.town;
  const premiumDetails = props.premiumDetails;
  const setshorting = props.setshorting;
  const setshortinghigh = props.setshortinghigh;
  const navigate = useNavigate();
  const islogin = JSON.parse(localStorage.getItem("login"));
  const [error, setError] = useState("");
  const [count, setcount] = useState(true);
  const [addSucces, setaddSucces] = useState(false);
  const [p_Id, setP_Id] = useState("");
  const [AddFav, setAddFav] = useState(false);
  const shorting = props.shorting;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : "";
  const postFavourite = async (property_id) => {
    // e.preventDefault();
    //const property_id = p_Id;
    try {
      const res = await axios.post(
        baseUrl + "/Add/favourite-property",
        { property_id: property_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = await res.data;
      // if(res.status==200){
      //   setaddSucces(true)
      // }
      if (res.status == 200) {
        setAddFav(true)

        swal({
          title: "Bella Islas says",
          text: res.data.message,
          icon: "success",
          button: "OK",
          // timer: 3000,
        });
      }
      // console.log(data, property_id);
    } catch (error) {
      // if(error){
      //   setaddSucces(false)
      // }
      console.log(error);
    }
  };


  //const filterDetails = detials;
  const [filterDetails, setfilterDetails] = useState([...detials])


  console.log(">>>>>>>>>>>>", filterDetails);


  const passId = (id) => {
    navigate(`/property-details/${id}`);
  };

  useEffect(() => {
    setfilterDetails(detials)
  }, [detials])

  const handleFilter = (e) => {
    setshorting(e.target.value)

  }

  console.log(detials, "filtered");
  useEffect(() => {
    if (count) {
      setcount(false);
    }
  }, []);


  let nf = new Intl.NumberFormat();


  const handlenewtab = (event) => {

  }

  return (
    <>
      <section className="fillter_res">
        <div className="container_">
          <div className="top_bar flex">
            <h5>
              Properties For Sale in  {town !== "" ? town + " :" : "Mallorca :"}{" "}
              {premiumDetails?.xml?.total} property results
            </h5>
            <div className="result-fillter">
              <label for="sort">Sort:</label>
              <select id="sortType" onChange={(e) => handleFilter(e)} name="sort" className="select sort-select"
                value={shorting == "low" ? "low" : shorting == "high" ? "high" : shorting == "new" ? "new" : shorting == "old" ? "old" : ""}>
                {/* //<option value="">Select option</option> */}
                <option value="low">Lowest Price</option>
                <option value="high">Highest Price</option>
                <option value="new">Newest Listed</option>
                <option value="old">Oldest Listed</option>
              </select>
            </div>
          </div>

          {/* <h3 className="top_heading"> Premium Featured-New to the market </h3> */}
          <PremiumSection premiumDetails={premiumDetails} />

          <h1>Available Listing</h1>
          <div style={{ textAlign: "center" }}>{error}</div>

          {Array.from(filterDetails).map((item, index) => {

            var type2 = JSON.stringify(item.data?.type).replace(/[\\[,"\]']+/g, "  ")

            return (
              <div className={"listings " + index} key={index} onClick={(e) => handlenewtab(e)} >
                <div className="listDataRight uper_side">
                  <div
                    // className={`${addSucces === true ? "fav_add_btn" : ""}`}
                    // type="submit"
                    onClick={() => {
                      if (islogin) {
                        postFavourite(item.property_id);
                      } else {
                        swal({
                          title: "Bella Islas says",
                          text: "Please Log in First !",
                          icon: "error",
                          button: "OK",
                          // timer: 3000,
                        });

                      }
                    }}
                  >
                    {" "}
                    {addSucces == item.property_id && AddFav == true ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0H24V24H0z" />
                        <path
                          d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z"
                          fill="rgba(255,140,0,1)"
                        />
                      </svg>
                    ) : (

                      <span
                        className="heart_hover"
                        onClick={() => setaddSucces(item.property_id)}
                      >
                        <i class="far fa-heart"></i>
                      </span>
                    )}
                  </div>
                </div>
                <NavLink className="flex" to={`/property-details/${item.property_id}`}>
                  <div className="col_ ">
                    <div className="bookmark-slider_mob" >
                      <div className=" slickArrow bookmark-slider_mob bookmark-slider list-img-slider" style={{ cursor: "pointer" }}>
                        <Slider {...settings}>
                          {Array.from(item.data.images?.image).map((img, i) => {
                            return (
                              <div key={i}>
                                <figure >
                                  <img src={img?.url} alt="loading img..." />
                                </figure>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="col_" style={{ cursor: "pointer" }}>
                    <div className="listDataContainer" >
                      {/* <NavLink to={`/property-details/${item?.property_id}`}> */}
                      <div className="listDataOuter">
                        <div className="listingtitle">
                          {item.data?.beds} bedroom{" "}

                          {/* {item.data?.type?.replace(/[\[,"\]']+/g," | " ) ? item.data?.type?.replace(/[\[,"\]']+/g," | " ) :"apartment"} */}

                          {type2 ? type2 : ""}

                          for{" "}
                          {item.data?.price_freq}
                        </div>
                        <div className="pricesection">
                          <span className="price">
                            <b>€ {item?.data.price ? nf.format(item?.data.price) : ""}</b> {" "}
                          </span>
                          <span className="bedrooms">
                            {item.data?.beds} <img src={bed} alt="logo" />{" "}
                          </span>
                          <span className="bathrooms">
                            {item.data?.baths} <img src={bath} />{" "}
                          </span>
                          <span className="bathrooms_size">
                            {
                              item.data?.surface_area?.built == null ? "" :
                                <>
                                  <img src={bathroom_size} alt="" />
                                  <span>
                                    {item.data?.surface_area.built ? item.data.surface_area.built : ""}

                                    m<sup>2</sup>{" "}
                                  </span>
                                </>
                            }
                          </span>

                        </div>

                        <div className="listingaddress">
                          <i
                            aria-hidden="true"
                            className="fas fa-map-marker-alt"
                          ></i>
                          {item.data?.town.substr(item.data?.town.lastIndexOf('\\') + 1).split('[')[1] ? JSON.parse(item.data?.town).map((a, index2) => a + " ") : item.data?.town}
                        </div>
                      </div>
                      {/* </NavLink> */}

                    </div>
                    <div className="agentname">
                      <div>
                        <p className="eng_desFont">
                          {item.english_description?.substring(0, 250)}
                        </p>
                      </div>

                      <div className="wrap flex">
                        <p>
                          <NavLink to={`/author/${item?.company_name}`}>
                            {item?.company_name}
                          </NavLink>
                        </p>
                        <figure>
                          <a href={`/author/${item?.company_name}`} className="firstalpha">
                            {item?.company_name?.charAt(0)}
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Buy;
