import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import "../../Styles/mainstyle/style.css";
import img1 from "../../assets/images/the-island-img.jpeg";
import img2 from "../../assets/images/tabing-left-1-img.jpg";
import loader from "../../assets/images/loader.svg";

import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { useParams } from "react-router-dom";
import { useRef } from "react";

function Mallorca() {
  const [description, setdescription] = useState(1);
  const [descriptionsouth, setdescriptionsouth] = useState(10);
  const [descriptionwestData, setdescriptionwestData] = useState(41);
  const [descriptionsoutheastData, setdescriptionsoutheastData] = useState(23);
  const [descriptioncentralData, setdescriptiocentralData] = useState(66);
  const [descriptionnorthData, setdescriptionnorthData] = useState(53);
  const [descriptionnorthEastData, setdescriptionnorthEastData] = useState(60);

  //console.log(description);

  const [loading, setloading] = useState(false);

  const [mallorca, setMallorca] = useState([]);
  console.log(mallorca.mallorcaData);

  const mallorcadata = mallorca.mallorcaData?.filter(
    (item) => item.category == "Palma De Mallorca"
  );

  const southwestData = mallorca.mallorcaData?.filter(
    (item) => item.category == "The South West"
  );

  const southeastData = mallorca.mallorcaData?.filter(
    (item) => item.category == "The South East"
  );
  const westData = mallorca.mallorcaData?.filter(
    (item) => item.category == "The West"
  );
  const northData = mallorca.mallorcaData?.filter(
    (item) => item.category == "The North"
  );
  const northEastData = mallorca.mallorcaData?.filter(
    (item) => item.category == "North East"
  );

  const centralData = mallorca.mallorcaData?.filter(
    (item) => item.category == "Central"
  );

  const { id } = useParams();

  console.log("mallorcadata", id);

  const handleScroll = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };
  window.onscroll = function () { myFunction() };



  function myFunction() {
    var header = document.getElementById("stopbtn");
    var sticky = header.offsetTop;
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky2");
    } else {
      header.classList.remove("sticky2");
    }
  }
  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/mallorca-list`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        console.log("townlist==", res);
        if (res.status == 200) {
          setMallorca(res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  var southeastsection = useRef(null);
  var southWestsection = useRef(null);
  var southsection = useRef(null);
  var palmasection = useRef(null);
  var centersection = useRef(null);
  var westsection = useRef(null);
  const southeastsectionGoto = () => {
    window.scrollTo({
      top: southeastsection.current.offsetTop,
      behavior: "smooth",
    });
  };
  const southWestsectionGoto = () => {
    window.scrollTo({
      top: southWestsection.current.offsetTop,
      behavior: "smooth",
    });
  };

  const centralsectionGoto = () => {
    window.scrollTo({
      top: centersection.current.offsetTop,
      behavior: "smooth",
    });
  };

  const palmasectionGoto = () => {
    window.scrollTo({
      top: palmasection.current.offsetTop,
      behavior: "smooth",
    });
  };

  const westGoto = () => {
    window.scrollTo({
      top: centersection.current.offsetTop,
      behavior: "smooth",
    });
  };
  console.log(southeastsection.current, "ref<<<<<<<,,");

  useEffect(() => {
    if (id == 1) {
      setTimeout(() => {

        southWestsectionGoto();
      }, 3000);
    }

    if (id == 2) {
      setTimeout(() => {
        southeastsectionGoto();
      }, 3000);
    }

    if (id == 3) {
      setTimeout(() => {
        westGoto();
      }, 3000);
    }

    if (id == 4) {
      setTimeout(() => {
        palmasectionGoto();
      }, 3000);
    }
    if (id == 5) {
      setTimeout(() => {
        westGoto();
        centralsectionGoto();
      }, 3000);
    }

    fetchtownList();
  }, []);

  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loader} alt="loading..." />{" "}
          </div>
        ) : (
          <>
            <section className="innerBanner innerBanner2">
              <div className="inner-heading">
                <h1>Mallorca</h1>
              </div>
            </section>
            {/* <!-- inner banner section end here -->

    <!-- about mallorca section start here --> */}
            <section className="about_mallorca">
              <div className="container-sm flex">
                <div className="col">
                  <div className="inner-heading">
                    <h3>The Island</h3>
                    <p>
                      Mallorca has a sophisticated infrastructure and a
                      reputation for year round quality tourism. In the last ten
                      years the number of 5 star hotels has more than doubled.
                      Traditional leisure pursuits are well catered for, with 23
                      golf courses and over 300 km of dedicated cycling routes.
                      And it’s fast gaining popularity with adventure sports
                      enthusiasts. In particular, divers, climbers and olympic
                      athletes come to the island for winter training. In
                      addition, an abundance of safe anchorages and first
                      className facilities have turned the island into the
                      yachting hub of the Western Mediterranean.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <figure>
                    <img src={img1} alt="img..." />
                  </figure>
                </div>
              </div>
              <div className="container-sm">
                <p>
                  There’s always something special about an island. Mallorca is
                  the largest of the four Balearic islands with an area of 3,640
                  sq km. It enjoys a temperate, sub-tropical climate of short,
                  mild winters and hot summers. And it’s an island of diverse
                  landscapes. There’s the Tramuntana mountain range and dramatic
                  sea cliffs. In addition, over 70 beaches, isolated coves,
                  picturesque villages and rolling agricultural plains are part
                  of Mallorca’s variety. And, wherever you are you are never
                  more than 25 minutes from the coast. Many buyers from overseas
                  becomes residents in Mallorca. The island’s permanent foreign
                  community of 20% remains the highest percentage of any
                  province in Spain
                </p>
              </div>
            </section>
            {/* <!-- about mallorca section end here -->

    <!-- property tab section start here --> */}
            <section className="property-tab one" ref={palmasection}>
              <div className="head head_back1">
                <h2>Palma De Mallorca</h2>
              </div>
              <div className="wrapper">
                <div className="container-sm">
                  <ul className="tab">
                    {mallorcadata?.map((item, index) => {
                      return (
                        <li
                          data-id=""
                          className={`${description == item.id ? "menu_active" : ""
                            }`}
                          //on={()=>setdescription(1)}
                          onClick={() => setdescription(item.id)}
                          key={item.id}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="tab-wrapper">
                    {mallorcadata
                      ?.filter((item) => item.id == description)
                      ?.map((item, i) => {
                        // console.log("desss", item);

                        return (
                          <div
                            className="tab-content "
                            style={{ display: "block" }}
                            id=""
                          >
                            <div className="wrap flex">
                              <div className="col" data-aos="fade-right">
                                <figure>
                                  <img
                                    src={item.file_path ? item.file_path : ""}
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="col" data-aos="fade-left">
                                <p>{item ? item.description : ""}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
            <section className="property-tab two"
              // ref={centersection}
              ref={southeastsection}
            >
              <div className="head head_back2">
                <h2>The South West</h2>
              </div>
              <div className="wrapper">
                <div className="container-sm">
                  <ul className="tab">
                    {southwestData?.map((item, index) => {
                      return (
                        <li
                          data-id=""
                          className={`${descriptionsouth == item.id ? "menu_active" : ""
                            }`}
                          onClick={() => setdescriptionsouth(item.id)}
                          key={item.id}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="tab-wrapper">
                    {southwestData
                      ?.filter((item) => item.id == descriptionsouth)
                      ?.map((item, i) => {
                        return (
                          <div
                            className="tab-content "
                            style={{ display: "block" }}
                            id=""
                          >
                            <div className="wrap flex">
                              <div className="col" data-aos="fade-left">
                                <p>{item ? item.description : ""}</p>
                              </div>
                              <div className="col" data-aos="fade-right">
                                <figure>
                                  <img
                                    src={item.file_path ? item.file_path : ""}
                                    alt=""
                                  />
                                </figure>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
            <section
              className="property-tab three"
              ref={southWestsection}
              id="southEast"
            >
              <div className="head head_back3">
                <h2>The South East</h2>
              </div>

              <div className="wrapper">
                <div className="container-sm">
                  <ul className="tab">
                    {southeastData?.map((item, index) => {
                      return (
                        <li
                          data-id=""
                          className={`${descriptionsoutheastData == item.id
                            ? "menu_active"
                            : ""
                            }`}
                          onClick={() => setdescriptionsoutheastData(item.id)}
                          key={item.id}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="tab-wrapper">
                    {southeastData
                      ?.filter((item) => item.id == descriptionsoutheastData)
                      ?.map((item, i) => {
                        return (
                          <div
                            className="tab-content "
                            style={{ display: "block" }}
                            id=""
                          >
                            <div className="wrap flex">
                              <div className="col" data-aos="fade-right">
                                <figure>
                                  <img
                                    src={item.file_path ? item.file_path : ""}
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="col" data-aos="fade-left">
                                <p>{item ? item.description : ""}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
            <section
              className="property-tab four"
              // ref={westsection}
              ref={centersection}
              id="thewest"
            >
              <div className="head head_back4">
                <h2>The West</h2>
              </div>
              <div className="wrapper">
                <div className="container-sm">
                  <ul className="tab">
                    {westData?.map((item, index) => {
                      return (
                        <li
                          data-id=""
                          className={`${descriptionwestData == item.id ? "menu_active" : ""
                            }`}
                          onClick={() => setdescriptionwestData(item.id)}
                          key={item.id}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="tab-wrapper">
                    {westData
                      ?.filter((item) => item.id == descriptionwestData)
                      ?.map((item, i) => {
                        return (
                          <div
                            className="tab-content "
                            style={{ display: "block" }}
                            id=""
                          >
                            <div className="wrap flex">

                              <div className="col" data-aos="fade-left">
                                <p>{item ? item.description : ""}</p>
                              </div>
                              <div className="col" data-aos="fade-right">
                                <figure>
                                  <img
                                    src={item.file_path ? item.file_path : ""}
                                    alt=""
                                  />
                                </figure>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
            <section className="property-tab five">
              <div className="head head_back5">
                <h2>The North</h2>
              </div>
              <div className="wrapper">
                <div className="container-sm">
                  <ul className="tab">
                    {northData?.map((item, index) => {
                      return (
                        <li
                          data-id=""
                          className={`${descriptionnorthData == item.id ? "menu_active" : ""
                            }`}
                          onClick={() => setdescriptionnorthData(item.id)}
                          key={item.id}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="tab-wrapper">
                    {northData
                      ?.filter((item) => item.id == descriptionnorthData)
                      ?.map((item, i) => {
                        return (
                          <div
                            className="tab-content "
                            style={{ display: "block" }}
                            id=""
                          >
                            <div className="wrap flex">
                              <div className="col" data-aos="fade-right">
                                <figure>
                                  <img
                                    src={item.file_path ? item.file_path : ""}
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="col" data-aos="fade-left">
                                <p>{item ? item.description : ""}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
            <section className="property-tab six">
              <div className="head head_back6">
                <h2>North East</h2>
              </div>

              <div className="wrapper">
                <div className="container-sm">
                  <ul className="tab">
                    {northEastData?.map((item, index) => {
                      return (
                        <li
                          data-id=""
                          className={`${descriptionnorthEastData == item.id ? "menu_active" : ""
                            }`}
                          onClick={() => setdescriptionnorthEastData(item.id)}
                          key={item.id}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="tab-wrapper">
                    {northEastData
                      ?.filter((item) => item.id == descriptionnorthEastData)
                      ?.map((item, i) => {
                        return (
                          <div
                            className="tab-content "
                            style={{ display: "block" }}
                            id=""
                          >
                            <div className="wrap flex">

                              <div className="col" data-aos="fade-left">
                                <p>{item ? item.description : ""}</p>
                              </div>
                              <div className="col" data-aos="fade-right">
                                <figure>
                                  <img
                                    src={item.file_path ? item.file_path : ""}
                                    alt=""
                                  />
                                </figure>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
            <section className="property-tab seven" >
              <div className="head head_back7">
                <h2>Central</h2>
              </div>
              <div className="wrapper">
                <div className="container-sm">
                  <ul className="tab">
                    {centralData?.map((item, index) => {
                      return (
                        <li
                          data-id=""
                          className={`${descriptioncentralData == item.id ? "menu_active" : ""
                            }`}
                          onClick={() => setdescriptiocentralData(item.id)}
                          key={item.id}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="tab-wrapper">
                    {centralData
                      ?.filter((item) => item.id == descriptioncentralData)
                      ?.map((item, i) => {
                        console.log("desss", item);

                        return (
                          <div
                            className="tab-content "
                            style={{ display: "block" }}
                            id=""
                          >
                            <div className="wrap flex">
                              <div className="col" data-aos="fade-right">
                                <figure>
                                  <img
                                    src={item.file_path ? item.file_path : ""}
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="col" data-aos="fade-left">
                                <p>{item ? item.description : ""}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
          </>
        )}

        <div className="scrollBtn">
          <div className="btn_style" id="stopbtn" onClick={() => handleScroll()}>
            <i aria-hidden="true" class="fas fa-angle-right"></i>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Mallorca;
