import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import { baseUrl } from "../../../baseUrl";
function ChartComponents({ startingdate, endingdate }) {
  const token = JSON.parse(localStorage.getItem("token"));
  const [loading, setloading] = useState(false);
  const [agentData, setAgentData] = useState([]);

  console.log(startingdate, endingdate);

  const [options, setObject] = useState({
    colors: ["#006994", "#FF8C00", "#FF8C00"],
    chart: {
      id: "series",
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: true,
      seriesName: "Email Lead",
      opposite: false,
      reversed: false,
      logarithmic: false,
      logBase: 0,
      tickAmount: 7,
      min: 0,
      max: 100,
      forceNiceScale: true,
      floating: false,
      decimalsInFloat: false,
    },
  });

  //console.log(">>>>>>", agentData ,emaildate ,emailcount);
  const [series, setseries] = useState([
    {
      name: "Email Lead",
      data: [],
    },
    {
      name: "Telephone Lead",
      data: [],
    },
  ]);

  const myaccountLeadReport = async () => {
    await axios
      .get(
        baseUrl +
        `/agent/lead-property?${startingdate == "" ? "" : `from_date=${startingdate}`
        }${endingdate == "" ? "" : `&date_to=${endingdate}`}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setAgentData(res.data.data);
          console.log("townlist==", res);
          setloading(true);
          const emaildate = [];
          const phonecount = [];
          const emailcount = [];

          res.data.data?.telephone?.map((data) => phonecount.push(data.count));
          res.data.data?.email?.map((data) => emaildate.push(data.date));
          res.data.data?.email?.map((data) => emailcount.push(data.count));
          console.log("emailcount", emailcount, phonecount);

          setObject({
            colors: ["#006994", "#FF8C00", "#FF8C00"],
            xaxis: {
              categories: emaildate,
            },
          });

          setseries([
            {
              name: "Email Lead",
              data: emailcount,
            },
            {
              name: "Telephone Lead",
              data: phonecount,
            },
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  useEffect(() => {
    myaccountLeadReport();
  }, [startingdate, endingdate]);

  return (
    <>
      <Chart
        width="100%"
        height={500}
        options={options}
        series={series}
        type="bar"
      />
    </>
  );
}

export default ChartComponents;
