import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

function Layout(props) {
  const { pathname } = useLocation()

  
  return (
    <>
    <Header/>
    
    {props.children}
    
     <Footer/>
    </>
  )
}

export default Layout