import React from "react";
import Layout from "../Layout/Layout";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { NavLink } from "react-router-dom";
function Sevedsearches() {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState("");

  const fetchproperty = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(baseUrl + "/saved/search-list", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const details = res.data.data.response;
      console.log(details);
      setData(details);
    } catch (error) {
      console.log("error", error.message);
      setError(error.message);
    }
  };

  React.useEffect(() => {
    fetchproperty();
  }, []);
  const [deleteId, setDeleteId] = React.useState("");
  const handleSubmit = (event) => {
    const id = deleteId;
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem("token"));
    axios
      .post(baseUrl + `/delete/search-list?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const delateFavourite = async (id) => {
    console.log(id);
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.post(
        baseUrl + `/delete/favourite-property?id=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const details = res.data;
      console.log("delate", details);
    } catch (error) {
      console.log(error.message);
      // setError(error.message)
    }
  };

  const delateSearch = async (id) => {
    console.log(id);
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(baseUrl + `/delete/search-list?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const details = res.data;
      console.log("delate", details);
      if (details.success == true) {
        const token = JSON.parse(localStorage.getItem("token"));

        const res = await axios.get(baseUrl + "/saved/search-list", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const details = res.data.data.response;
        console.log(details);
        setData(details);
      }
    } catch (error) {
      console.log(error.message);
      // setError(error.message)
    }
  };

  return (
    <>
      <Layout>
        <section className="saved-searches">
          <div className="container-sm">
            <h1>Saved Searches</h1>
            <p>
              Track your searches and get alerts for new matching properties
            </p>
            <div className="listing-search">
              <table id="alert-fq" className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Price Range</th>
                    <th>Beds</th>
                    <th>Alert Frequency</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    // { console.log("jjjjjj", item) }
                    <tr colspan="6" key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.locations}</td>
                      <td>
                        {parseInt(item.min_price).toLocaleString(undefined, { maximumFractionDigits: 2 })}-{parseInt(item.max_price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </td>
                      <td>
                        {item.min_beds}-{item.max_beds}
                      </td>
                      <td>
                        <input
                          type="hidden"
                          name="saved-search-id"
                          id="saved-search-id"
                          className="saved-search-id"
                          value="49"
                        />
                        <select
                          name="alerts-frequency"
                          required=""
                          className="form-control"
                          id="alerts-frequency"
                        >
                          <option value="daily" selected="selected">
                            {item.alert}
                          </option>
                          <option value="three-days">Every 3 Days</option>
                          <option value="weekly">Weekly</option>
                          <option value="never">Never</option>
                          <option value="instant">Instant</option>
                        </select>
                      </td>
                      <td>
                        <NavLink to="/buy" className="frq-link" href="#">
                          Search
                        </NavLink>
                      </td>
                      <td>
                        <button
                          className="frqDeleteBtn"
                          onClick={() => delateSearch(item.id)}
                          data-id="49"
                          type="submit"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#000"
                          >
                            {" "}
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>{" "}
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{ fontSize: "12px", textAlign: "center" }}>
                {error}
              </div>
            </div>
            <div className="create-search">
              <NavLink to="/">Create a new search</NavLink>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default Sevedsearches;
