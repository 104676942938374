import React from 'react'
import GoogleMaps from './GoogleMaps'

function Maps() {
  return (
    <>
    <GoogleMaps/>
    
    
    
    </>
  )
}

export default Maps