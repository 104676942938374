import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { baseUrl } from '../../../baseUrl';
import { articledata } from '../../../Data/Data'
import moment from "moment"
function NewsSection() {
    const [blog, setblog] = useState();
    const [loading, setloading] = useState(false);
  
    console.log(blog?.map((item)=>  moment(item?.updated_at).format('DD MMM, YYYY')));
  
    const fetchblogdata = async () => {
      await axios
        .get(baseUrl + "/blog/blog-list", {
          headers: { Authorization: `No Auth` },
        })
        .then((res) => {
          if (res.status == 200) {
            setloading(true);
          }
          console.log(res);
          setblog(res.data.data);
        })
        .catch((error) => {
          if (error) {
            setloading(true);
          }
          console.log(error);
        });
    };
  
    useEffect(() => {
      fetchblogdata();
    }, []);
  

    return (
        <>
            <section class="news-section">
                <div class="container-sm">
                    <div class="heading-line" data-aos="fade-in">
                        <h3>News & Articles</h3>
                        <NavLink to="/blog">View More</NavLink>

                    </div>
                    <ul class="flex">
                        {
                            blog?.slice(0, 3).map((item, index) => {
                                return (


                                    <li data-aos="fade-up" key={index}>
                                        <figure>
                                            <NavLink to={`/blog-details/${item.id}`}><img src={item.file_path} alt="img loading..." /></NavLink>
                                        </figure>
                                        <div class="content">
                                            <h4><a href="">{item.title}</a></h4>
                                            <p>

                                                {item.short_desc.substring(0,75)}   
                                                                                     </p>
                                            <NavLink to={`/blog-details/${item.id}`} style={{cursor:"pointer"}} className="read_more">Read More</NavLink>
                                        </div>
                                        <div class="date">
                                            <p>{moment(item?.updated_at).format('DD MMM, YYYY')}</p>
                                        </div>
                                    </li>
                                )
                            })
                        }


                    </ul>
                </div>
            </section>
        </>
    )
}

export default NewsSection
