import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import axios from "axios";
import { baseUrl } from "../../../baseUrl";
import loaderImg from "../../../assets/images/loader.svg";
import moment from "moment";
import swal from "sweetalert";

function Section2() {
  const [popup, setpopup] = useState(false);
  const [data, setData] = useState(1);
  const [agentData, setAgentData] = useState([]);
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const token = JSON.parse(localStorage.getItem("token"));
  const [loading, setloading] = useState(false);
  const islogin = JSON.parse(sessionStorage.getItem("login"));
  const userinfo = JSON.parse(localStorage.getItem("user"));
  const [agentlist, setagentlist] = useState();

  const [forPagination, setForPagination] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const [entries, setEntries] = useState();

  const myaccountlist = async () => {
    setloading(false);

    await axios
      .get(baseUrl + `/agent/property-list?page=${currentPage}&show=${entries ? entries :""}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          setagentlist(res.data?.data);
          setForPagination(res.data?.data);
          setloading(true);
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  const delateProperty = async (id) => {
    console.log(id);
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(baseUrl + `/agent/delete-property?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const details = res.data;
      console.log("delate", details);
      if (res.status == 200) {
        myaccountlist();
        // myaccount();
        swal({
          title: "Bella Islas says",
          text: res.data.message,
          icon: "success",
          button: "OK",
          // timer: 3000,
        });

        await axios
          .get(baseUrl + `/agent/property-list`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status == 200) {
              console.log("myaccountlist==", res.data.data);
              setagentlist(res.data?.data);
              console.log(res.data.data.data);
              setloading(true);
            }
          })
          .catch((error) => {
            console.log(error);
            if (error) {
              setloading(true);
            }
          });
      }
    } catch (error) {
      console.log(error.message);
      // setError(error.message)
      swal({
        title: "Bella Islas says",
        text: error.message,
        icon: "error",
        button: "OK",
        // timer: 3000,
      });
    }
  };

  const [premium, setpremium] = useState([]);
  //console.log("agentlist==>>>>", premium);
  const premiumpagination = [];
  const lastpage = premium.property?.last_page;
  for (let i = 0; i < Math.ceil(lastpage); i++) {
    premiumpagination.push(i + 1);
  }
  console.log("premium>>>>>", premium, ",,,,,", premiumpagination);

  const [searhValue, setSearhValue] = useState("");
  const [searhValueData, setSearhValueData] = useState(agentlist);

  const setSearhValueHandle = (e) => {
    console.log(parseFloat(e.target.value));
    setSearhValue(e.target.value);
    if (e.target.value === "") {
      setSearhValueData(agentlist.data);
      // console.log(agentlist ,"agentlisdfgdst");
    } else {
      const filterdata = agentlist.data.filter((item) =>
        item.postcode.includes(parseFloat(e.target.value))
      );

      console.log(filterdata, "filterdata");
      setSearhValueData(filterdata);
    }
    // setSearhValue(e.target.value);
  };
  useEffect(() => {
    if (userinfo) {
      if (userinfo.user_type == "User") {
        setUserData(userinfo);
      } else if (userinfo.user_type == "Agent") {
        setUserData(userinfo);
      }
    }
    // myprimiumProperty();
    // myaccountlist();

    // myPropertyperformance();
  }, []);

  useEffect(() => {
    myaccountlist();
  }, [currentPage ,entries]);

  //   useEffect(() => {
  //     myaccountLeadReport();
  //   }, [currentpage2]);

  const [shorted, setshorted] = useState({ shorted: "id", reversed: false });
  const shortedbyId = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      agentlist.data.sort((valuA, valuB) => valuA.data.id - valuB.data.id);
    } else {
      agentlist.data.sort((valuA, valuB) => valuB.data.id - valuA.data.id);
    }
  };

  const shortedPrice = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      agentlist.data.sort(
        (valuA, valuB) => valuA.data.price - valuB.data.price
      );
    } else {
      agentlist.data.sort(
        (valuA, valuB) => valuB.data.price - valuA.data.price
      );
    }
  };


  let nf = new Intl.NumberFormat();

  const [itemsPerPage, setitemsPerPage] = useState(20);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];
  for (let i = 1; i <= Math.ceil(forPagination?.last_page); i++) {
    pages.push(i);
  }
  console.log(pages);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }
  console.log(agentlist);
  return (
    <>
      {loading == false ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img src={loaderImg} style={{ width: "100px" }} />
        </div>
      ) : (
        <div
          class="main-wrapper availableProperty"
          style={{ display: "block" }}
          id="menuTwo"
        >
          <h2>Available Properties</h2>
          <div class="tableFilter flex">
            <div class="dataTables_length" id="datatable-tabletools_length">
              <label>
                Show
                <select
                  name="datatable-tabletools_length"
                  aria-controls="datatable-tabletools"
                  class=""
                  value={entries}
                  onChange={(e) => setEntries(e.target.value)}
                >
                  <option>5</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option>All</option>
                </select>{" "}
                entries
              </label>
            </div>
            <div class="searchFilter">
              <form action="">
                <div class="form_group">
                  <input
                    type="text"
                    placeholder="Search by Postcode"
                    onChange={(e) => setSearhValueHandle(e)}
                  />
                </div>
              </form>
            </div>
          </div>
          <div class="tableWrap">
            <table
              className="table1 tablesorter tablesorter-blue tablesorter72d6f05d2d007"
              data-sortlist="[[0,0],[2,0]]"
            >
              <thead>
                <tr className="tablesorter-headerRow">
                  <th
                    className="tablesorter-header tablesorter-headerAsc"
                    onClick={() => shortedbyId()}
                  >
                    Property
                  </th>
                  <th
                    className="tablesorter-header tablesorter-headerAsc"
                    onClick={() => shortedbyId()}
                  >
                    Postcode
                  </th>
                  <th
                    className="tablesorter-header tablesorter-headerAsc"
                    onClick={() => shortedPrice()}
                  >
                    Price
                  </th>
                  <th className="tablesorter-header tablesorter-headerAsc">
                    First Visible
                  </th>
                  <th className="tablesorter-header tablesorter-headerAsc">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {agentlist?.data
                  .filter((item) => {
                    if (searhValue == "") {
                      return item;
                    } else {
                      return item.postcode.includes(searhValue);
                    }
                  })
                  ?.slice(0, entries ? entries : 5)
                  ?.map((item, index) => {
                    // console.log(item);
                    return (
                      <tr key={index}>
                        <td>
                          <div class="propertyOuter">
                            <div class="property-img">
                              <img src={item.data.images?.image[0]?.url} />
                            </div>
                            <div class="property-name">
                              {item.data?.location_detail
                                ? item.data?.location_detail
                                : "Not Added"}
                            </div>
                          </div>
                        </td>
                        <td>{item?.postcode ? item?.postcode : "Not Added"}</td>
                        <td>
                          {" "}
                          € {item?.price ? nf.format(item?.price) : "Not Added"}
                        </td>
                        <td>
                          {" "}
                          {item.data?.date
                            ? moment(item.data?.date).format("DD MMM, YYYY")
                            : "Not Added"}
                        </td>
                        <td>
                          <div class="set_btns table-controls">
                            <a
                              class="btn view-btn"
                              href={`/property-details/${item.data?.id}`}
                            >
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            </a>
                            {
                              item?.xml_id != null ? "" :
                                <a
                                style={{margin:"0 4px"}}
                                  class="btn edit-btn"
                                  href={`/add-listing-edit/${item?.id}`}
                                >
                                  <i class="fa fa-edit"></i>
                                </a>
                            }
                            <a
                              href="#"
                              onClick={() => delateProperty(item?.id)}
                              class="btn edit delete-check"
                            >
                              {" "}
                              <i class="fa fa-trash"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className="bottomPagenation">
            <div class="dataTables_info" id="datatable-tabletools_info">
              Showing
               {/* {agentlist?.data ? agentlist?.data?.length > currentPage * 5 ? currentPage * 5 : agentlist?.data?.length : "0"} */}
               {"  "}  {agentlist?.data ? agentlist?.data?.length :"0"} {"  "}

                of{" "}

              {agentlist?.data ? agentlist?.total : "0"} entries
            </div>
            <div className="pagenationTable">
              {/* <button>First</button> */}
              <button
                onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false}
              >
                « Previous
              </button>
              {pages.map((number, index) => {
                if (index > currentPage - 3 && index < currentPage + 3) {
                  return (
                    <><p>
                      <span
                        key={number}
                        id={number}
                        onClick={() => setcurrentPage(number)}
                        className={`${currentPage == number ? "active_page" : ""
                          }`}
                      >
                        {number}{" "}
                      </span>
                    </p>

                    </>
                  );
                }
              })}
              <button
                onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              >
                Next »
              </button>{" "}
              {/* <button>Last</button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Section2;
