export const Homedata = [
    {
        img: require("../assets/images/search-icon.png"),
        path :"/buy-advice",
        heading: "Advice and tips when buying in Mallorca",
        description: "Whether you are Spanish, German or British, we have the latest advice to help you buy a property in Mallorca."
    },
    {
        img: require("../assets/images/notify-icon.png"),
        heading: "Be the first to see new properties",
        path :"/buy",

        description: "Register with us to get instant property alerts so you never miss out."
    },

    {
        img: require("../assets/images/house-icon.png"),
        heading: "How much your home is worth",
        path :"/buy",

        description: "Get an accurate market valuation from property experts you select."
    },

]

export const articledata = [
    {
        img: require("../assets/images/new-image1.jpg"),
        heading: "Mallorca Properties",
        description: "With its contrasting coastal regions and quaint villages in the interior of the island, Mallorca offers a huge selection of charming residential areas.",
        readmore: "Read More »",
        path: "#"
    },
    {
        img: require("../assets/images/new-image2.jpg"),
        heading: "How COVID-19 is affecting the Mallorca property market",
        description: "Like almost every sector, the Corona crisis hit the Mallorca property market hard last year. Lockdown, declining purchasing power and entry restrictions led to less sales closing in 2020 than in the immediate years pre Covid.",
        readmore: "Read More »",
        path: "#"
    },

    {
        img: require("../assets/images/new-image3.jpg"),
        heading: "Tips, tricks and facts for buying property in Mallorca",
        description: "The property market on the island is bursting at the seams with chic, cosy and at times properties in need of love.",
        readmore: "Read More »",
        path: "#"
    },

]


export const exploredata = [
    {
        img: require("../assets/images/explore-image.jpg"),
        heading: "Cala D'or",
 id : 1,
        path: "#"
    },
    {
        img: require("../assets/images/explore-image2.jpg"),
        heading: "Port Andratx",
        id : 2,

        path: "#"
    },

    {
        img: require("../assets/images/explore-image3.jpg"),
        heading: "Deia",
        id : 3,

        path: "#"
    },
    {
        img: require("../assets/images/explore-image4.jpg"),
        heading: "Palma",
        id : 4,

        path: "#"
    },
    {
        img: require("../assets/images/explore-image5.jpg"),
        heading: "Soller",
        id : 5,

        path: "#"
    },

]



export const aboutdata = [
    {
        img: require("../assets/images/swap-key.png"),
        heading: "Buy a home",

        path: "#"
    },
    {
        img: require("../assets/images/rent.png"),
        heading: "Rent a home",

        path: "#"
    },

    {
        img: require("../assets/images/buy-home.png"),
        heading: "Sell a home",

        path: "#"
    },
]

export const blogdata = [
    {
        img: require("../assets/images/blog-image1.jpg"),
        heading: "Mallorca Properties",
        description: "With its contrasting coastal regions and quaint villages in the interior of the island, Mallorca offers a huge selection of charming residential areas.",

        path: "#"
    },
    {
        img: require("../assets/images/blog-image2.jpg"),
        heading: "How COVID-19 is affecting the Mallorca property market",
        description: "Like almost every sector, the Corona crisis hit the Mallorca property market hard last year. Lockdown, declining purchasing power and entry restrictions led to less sales closing in 2020 than in the immediate years pre Covid.",

        path: "#"
    },

    {
        img: require("../assets/images/blog-image3.jpg"),
        heading: "Sell a home",
        description: "The property market on the island is bursting at the seams with chic, cosy and at times properties in need of love.",

        path: "#"
    },
]





export const WeAredata = [
    {
        id: 1,
        heading: "BELLA ISLAS"

    },
    {
        id: 2,
        heading: "PROPERTY SEARCH"

    },
    {
        id: 3,
        heading: "TYPES"

    },
    {
        id: 4,
        heading: "SALE & RENT"

    },
    {
        id: 5,
        heading: "FILTER"

    },

    {
        id: 6,
        heading: "LOOKING TO SELL OR RENT?"

    },


]



export const Dashboarddata = [
    {
        id: 1,
        name: "Dashboard",
        path: "#"
    },
    {
        id: 2,

        name: "Property Listing",
        path: "#"
    },
    {
        id: 3,

        name: "Property Performance",
        path: "#"
    },
    {
        id: 4,

        name: "Premium & Featured Listings",
        path: "#"
    },
    {
        id: 5,

        name: "Lead Reports",
        path: "#"
    },

]


export const buyAdviceData = [

    { id: 1,
        heading: "I am a British buyer",
        img: require("../assets/images/buying-advice1.jpeg"),
        path: "#"
    }
    ,
    {
        id: 2,
        heading: "I am a Spanish buyer",
        img: require("../assets/images/buying-advice2.jpeg"),
        path: "/spanish-buy-advice"
    }
    ,
    {
        id: 3,
        heading: "I am a Spanish buyer",
        img: require("../assets/images/buying-advice3.jpeg"),
        path: "#"
    }
]

export const palmaData = [
   
    {
        id:1,
        heading:"About"
    },
    {
        id:2,
        heading:"Arenal"
    },
    {
        id:3,
        heading:"Can Pastilla"
    },
    {
        id:4,
        heading:"Ciudad Jardin"
    },
    {
        id:5,
        heading:"Genova"
    },
    {
        id:6,
        heading:"old Town"
    },
    {
        id:7,
        heading:"Portixol"
    },
    {
        id:8,
        heading:"Son Vida"
    },
    {
        id:9,
        heading:"Santa Catalina"
    }


]


export const southwestData = [
   
    {
        id:1,
        heading:"About"
    },
    {
        id:2,
        heading:"Bendinat"
    },
    {
        id:3,
        heading:"Calvia"
    },
    {
        id:4,
        heading:"Costa d'En Blanes"
    },
    {
        id:5,
        heading:"Es Camp de Mar"
    },
    {
        id:6,
        heading:"Es Capdellà"
    },
    {
        id:7,
        heading:"Magaluf"
    },
    {
        id:8,
        heading:"Palmanova"
    },
    {
        id:9,
        heading:"Peguera"
    },
    {
        id:10,
        heading:"Port d’Andratx"
    },
    {
        id:11,
        heading:"Portals"
    },
    {
        id:12,
        heading:"Portals Nous"
    },
    {
        id:13,
        heading:"Santa Ponsa"
    }


]

export const southeastData = [
   
    {
        id:1,
        heading:"About"
    },
    {
        id:2,
        heading:"Cala d’Or"
    },
    {
        id:3,
        heading:"Cala Millor"
    },
    {
        id:4,
        heading:"Campos"
    },
    {
        id:5,
        heading:"Colonia de Sant Jordi"
    },
    {
        id:6,
        heading:"Felanitx"
    },
    {
        id:7,
        heading:"Porto Cristo"
    },
    {
        id:8,
        heading:"Portocolom"
    },
    {
        id:9,
        heading:"Santanyi"
    },
   

]


export const westData = [
   
    {
        id:1,
        heading:"About"
    },
    {
        id:2,
        heading:"Bañalbufar"
    },
    {
        id:3,
        heading:"Bunyola"
    },
    {
        id:4,
        heading:"Deia"
    },
    {
        id:5,
        heading:"Esporles"
    },
    {
        id:6,
        heading:"Establiments"
    },
    {
        id:7,
        heading:"Estellencs"
    },
    {
        id:8,
        heading:"Fornalutx"
    },
    {
        id:9,
        heading:"Port De Soller"
    },
     {
        id:10,
        heading:"Puigpunyent"
    }, 
    {
        id:11,
        heading:"Valdemossa"
    },
    {
        id:12,
        heading:"Soller"
    },
   

]


export const northData = [
   
    {
        id:1,
        heading:"About"
    },
    {
        id:2,
        heading:"Alcúdia"
    },
    {
        id:3,
        heading:"Can Picafort"
    },
    {
        id:4,
        heading:"Pollensa"
    },
    {
        id:5,
        heading:"Port d’Alcudia"
    },
    {
        id:6,
        heading:"Port de Pollensa"
    },
    {
        id:7,
        heading:"Sa Pobla"
    },
   
   

]

export const northEastData = [
   
    {
        id:1,
        heading:"About"
    },
    {
        id:2,
        heading:"Arta"
    },
    {
        id:3,
        heading:"Campanet"
    },
    {
        id:4,
        heading:"Capdepera"
    },
    {
        id:5,
        heading:"Manacor"
    },
    {
        id:6,
        heading:"Muro"
    },
    {
        id:7,
        heading:" Sa Coma "
    },
   
   

]


export const centralData = [
   
    {
        id:1,
        heading:"About"
    },
    {
        id:2,
        heading:"Alaro"
    },
    {
        id:3,
        heading:"Algaida"
    },
    {
        id:4,
        heading:"Ariany"
    },
    {
        id:5,
        heading:"Binissalem"
    },
    {
        id:6,
        heading:"Buger"
    },
    {
        id:7,
        heading:"Consell"
    },
    {
        id:8,
        heading:"Inca"
    },
    {
        id:9,
        heading:"Llucmajor"
    },
     {
        id:10,
        heading:"Marratxi"
    }, 
    {
        id:11,
        heading:"Porreres"
    },
    {
        id:12,
        heading:"Santa Maria del Cami"
    },
    {
        id:13,
        heading:"Sineu"
    },
    {
        id:14,
        heading:"Selva"
    },
   

]

// export const myaccountData = [
  
//     {
//         heading: "I am a British buyer",
//         img: require("../assets/images/bookmark-img1.jpg"),
//         path: "#"
//     }
//     ,
//     {
//         heading: "I am a Spanish buyer",
//         img: require("../assets/images/buying-advice2.jpeg"),
//         path: "#"
//     }
//     ,
//     {
//         heading: "I am a Spanish buyer",
//         img: require("../assets/images/buying-advice3.jpeg"),
//         path: "#"
//     }
// ]



export const myBookmarkData = [

    {

        id: 1,
        address: "Palma de Mallorca",
        price: 1700,
        img: [
            require("../assets/images/bookmark-img1.jpg"),
            require("../assets/images/bookmark-img2.jpg"),
            require("../assets/images/bookmark-img3.jpg"),
        ],
        view: "#",
        delete: "#"
    }
    ,
    {

        id: 2,
        address: "Palma de Mallorca",
        price: 1700,
        img: [
            require("../assets/images/bookmark-img1.jpg"),
            require("../assets/images/bookmark-img2.jpg"),
            require("../assets/images/bookmark-img3.jpg"),
        ],
        view: "#",
        delete: "#"
    }
    ,
    {

        id: 3,
        address: "Palma de Mallorca",
        price: 1700,
        img: [
            require("../assets/images/bookmark-img1.jpg"),
            require("../assets/images/bookmark-img2.jpg"),
            require("../assets/images/bookmark-img3.jpg"),
        ],
        view: "#",
        delete: "#"
    }
    ,
    {

        id: 4,
        address: "Palma de Mallorca",
        price: 1700,
        img: [
            require("../assets/images/bookmark-img1.jpg"),
            require("../assets/images/bookmark-img2.jpg"),
            require("../assets/images/bookmark-img3.jpg"),
        ],
        view: "#",
        delete: "#"
    }
    ,
]


