import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { baseUrl } from '../../../baseUrl'

function NewsLetter() {
    const [message, setMessage] = useState("")



    const handleSubmit = async (event) => {
        const email = event.target.email.value
if(email !== ""){

    await axios.post(baseUrl + `/news-letter-subscribe?email=${email}`).then((res) => {
        console.log(res);
        event.target.reset();
        if (res.status === 200) {
            setMessage("The form was sent successfully.")
            setTimeout(() => {
                setMessage("")
            }, 3000)
        } else {
            setMessage("")
        }
    }).catch((error) => {
        console.log(error);
    })
}else{
    setMessage("Please Enter Email  !") 
}
    }









    return (
        <>
            <section className="newsletter" data-aos="fade-in">
                <div className="container">
                    <div className="container-sm">
                        <div className="wrapper flex">
                            <h3>Subscribe to our newsletter</h3>
                            <form action="" className="newsletterForm" method='dialog' onSubmit={handleSubmit}>
                                <div className="form_group">
                                    <input type="email" placeholder="Your email" id='email'  />
                                    <div className='sub_success'>{message}</div>
                                </div>
                                <div className="form_group">
                                    <button type="submit" className="sub-btns" >Subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewsLetter
