import React, { useState } from 'react'
import { WeAredata } from '../../../Data/Data'


function WeAre() {

    const [description, setdescription] = useState(1)

   // console.log(description);


    return (
        <>
            <section className="who-we-are" data-aos="fade-in">
                <div className="container-sm">
                    <div className="wrapper flex">
                        <div className="col">
                            <div className="heading-line">
                                <h3>Who we are:</h3>
                            </div>
                        </div>
                        <div className="col">
                            <ul className="tab">
                                {
                                    WeAredata.map((item, index) => {
                                        return (

                                            <li key={item.id} className={`${description === item.id ? "setActive" : ""}`} onClick={() => setdescription(item.id)} >{item.heading}</li>

                                        )
                                    })
                                }

                            </ul>
                            <div className="tab-wrapper">
                                {
                                    description === 1 ?

                                        <div className="">
                                            <h2>

                                                The real estate portal to find homes for sale and rent in Mallorca. Search for the ideal house, apartment or land.

                                            </h2>

                                        </div> :
                                        description === 2 ?


                                            <div className="" >
                                                <h2>Search for a home by selecting the city or area that you are interested in. If you are unsure of which area you want to buy or rent then visit our Mallorca page.</h2>
                                            </div> :
                                            description === 3 ?
                                                <div className="" >
                                                    <h2>You can also decide how many rooms you prefer to have in your home and whether you would like to live in an apartment, house, buy a plot of land or if you would like a new build.</h2>
                                                </div> : description === 4 ?
                                                    <div className="" >
                                                        <h2>To help you find the property of your dreams, we provide you with properties across the whole of Mallorca for sale and to rent.</h2>
                                                    </div>
                                                    : description === 5 ?
                                                        <div className="" >
                                                            <h2>You can filter your search results by price, number of rooms, number of bathrooms, the presence of garden, parking, pool, elevators and many more.</h2>
                                                        </div>
                                                        :
                                                        <div className="" >
                                                            <h2>Do you have a property to sell or rent? With bella islas you can easily search for the right real estate agencies to suit you.</h2>
                                                        </div>

                                }
                                { /* <div className="tab-content" >
                                <h2>Search for a home by selecting the city or area that you are interested in. If you are unsure of which area you want to buy or rent then visit our Mallorca page.</h2>
                            </div>
                            <div className="tab-content" id="three">
                                <h2>You can also decide how many rooms you prefer to have in your home and whether you would like to live in an apartment, house, buy a plot of land or if you would like a new build.</h2>
                            </div>
                            <div className="tab-content" id="four">
                                <h2>To help you find the property of your dreams, we provide you with properties across the whole of Mallorca for sale and to rent.</h2>
                            </div>
                            <div className="tab-content" id="five">
                                <h2>You can filter your search results by price, number of rooms, number of bathrooms, the presence of garden, parking, pool, elevators and many more.</h2>
                            </div>
                            <div className="tab-content" id="six">
                                <h2>Do you have a property to sell or rent? With bella islas you can easily search for the right real estate agencies to suit you.</h2>
                            </div>

                                */}
                            </div>
                            <div className="accordian">
                                <div className="box">
                                    <div className="head">
                                        <h4 className={`${description === 1 ? "setActive" : ""}`} onClick={() => setdescription(1)}>BELLA ISLAS</h4>
                                    </div>
                                    {description === 1 ?

                                        <div className="">
                                            <h2>

                                                The real estate portal to find homes for sale and rent in Mallorca. Search for the ideal house, apartment or land.

                                            </h2>

                                        </div> : ""}
                                </div>
                                <div className="box">
                                    <div className="head">
                                        <h4 className={`${description === 2 ? "setActive" : ""}`} onClick={() => setdescription(2)}>PROPERTY SEARCH</h4>
                                    </div>
                                    {description === 2 ?

                                        <div className="">
                                            <h2>Search for a home by selecting the city or area that you are interested in. If you are unsure of which area you want to buy or rent then visit our Mallorca page.</h2>

                                        </div> : ""}


                                </div>
                                <div className="box">
                                    <div className="head">
                                        <h4 className={`${description === 3 ? "setActive" : ""}`} onClick={() => setdescription(3)}>TYPES</h4>
                                    </div>
                                    {description === 3 ?

                                        <div className="">
                                            <h2>You can also decide how many rooms you prefer to have in your home and whether you would like to live in an apartment, house, buy a plot of land or if you would like a new build.</h2>

                                        </div> : ""}


                                </div>
                                <div className="box">
                                    <div className="head">
                                        <h4 className={`${description === 4 ? "setActive" : ""}`} onClick={() => setdescription(4)}>SALE & RENT</h4>
                                    </div>
                                    {description === 4 ?

                                        <div className="">
                                            <h2>To help you find the property of your dreams, we provide you with properties across the whole of Mallorca for sale and to rent.</h2>

                                        </div> : ""}


                                </div>
                                <div className="box">
                                    <div className="head">
                                        <h4 className={`${description === 5 ? "setActive" : ""}`} onClick={() => setdescription(5)}>FILTER</h4>
                                    </div>
                                    {description === 5 ?

                                        <div className="">
                                            <h2>You can filter your search results by price, number of rooms, number of bathrooms, the presence of garden, parking, pool, elevators and many more.</h2>

                                        </div> : ""}


                                </div>
                                <div className="box">

                                    <div className="head">
                                        <h4 className={`${description === 6 ? "setActive" : ""}`} onClick={() => setdescription(6)}>LOOKING TO SELL OR RENT?</h4>
                                    </div>
                                    {description === 6 ?

                                        <div className="">
                                            <h2>Do you have a property to sell or rent? With bella islas you can easily search for the right real estate agencies to suit you.</h2>

                                        </div> : ""}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WeAre
