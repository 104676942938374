import React from "react";
import Slider from "react-slick";
import Layout from "../../Layout/Layout";
import bed from "../../../assets/images/single-bed.png";
import bathroom_size from "../../../assets/images/bathrooms_size.png";
import bath from "../../../assets/images/bath.png";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../../baseUrl";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import PremiumSection from "../SearchSections/PremiumSection";
import { toast } from "react-toastify";
import swal from "sweetalert";

const img = [
  require("../../../assets/images/bookmark-img1.jpg"),
  require("../../../assets/images/bookmark-img2.jpg"),
  require("../../../assets/images/bookmark-img3.jpg"),
];
function Rent(props) {
  const [loader, setLoader] = useState(false);
  const detials = props.details;
  const total = props.total;
  const town = props.town;
  const navigate = useNavigate();
  const premiumDetails = props.premiumDetails;
  const setshorting = props.setshorting;
  const shorting = props.shorting;

  //console.log("ffffff>>>>>", detials);
  // const location = props.location
  const error = props.error;
  const [p_Id, setP_Id] = useState("");
  const [addSucces, setaddSucces] = useState("");
  const [AddFav, setAddFav] = useState(false);
  // console.log("property_id", );
  const property = props.totalResult;
  const token = JSON.parse(localStorage.getItem("token"));
  const islogin = JSON.parse(localStorage.getItem("login"));

  const postFavourite = async (property_id) => {
    // e.preventDefault();

    const body = { property_id: property_id };
    const res = await axios.post(
      baseUrl + "/Add/favourite-property",
      body,

      { headers: { Authorization: `Bearer ${token}` } }
    );
    const data = await res.data;
    if (res.status == 200) {
      setAddFav(true);

      swal({
        title: "Bella Islas says",
        text: res.data.message,
        icon: "success",
        button: "OK",
        // timer: 3000,
      });
    }
    // console.log(data,islogin, property_id);
  };

  // useEffect(() => {
  //   postFavourite();
  // }, [postFavourite()]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,

    slidesToScroll: 1,
  };

  const passId = (id) => {
    navigate(`/property-details/${id}`);
  };

  // const filterDetails = detials?.filter(
  //   (item) =>
  //     item.data.price_freq === "month" || item.data.price_freq === "week"
  // );
  //console.log(  setshorting(detials.sort((a,b)=> a.price-b.price)))

  const shortbyprice = () => {
    // detials.sort((a, b) => b.data.price - a.data.price);
  };

  const handleFilter = (e) => {
    console.log(e.target.value, "filtered");
    setshorting(e.target.value);
    //else{
    //   detials.sort((a,b)=> a.data.price -b.data.price)
    // }
  };

  let nf = new Intl.NumberFormat();
  // nf.format

  const handlenewtab = (event) => {
    // console.log(event);
    // if (event.ctrlKey) {
    //   window.open(`/rent`, "_blank");
    // }
  };

  return (
    <>
      <section className="fillter_res">
        <div className="container_">
          <div className="top_bar flex">
            <h5>
              Properties for Rent in {town !== "" ? town + " :" : "Mallorca :"}
              {"     "}
              {total?.total !== "" ? total?.total : "0"} property results
            </h5>
            <div className="result-fillter">
              <label for="sort">Sort:</label>
              <select
                id="sortType"
                name="sort"
                onChange={(e) => handleFilter(e)}
                className="select sort-select"
                // defaultValue={shorting== "low" ? "Lowest Price":""}
                value={shorting == "low" ? "low" : shorting == "high" ? "high" : shorting == "new" ? "new" : shorting == "old" ? "old" : ""}
              >
                {/* <option value="">Select option</option> */}
                <option value="low">Lowest Price</option>
                <option value="high" onClick={shortbyprice}>
                  Highest Price
                </option>
                <option value="new">Newest Listed</option>
                <option value="old">Oldest Listed</option>
              </select>
            </div>
          </div>
          <PremiumSection premiumDetails={premiumDetails} />

          {/* <h3 className="top_heading"> Premium Featured-New to the market </h3> */}
          <h1>Available Listing</h1>
          <div style={{ textAlign: "center" }}>{error}</div>
          {detials?.map((item, index) => {
            var type2 = JSON.stringify(item.data?.type).replace(
              /[\\[,"\]']+/g,
              "  "
            );

            return (
              <div
                className="listings "
                key={item.data.id}
                onClick={(e) => handlenewtab(e)}
              >
                <div className="listDataRight uper_side">
                  <div
                    // className={`${addSucces === true ? "fav_add_btn" : ""}`}
                    // type="submit"
                    onClick={() => {
                      if (islogin) {
                        postFavourite(item.property_id);
                      } else {
                        swal({
                          title: "Bella Islas says",
                          text: "Please Log in First !",
                          icon: "error",
                          button: "OK",
                          // timer: 3000,
                        });
                      }
                    }}
                  >
                    {" "}
                    {addSucces == item.property_id && AddFav == true ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0H24V24H0z" />
                        <path
                          d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z"
                          fill="rgba(255,140,0,1)"
                        />
                      </svg>
                    ) : (
                      <span
                        className="heart_hover"
                        onClick={() => setaddSucces(item.property_id)}
                      >
                        <i class="far fa-heart"></i>
                      </span>
                    )}
                  </div>
                </div>
                <NavLink
                  className="flex"
                  to={`/property-details/${item.property_id}`}
                >
                  <div className="col_">
                    <div className="bookmark-slider_mob ">
                      <div className="slickArrow bookmark-slider_mob bookmark-slider list-img-slider">
                        <Slider {...settings}>
                          {item.data?.images.image !== undefined
                            ? item.data?.images.image.map((img, i) => {
                              return (
                                <div key={i}>
                                  <figure style={{ cursor: "pointer" }}>
                                    <img src={img.url} alt="loading img..." />
                                  </figure>
                                </div>
                              );
                            })
                            : ""}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="col_" style={{ cursor: "pointer" }}>
                    <div className="listDataContainer">
                      <a>
                        <div className="listDataOuter">
                          <div
                            className="listingtitle"
                            style={{ cursor: "pointer" }}
                          >
                            {item.data?.beds} bedroom {type2 ? type2 : ""} to
                            rent
                          </div>
                          <div className="pricesection">
                            <span className="price">
                              <b>
                                €{" "}
                                {item?.data.price
                                  ? nf.format(item?.data.price)
                                  : ""}
                              </b>{" "}
                              {/* {item.data?.price_freq} */}
                            </span>
                            <span className="bedrooms">
                              {item.data?.beds} <img src={bed} alt="logo" />{" "}
                            </span>
                            <span className="bathrooms">
                              {item.data?.baths} <img src={bath} />{" "}
                            </span>
                            <span className="bathrooms_size">
                              {item.data?.surface_area?.built == null ? (
                                ""
                              ) : (
                                <>
                                  <img src={bathroom_size} alt="" />
                                  <span>
                                    {item.data?.surface_area.built
                                      ? item.data.surface_area.built
                                      : ""}
                                    m<sup>2</sup>{" "}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>

                          <div className="listingaddress">
                            <i
                              aria-hidden="true"
                              className="fas fa-map-marker-alt"
                            ></i>

                            {item.data?.town
                              .substr(item.data?.town.lastIndexOf("\\") + 1)
                              .split("[")[1]
                              ? JSON.parse(item.data?.town).map(
                                (a, index2) => a + " "
                              )
                              : item.data?.town}
                            {/* {item.data?.town?.replace(/[\[,"\]']+/g, " | ")
                              ? item.data?.town?.replace(/[\[,"\]']+/g, " | ")
                              : ""} */}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="agentname">
                      <div>
                        <p className="eng_desFont">
                          {item.english_description?.substring(0, 250)}
                        </p>
                      </div>
                      <div className="wrap flex">
                        <p>
                          <NavLink to={`/property-details/${item.property_id}`}>
                            {item?.company_name}
                          </NavLink>
                        </p>
                        <figure>
                          <a
                            href={`/author/${item?.company_name}`}
                            className="firstalpha"
                          >
                            {item?.company_name?.charAt(0)}
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Rent;
