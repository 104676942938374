import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import "../../Styles/mainstyle/style.css";
import { baseUrl } from "../../baseUrl";
import axios from "axios";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import img1 from "../../assets/images/related_properties_img.jpg";
import beds from "../../assets/images/single-bed.png";
import baths from "../../assets/images/bath.png";
import Stack from "@mui/material/Stack";

import bathsSize from "../../assets/images/bathrooms_size.png";
const emailRegex = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);
function RequestView() {
  const propertyId = useParams();
  const [value, onChange] = useState(new Date());

  console.log(propertyId.propertyId);
  const [viewerDetails, setviewerDetails] = useState();
  const [showsubmit, setShowsubmit] = useState("1");
  const [details, setDetails] = useState();
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  console.log("for loading  ", details?.town, showsubmit);
  const [date, setDate] = useState("");
  const userinfo = JSON.parse(localStorage.getItem("user"));
  const [userinfo2, setuserinfo] = useState();
  const [userId, setUserId] = useState();
  const [check, setcheck] = useState(true);
  const [CallAgentName, setCallAgentName] = useState("")
  const [CallAgentEmail, setCallAgentEmail] = useState("")
  const [CallAgentPhone, setCallAgentPhone] = useState("")


console.log(date ,">>>>>>>>>>");

  useEffect(() => {
    if (check) {
      setCallAgentName(JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))?.name : "")
      setCallAgentEmail(JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))?.email : "")
      setCallAgentPhone(JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))?.phone : "")
      setcheck(false)
    }
  }, []);

  console.log(">>>>>", userinfo2, value);
  const [error2, seterror2] = useState({
    username: "",
    password: "",
    resetemailadd: ""
  });
  const logins_field = (e) => {

    const { name, value } = e.target;
    switch (name) {
      case 'name':
        error2.username =
          value.length > 4
            ? "" : "Email not valid."
          ;
        break;
      case 'phone':
        error2.password =
          value.length < 13
            ? "Minimum 8 characters required"
            : "";
        break;
      case 'email':
        error2.resetemailadd =
          emailRegex.test(value) ? "" : "Email not valid.";
        break;
      default:
        break;
    }
    if (name == "name") {
      setCallAgentName(value)
    }
    if (name == "phone") {
      setCallAgentPhone(value)
    }
    if (name == "email") {
      setCallAgentEmail(value)
    }
    seterror2(error2)
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const callAgent = async (e) => {
    e.preventDefault();
    console.log("clicked");
    const name = CallAgentName;
    const email = CallAgentEmail;
    const contact = CallAgentPhone;
    const property_id = propertyId.propertyId;
    const date_time = date;
    const request_viewing = "2";
    const agent_id = userId?.user_id ? userId?.user_id : "";

    const data = {
      name,
      email,
      contact,
      date_time,
      property_id,
      request_viewing,
      agent_id,
    };
    console.log(data);
    const token = JSON.parse(localStorage.getItem("token"));
    if (name !== "" || email !== "" || contact !== "" || error2.password == "" || error2.username == "" || error2.resetemailadd == "") {
      try {
        const res = await axios.post(baseUrl + `/property/call-agent`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(res);
        const details = res.data;
        if (res.status == 200) {
          setStatus(res.status);
          //console.log(res);
        }
        console.log("callagent res ", details);
        if (details.status) {
          setviewerDetails(details.request_confirmation);
        }
      } catch (error) {
        //console.log(error);
        if (error) {
          setError("Fill All Required Field !");
        }
      }
    } else {
      //setEmptyMessage("Fill All Field");
    }
  };

  setTimeout(() => {
    setError("");
  }, 4000);

  const fetchproperty = async () => {
    try {
      const res = await axios.get(
        baseUrl + `/property?property_id=${propertyId.propertyId}`
      );
      const propertydetails = res.data;
      console.log("filtered", propertydetails.data);
      if (propertydetails) {
        setDetails(propertydetails.data.xml.data);
        setUserId(propertydetails.data.xml);
      }
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    }
  };
  let nf = new Intl.NumberFormat();

  useEffect(() => {
    fetchproperty();
    if (userinfo !== null) {
      setuserinfo(userinfo);
    }
  }, []);

  console.log(">>>>>>>>>>>", error2, CallAgentName, CallAgentEmail, CallAgentPhone);
  return (
    <>
      <Layout>
        {/* <!-- request-view section start here --> */}
        <section className="request-view">
          <div className="container-sm">
            <div className="wrapper flex">
              {status == 200 ? (
                <div className="col">
                  <h3>Request date and time</h3>
                  <div className="request-confirmation">
                    <p>
                      <strong>Your Appointment details are:</strong>
                    </p>
                    <p>
                      <strong>Name:</strong> {viewerDetails.name}
                    </p>
                    <p>
                      <strong>Email:</strong> {viewerDetails.email}
                    </p>
                    <p>
                      <strong>Phone Number:</strong> {viewerDetails.contact}
                    </p>
                    <p>
                      <strong>Date &amp; Time:</strong>{" "}
                      {viewerDetails.date_time}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="col">
                  <h3>Request date and time</h3>
                  <p>
                    Any date and time is subject to confirmation by the Estate
                    Agent
                  </p>
                  <form action="" onSubmit={(e) => callAgent(e)}>
                    <div className="form_group">
                     
                          <Stack component="form" noValidate spacing={3}>
                        <div className="input-date" id="datetimepicker1">
                          <input
                            id="datetime-local"
                            label="Next appointment"
                            type="datetime-local"
                            sx={{ width: 250 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autocomplete="off"
                            placeholder="Select Date and Time"
                            onChange={(e) => setDate(e.target.value)}
                            onClick={(e)=>{
                              if(e.target.value!=""){
                                setDate(e.target.value)
                              }
                            }}
                          />

                          {/* <span className="input-group-addon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="14"
                              viewBox="0 0 448 512"
                            >
                              <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
                            </svg>
                          </span> */}
                        </div>
                      </Stack>

                      

                    </div>
                    <div className="form_group">
                      <label>Name</label>
                      <input
                        type="text"
                        // value={userinfo2?.name ? userinfo2?.name : ""}
                        placeholder="Enter Name"
                        id="name"
                        onChange={(e) => logins_field(e)} name="name" value={CallAgentName} style={error2.username != "" ? { borderBottom: "2px solid red" } : {}}
                      />
                    </div>
                    <div className="form_group">
                      <label>Email</label>
                      <input
                        type="email"
                        // value={userinfo2?.email ? userinfo2?.email : ""}
                        placeholder="Enter Email"
                        id="email"
                        onChange={(e) => logins_field(e)} name="email" value={CallAgentEmail} style={error2.resetemailadd != "" ? { borderBottom: "2px solid red" } : {}}
                      />
                    </div>
                    <div className="form_group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        // value={userinfo2?.phone ? userinfo2?.phone : ""}

                        placeholder="Enter Phone Number"
                        id="number"
                        onChange={(e) => logins_field(e)} name="phone" value={CallAgentPhone} style={error2.password != "" ? { borderBottom: "2px solid red" } : {}}
                      />
                    </div>
                    <div className="form_group">
                      {/* {date === "" ? "" : (
                        <button
                          type="submit"
                          style={{ display: "inline-block" }}
                        >
                          SUBMIT
                        </button>
                      )} */}
                       {/* <button
                          type="submit"
                          className={ date === "" ? "" :"submitActivebtn"}
                          //style={{ display: date === "" ? "" : "block" }}
                        >
                          SUBMIT
                        </button> */}
                        <button
                          type="submit"
                          style={{ display: "inline-block" }}
                        >
                          SUBMIT
                        </button>
                    </div>
                    <div style={{ textAlign: "center", fontSize: "14px" }}>
                      {error}{" "}
                    </div>
                  </form>
                </div>
              )}

              <div className="col">
                <h3>Property to be viewed</h3>
                <div className="request-view-slider">
                  <div className="bookmark-slider_mob ">
                    <div className="bookmark-slider_mob bookmark-slider list-img-slider">
                      <Slider {...settings}>
                        {details?.images.image.map((img, index) => {
                          return (
                            <div key={index}>
                              <figure>
                                <img src={img.url} alt="img..." />
                              </figure>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="listingaddress">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="14"
                    viewBox="0 0 384 512"
                  >
                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z" />
                  </svg>
                  {details?.town?.replace(/[\["\]']+/g, "  ")}
                </div>
                <div className="pricesection">
                  <span className="price">
                    <b>€ {details?.price ? nf.format(details?.price) : ""}</b> {" "}

                  </span>
                  <span className="bedrooms">
                    {details?.beds} <img src={beds} />{" "}
                  </span>
                  <span className="bathrooms">
                    {details?.baths} <img src={baths} />{" "}
                  </span>
                  <span className="bathrooms_size">
                            {details?.surface_area?.built == null ? (
                              ""
                            ) : (
                              <>
                                <img src={bathsSize} alt="" />
                                <span>
                                  {details?.surface_area?.built
                                    ? details?.surface_area?.built
                                    : ""}
                                  m<sup>2</sup>{" "}
                                </span>
                              </>
                            )}
                          </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- request-view section end here --> */}
      </Layout>
    </>
  );
}

export default RequestView;
