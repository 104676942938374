import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import img1 from "../../assets/images/related_properties_img.jpg";
import Slider from "react-slick";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { NavLink, useParams } from "react-router-dom";
import swal from 'sweetalert';

import loader from "../../assets/images/loader.svg";

const emailRegex = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);
function Author() {
  const { comapnyname } = useParams();
  const [loading, setloading] = useState(false);
  const [show, setshow] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const [propertydetails, setpropertydetails] = useState();
  const [message, setmessage] = useState("")
  //const [currentPage, setCurrentPage] = useState(1);
  const [check, setcheck] = useState(true);
  const [CallAgentName, setCallAgentName] = useState("")
  const [CallAgentEmail, setCallAgentEmail] = useState("")
  const [CallAgentPhone, setCallAgentPhone] = useState("")
  const [userInformation, setuserInformation] = useState()
  const [currentPage, setcurrentPage] = useState(1);

  const page = [];
  const [forPagination, setForPagination] = useState([]);
  // const totalPage = forPagination?.length - 2;
  // for (let i = 0; i < Math.ceil(totalPage); i++) {
  //   page.push(i + 1);
  // }

  console.log("forPagination", forPagination);
  const fetchpropertydetails = async () => {
    setloading(false);

    await axios
      .get(`https://admin.bella.sdsstaging.co.uk/api/company/property-list?page=${currentPage}&&company_name=${comapnyname}`, {
        headers: {
          Authorization: `No Auth`,
        },
      })
      .then((res) => {
        console.log(res);
        if ((res.status = 200)) {
          setForPagination(res.data.data?.property);

          setpropertydetails(res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        if (error) {
          setloading(true);
        }
        console.log(error);
      });
  };

  useEffect(() => {
    fetchpropertydetails();
  }, [currentPage]);





  const handleSubmit = (event) => {
    event.preventDefault();
    const name = CallAgentName
    const subject = event.target.subject.value;
    const email = CallAgentEmail;
    const contact = CallAgentPhone;
    const message = event.target.message.value;
    const data = { name, subject, email, contact, message };

    if (name !== "" && contact !== "" && subject !== "" && email !== "" && message !== "") {

      axios
        .post(baseUrl + "/property/call-agent-form", data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            event.target.reset()
            setCallAgentName("")
            setCallAgentPhone("")
            setCallAgentEmail("")

            swal({
              title: "Bella Islas says",
              text: response.data.message,
              icon: "success",
              button: "OK",
              // timer: 3000,
            });

            if (name !== "") {
              // setshowPopup(false)
            }
            setmessage(response.data.message)
            setTimeout(() => {
              setshow(false)
            }, 5000);

          } else {
            setmessage("")

          }



        }).catch((error) => {
          console.log(error);
          setmessage(error)
        })

    } else {
      setmessage("Please Fill All Field !")

    }

  }



  // const handlePrev = () => {
  //   if (currentPage !== 1) {
  //     setcurrentPage(currentPage - 1);
  //     setTimeout(() => {

  //       window.scrollTo(0, 0);
  //     }, 2000);
  //   }
  // };

  // const handleNext = () => {
  //   if (currentPage !== totalPage) {
  //     setcurrentPage((currentPage) => currentPage + 1);
  //     setTimeout(() => {

  //       window.scrollTo(0, 0);
  //     }, 2000);    }
  // };



  let nf = new Intl.NumberFormat();


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  const [error2, seterror2] = useState({
    username: "",
    password: "",
    resetemailadd: ""
  });
  const logins_field = (e) => {

    const { name, value } = e.target;
    switch (name) {
      case 'name':
        error2.username =
          value.length > 4
            ? "" : "Email not valid."
          ;
        break;
      case 'phone':
        error2.password =
          value.length < 13
            ? "Minimum 8 characters required"
            : "";
        break;
      case 'email':
        error2.resetemailadd =
          emailRegex.test(value) ? "" : "Email not valid.";
        break;
      default:
        break;
    }
    if (name == "name") {
      setCallAgentName(value)
    }
    if (name == "phone") {
      setCallAgentPhone(value)
    }
    if (name == "email") {
      setCallAgentEmail(value)
    }
    seterror2(error2)
  }
  useEffect(() => {
    if (check) {
      setuserInformation(JSON.parse(localStorage.getItem("user")))
      setCallAgentName(JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))?.name : "")
      setCallAgentEmail(JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))?.email : "")
      setCallAgentPhone(JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))?.phone : "")
      setcheck(false)
    }
  }, [userInformation]);




  const [itemsPerPage, setitemsPerPage] = useState(20);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];
  for (let i = 1; i <= Math.ceil(forPagination?.last_page); i++) {
    pages.push(i);
  }
  console.log(pages);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }




  console.log(propertydetails);


  return (
    <>
      <Layout>
        {/* <!-- banner section start here --> */}

        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loader} alt="loading..." />{" "}
          </div>
        ) : (
          <>
            <section className="user-profile-cover">
              <div className="main-info-desktop">
                <div className="container listing-main-info flex">
                  <div className="col-md-6">
                    <div className="profile-name no-tagline no-rating">


                      <a className=" firstalpha2 ">
                        {comapnyname?.charAt(0)}
                      </a>
                      <h1 className="case27-primary-text">
                        {comapnyname ? comapnyname : ""}
                      </h1>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="listing-main-buttons detail-count-1">
                      <ul>
                        <li className="price-or-date">
                          <div className="lmb-label">Active listings</div>
                          <div className="value">
                            {propertydetails ? propertydetails?.property?.total : "0"}
                          </div>
                        </li>
                        <li id="cta-549f5e" className="lmb-calltoaction">
                          <a href="#" onClick={() => setshow(true)} className="myBtn" id="myBtn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 512 512"
                            >
                              <path d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64z" />
                            </svg>
                            Contact Agent
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="heading_center">
                    <p style={{ fontSize: "40px" }}>Listings</p>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- banner section end here -->

    <!-- author listing section start here --> */}
            <section className="author-listing">
              <div className="container-sm">
                <div className="related-properties">
                  <ul className="flex">
                    {propertydetails?.property?.data?.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="related_pop_slider">
                            <div className="bookmark-slider_mob ">
                              <div className="bookmark-slider_mob bookmark-slider">
                                <Slider {...settings}>
                                  {item.data.images.image?.map((img, i) => {
                                    return (
                                      <div>
                                        <NavLink
                                          to={`/property-details/${item.property_id}`}
                                        >
                                          <figure>
                                            <img
                                              src={img?.url}
                                              style={{ height: "240px" }}
                                              alt="img.."
                                            />
                                          </figure>
                                        </NavLink>
                                      </div>
                                    );
                                  })}
                                </Slider>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <a href="#">
                              <h5>€{nf.format(item.data.price)}</h5>
                              <p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="16"
                                  viewBox="0 0 384 512"
                                >
                                  <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                                </svg>{" "}
                                {item.data.town?.replace(/[\["\]']+/g, "  ")}
                              </p>
                            </a>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="pagination_div second">
                  <button
                    onClick={handlePrevbtn}
                    disabled={currentPage == pages[0] ? true : false}
                  >
                    « Previous
                  </button>
                  <p style={{ display: "flex" }}>
                    {pages.map((number, index) => {
                      if (index > currentPage - 5 && index < currentPage + 5) {
                        return (
                          <>
                            <span
                              key={number}
                              id={number}
                              onClick={() => setcurrentPage(number)}
                              className={`${currentPage == number ? "active_page2" : ""
                                }`}
                            // onClick={() => {
                            //   setCurrentPage(page);
                            //   setTimeout(() => {
                            //     window.scrollTo(0, 0);
                            //   }, 2000);
                            // }}
                            >
                              {number}{" "}
                            </span>

                          </>
                        );
                      }
                    })}
                    {/* <span onClick={handleNextbtn}
                      disabled={currentPage == pages[pages.length - 1] ? true : false}>
                      ...
                    </span> */}
                  </p>
                  <button
                    onClick={handleNextbtn}
                    disabled={currentPage == pages[pages.length - 1] ? true : false}
                  >
                    Next »
                  </button>
                </div>

              </div>
            </section>
            {/* <!-- author listing section end here -->

    <!-- agent contact popup section start here --> */}
            {
              show == true ?
                <section className="agentContact active ">
                  <div className="wrapper">
                    <div className="head">
                      <h2>Please enter your details to call the agent</h2>
                      <div onClick={() => setshow(false)} className="close">×</div>
                    </div>
                    <div className="content">
                      <form onSubmit={handleSubmit}>
                        <div className="form_group">
                          <input type="text" id="name" onChange={(e) => logins_field(e)} defaultValue={userInformation?.name} value={CallAgentName} name="name" style={error2.username != "" ? { borderBottom: "2px solid red" } : {}} placeholder="Name" />
                        </div>
                        <div className="form_group">
                          <input type="email" id="email" onChange={(e) => logins_field(e)} value={CallAgentEmail} name="email" style={error2.resetemailadd != "" ? { borderBottom: "2px solid red" } : {}} placeholder="Email" />
                          {/* <p>{error2.resetemailadd}</p> */}
                        </div>
                        <div className="form_group">
                          <input type="text" maxLength={13} onChange={(e) => logins_field(e)} value={CallAgentPhone} name="phone" style={error2.password != "" ? { borderBottom: "2px solid red" } : {}} id="phone" placeholder="Phone" />
                        </div>
                        <div className="form_group">
                          <input type="text" id="subject" placeholder="Subject" />
                        </div>
                        <div className="form_group full">
                          <textarea name="message" id="message" placeholder="Message"></textarea>
                        </div>
                        <div className="form_group">
                          <button type="submit">Submit</button>
                        </div>
                      </form>
                      <div style={{ fontSize: "15px", textAlign: "center" }}>
                        {message}
                      </div>
                    </div>
                  </div>
                </section> : ""
            }
            {/* <!-- agent contact popup section end here --> */}
          </>
        )}
      </Layout>
    </>
  );
}

export default Author;
