import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';

import store from './Features/store/store';
import {GoogleOAuthProvider} from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
<Provider store={store}>
<GoogleOAuthProvider clientId="1077910817387-91607s82huc8fg7dgs7tb8ju3jt7ctvf.apps.googleusercontent.com">

    <App />
</GoogleOAuthProvider>

</Provider>

  </React.StrictMode>
);

reportWebVitals();
