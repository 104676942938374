import React, { useState } from "react";
import Layout from "../Layout/Layout";

import img1 from "../../assets/images/bookmark-img1.jpg";
import img2 from "../../assets/images/bookmark-img2.jpg";
import img3 from "../../assets/images/bookmark-img3.jpg";
import viewicon from "../../assets/images/view-icon.png";
import dlticon from "../../assets/images/dlt-big-icon.png";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import { myBookmarkData } from "../../Data/Data";
import { baseUrl } from "../../baseUrl";
import loader from "../../assets/images/loader.svg";

function Mybookmark() {
  const [data, setData] = React.useState([]);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  console.log("map", data);
  const fetchproperty = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(baseUrl + "/Add/favourite-list", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const details = res.data;
      if (res.status == 200) {
        setloading(true);
      }
      console.log(details);
      setData(details.data.response.data);
    } catch (error) {
      console.log(error.message);
      seterror(error.message);
      if (error) {
        setloading(true);
      }
    }
  };
  React.useEffect(() => {
    fetchproperty();
  }, []);

  const delateFavourite = async (id) => {
    console.log(id);
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(
        baseUrl + `/delete/favourite-property?id=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const details = res.data;
      console.log("delate", details);
      if (details.success == true) {
        const token = JSON.parse(localStorage.getItem("token"));
        const res = await axios.get(baseUrl + "/Add/favourite-list", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const details = res.data;
        console.log(details);
        setData(details.data.response.data);
      }
    } catch (error) {
      console.log(error.message);
      // setError(error.message)
    }
  };

  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loader} alt="loading..." />{" "}
          </div>
        ) : (
          <section className="bookmark-section">
            <div className="container-lg">
              <ul className="flex_dirction_col flex">
                {data?.length !== 0 ? data.map((item, index) => {
                  return (
                    <>
                      <li className="bookmark-slider_mo" key={index}>
                        <div className="  bookmark-slider">
                          <Slider {...settings}>
                            {item.data.images.image.map((img, i) => {
                              return (
                                <div key={i}>
                                  <figure>
                                    <img src={img.url} alt="loading img..." />
                                  </figure>
                                </div>
                              );
                            })}
                          </Slider>
                        </div>
                        <div className="bookmark-content">
                          <div className="top flex">
                            <h5>
                              <NavLink href="#">
                                € {parseInt(item.data.price).toLocaleString(undefined, { maximumFractionDigits: 2 })} per {item.data.price_freq}
                              </NavLink>
                            </h5>
                            <h5>
                              <NavLink href="#" style={{ marginTop: "5px" }}>
                                {item.data?.town?.replace(/[\[,"\]']+/g, "  ") ? item.data?.town?.replace(/[\[,"\]']+/g, "  ") : ""}
                              </NavLink>
                            </h5>
                          </div>
                          <div className="bottom flex">
                            <NavLink
                              to={`/property-details/${item.property_id}`}
                              className="view-action"
                            >
                              <img src={viewicon} alt="icon.." />
                            </NavLink>
                            <NavLink
                              href="#"
                              className="delete-action"
                              onClick={() => {
                                delateFavourite(item.id);
                              }}
                            >
                              <img src={dlticon} alt="icon.." />
                            </NavLink>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })

                  : <>
                    <div className="heading-line" data-aos="fade-in">
                      <NavLink to="/buy">Go To Add</NavLink>
                    </div>

                  </>
                }
              </ul>
              <div className="loader_set">{error ? error : ""}</div>
            </div>
          </section>
        )}
      </Layout>
    </>
  );
}

export default Mybookmark;
