import React, { useState } from 'react'

import "../../Styles/style.css"
import { baseUrl } from '../../baseUrl'
import axios from "axios"
import { NavLink, useNavigate } from 'react-router-dom';
import Layout from '../Layout/Layout';
import swal from 'sweetalert';

function Signup() {
    const [loginError, setloginError] = useState()

    const navigate = useNavigate()
    const [passwordError, setpasswordError] = useState("")

    const [error, setError] = useState("")


    const submitHandler = (event) => {
        event.preventDefault();
        const name = event.target.name.value;
        const email = event.target.email.value;
        const password = event.target.password.value;
        const c_password = event.target.cpassword.value;



        const data = { name, email, password, c_password };
        const logindata = { email, password, };

        if (password !== c_password) {
            setpasswordError("Confirm Password is not Matched")
        } else {


            axios
                .post(baseUrl + "/register", data)
                .then((response) => {
                    if (response.status === 200) {
                        // alert(response.data.message)
                        // navigate("/signin")
                        console.log(response.data);
                        
swal({
    title:"Bella Islas says",
    text:  response.data.message,
    icon: "success",
    button: "OK",
    // timer: 3000,
  });

                        axios
                            .post(baseUrl + "/login", logindata)
                            .then((resp) => {
                                console.log(" resp ", resp);
                                event.target.reset();
                                if (resp.status === 200) {
                                    //alert(response.data.message)
                                    const user = resp.data.data.user.user_type

                                    console.log(user);
                                    if (user == "User") {
                                        localStorage.setItem("data", JSON.stringify(resp.data))
                                        localStorage.setItem("token", JSON.stringify(resp.data.data.token))
                                        localStorage.setItem("user", JSON.stringify(resp.data.data.user))

                                        localStorage.setItem("login", "true")



                                        navigate("/")

                                    } else if (user == "Agent") {
                                        navigate("/signin")

                                    } else if (user == "admin") {
                                        navigate("/signin")

                                    }


                                } else if (resp.status === 404) {

                                    //alert(response.data.message)
                                    console.log(resp.data.message);
                                    navigate("/signin")

                                } else {
                                    navigate("/signin")

                                }


                            })
                            .catch((error) => {
                                console.log(error);
                                if (error) {
                                    // alert(error.message)
                                    setloginError(error.response.data.message + " ? Please Check Your Password !")
                                } else {
                                    setloginError("")
                                }

                            });






                    }

                    // console.log(response.status);

                    event.target.reset();
                })
                .catch((error) => {
                    console.log(error.message);
                    if (error) {
                        setError(`Something went wrong ? ${error.message} `)

                    } else {
                        setError("")
                    }

                });
            setpasswordError("")
        }


    };






    return (
        <>
            <Layout>

                <section className="login-section">
                    <div className="container-sm">
                        <div className="wrapper">
                            <h3>New to Bella Islas? Register</h3>
                            <p>Already registered? <NavLink to="/signin">Sign In</NavLink></p>
                            <form action="#" className="user-login" onSubmit={submitHandler}>
                                <div className="form_group">
                                    <label for="firstName">First Name <sup>*</sup></label>
                                    <input type="text" name="firstName" id="name" />
                                </div>
                                <div className="form_group">
                                    <label for="lastName">Last Name</label>
                                    <input type="text" name="lastName" />
                                </div>
                                <div className="form_group">
                                    <label for="emailSignup">Email Address <sup>*</sup></label>
                                    <input type="email" name="emailSignup" id="email" />
                                </div>
                                <div className="form_group">
                                    <label for="passwordSignup">Password <sup>*</sup></label>
                                    <input type="password" name="passwordSignup" id="password" />
                                </div>
                                <div className="form_group">
                                    <label for="confirmpasswordSignup">Confirm Password <sup>*</sup></label>
                                    <input type="password" name="confirmpasswordSignup" id="cpassword" />
                                    <span style={{ color: "red", fontSize: "15px" }}> {passwordError} </span>
                                    <span style={{ color: "red", fontSize: "15px" }}>
                                        {error}
                                    </span>
                                    <span></span>

                                </div>
                                <div className="form_group">
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </Layout>

        </>
    )
}

export default Signup
