import React, { useState } from "react";
import { json, NavLink, useNavigate } from "react-router-dom";
import "../../Styles/style.css";
import { baseUrl } from "../../baseUrl";
import axios from "axios";
import jwtdecode from "jwt-decode";
import FacebookLogin from "react-facebook-login";

import Layout from "../Layout/Layout";
import { useDispatch } from "react-redux";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";

function Signin() {
  const dispatch = useDispatch();
  const [checkError, setcheckError] = useState("Please Click on Check Box");
  const [loginError, setloginError] = useState();
  const navigate = useNavigate();
  const [loginGoogle, setloginGoogle] = useState();
  // sessionStorage.clear()

  console.log(loginGoogle, "<<<loginGoogle");
  const checkedHandle = (e) => {
    if (e.target.checked === true) {
      setcheckError("");
    } else if (e.target.checked === false) {
      setcheckError("Please Click on Check Box");
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    const data = { email, password };

    axios
      .post(baseUrl + "/login", data)
      .then((response) => {
        console.log(" resp ", response);
        event.target.reset();
        if (response.status === 200) {
          //alert(response.data.message)
          const user = response.data.data.user.user_type;
          dispatch({ type: "USER_INFO", payload: response.data.data.user });
          console.log(user);
          if (user == "User") {
            localStorage.setItem("data", JSON.stringify(response.data));
            localStorage.setItem(
              "token",
              JSON.stringify(response.data.data.token)
            );
            localStorage.setItem(
              "user",
              JSON.stringify(response.data.data.user)
            );

            localStorage.setItem("login", "true");

            navigate("/");
          } else if (user == "Agent") {
            setloginError("Invalid Usertype ?");
            navigate("/signin");
          } else if (user == "admin") {
            navigate("/agent-dashboard");
          } else {
            setloginError("");
          }
        } else if (response.status === 404) {
          //alert(response.data.message)
          console.log(response.data.message);
          navigate("/signin");
        } else {
          navigate("/signin");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          // alert(error.message)
          setloginError(
            error.response.data.message + " ? Please Check Your Password !"
          );
        } else {
          setloginError("");
        }
      });
  };

  const clientId =
    "1077910817387-91607s82huc8fg7dgs7tb8ju3jt7ctvf.apps.googleusercontent.com";

  const [showloginButton, setShowloginButton] = useState(true);
  const [showlogoutButton, setShowlogoutButton] = useState(false);
  const onLoginSuccess = (res) => {
    console.log("Login Success:", res.profileObj);
    setShowloginButton(false);
    setShowlogoutButton(true);
  };

  const login = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );

        if (res.data.email_verified == true) {
         
          const email = res.data.email;
          const name = res.data.given_name;
          const login_type = "google";
          const data = { name, email ,login_type };
          axios
            .post(baseUrl + "/agent/social-login", data)
            .then((res2) => {
             // console.log(res2);

              if (res2.status == 200) {
                //localStorage.setItem("user", JSON.stringify(res.data));
                localStorage.setItem(
                  "user",
                  JSON.stringify(res2.data.data.user)
                );
                localStorage.setItem("login", "true");
                localStorage.setItem(
                  "token",
                  JSON.stringify(res2.data.data.token)
                );
                navigate("/");
              }
            })
            .catch((error) => {
              console.log(error);
              if (error) {
                // alert(error.message)
                setloginError(
                  error.response.data.message +
                    " ? Please Check Your Password !"
                );
              }
            });
        }
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const responseFacebook = (response) => {
   // console.log(response);
    if (response.accessToken !== "") {
      const userId = response?.userID;
      const name = response?.name;
      const login_type = "facebook";

      const data = { name, userId ,login_type };

      axios
        .post(baseUrl + "/agent/social-login", data)
        .then((res2) => {
         // console.log(res2);

          if (res2.status == 200) {
            //localStorage.setItem("user", JSON.stringify(res.data));
            localStorage.setItem(
              "user",
              JSON.stringify(res2.data.data.user)
            );
            localStorage.setItem("login", "true");
            localStorage.setItem(
              "token",
              JSON.stringify(res2.data.data.token)
            );
            navigate("/");
          }
        })
        .catch((error) => {
          //console.log(error);
          if (error) {
            // alert(error.message)
            setloginError(
              error.response.data.message +
                " ? Please Check Your Password !"
            );
          }
        });
    }
  };

  const componentClicked = (response) => {
    console.log(response);
  };

  return (
    <>
      <Layout>
        <section className="login-section">
          <div className="container-sm">
            <div className="wrapper">
              <h3>Sign in to Bella Islas</h3>
              <p>
                New user? <NavLink to="/signup">Create an account</NavLink>
              </p>
              <form action="#" className="user-login" onSubmit={submitHandler}>
                <div className="form_group">
                  <label for="userEmail">Username or email address</label>
                  <input type="email" name="userEmail" id="email" />
                </div>
                <div className="form_group">
                  <label for="userPassword">Password</label>
                  <input type="password" name="userPassword" id="password" />
                </div>
                <div className="form_group flex">
                  <div className="col">
                    <input
                      type="checkbox"
                      onChange={checkedHandle}
                      name="remember"
                      value="remember me"
                    />

                    <label for="remember">Remember Me</label>
                  </div>

                  <div className="col">
                    <NavLink to="/forgot-password">Lost your password?</NavLink>
                  </div>
                </div>

                <div className="row d-flex text-center">
                  <p style={{ color: "red", fontSize: "15px" }}>{loginError}</p>
                </div>
                <div className="form_group">
                  <button type="submit">Login</button>
                </div>
                <div className="form_group">
                  <div style={{ width: "50%" }}>
                    <div style={{ display: "none" }}>
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          console.log(credentialResponse.credential);

                          var decoded = jwtdecode(
                            credentialResponse.credential
                          );
                          console.log(decoded);
                        }}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <ul class="flex otherLogin">
              <li
                onClick={login}
                style={{ cursor: "pointer", paddingLeft: "2rem" }}
              >
                <a
                //  href="https://bellaislas.com/wp-login.php?loginSocial=google&redirect=https%3A%2F%2Fbellaislas.com%2Fen%2Fuser-login%2F"
                >
                  <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        fill="#4285F4"
                        d="M20.64 12.2045c0-.6381-.0573-1.2518-.1636-1.8409H12v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M12 21c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H3.9574v2.3318C5.4382 18.9832 8.4818 21 12 21z"
                      ></path>
                      <path
                        fill="#FBBC05"
                        d="M6.964 13.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V7.9582H3.9573A8.9965 8.9965 0 0 0 3 12c0 1.4523.3477 2.8268.9573 4.0418L6.964 13.71z"
                      ></path>
                      <path
                        fill="#EA4335"
                        d="M12 6.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C16.4632 3.8918 14.426 3 12 3 8.4818 3 5.4382 5.0168 3.9573 7.9582L6.964 10.29C7.6718 8.1627 9.6559 6.5795 12 6.5795z"
                      ></path>
                    </svg>
                  </div>
                  <div class="content">
                    <p>Continue with Google</p>
                  </div>
                </a>
              </li>
              <li style={{ cursor: "pointer", paddingRight: "2rem" }}>
                <a>
                  <div class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1365.3 1365.3"
                      height="1365.3"
                      width="1365.3"
                    >
                      <path
                        d="M1365.3 682.7A682.7 682.7 0 10576 1357V880H402.7V682.7H576V532.3c0-171.1 102-265.6 257.9-265.6 74.6 0 152.8 13.3 152.8 13.3v168h-86.1c-84.8 0-111.3 52.6-111.3 106.6v128h189.4L948.4 880h-159v477a682.8 682.8 0 00576-674.3"
                        fill="#4285f4"
                      ></path>
                    </svg>
                  </div>
                  <div class="content">
                    <div>
                      <FacebookLogin
                        appId="737865964578823"
                        // autoLoad={false}
                        onClick={componentClicked}
                        fields="name,email,picture"
                        callback={responseFacebook}
                      />
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default Signin;
