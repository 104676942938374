
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import About from './components/Home/About';
import Blog from './components/Home/Blog';
import Signup from './components/Home/Signup';
import Signin from './components/Home/Signin';
import Userdashboard from './components/Home/Userdashboard';
//import Protected from './components/Sections/ProtectedRoute/Protected';
import PrivateRoute from './components/Sections/ProtectedRoute/PrivatevRoute';
import { useEffect, useState } from 'react';
import ScrolTop from './components/Layout/ScrolTop';
import UserEdit from './components/Home/UserEdit';
import 'remixicon/fonts/remixicon.css'
import AgentTerms from './components/AgentData/AgentTerms';
import Whyus from './components/Home/Whyus';
import Mybookmark from './components/Home/Mybookmark';
import Codeofconduct from './components/Home/Codeofconduct';
import BuyAdvice from './components/Home/BuyAdvice';
import Sevedsearches from './components/Home/Sevedsearches';
import UserTerms from './components/Home/UserTerms';
import AgentPrivateRoute from './components/Sections/ProtectedRoute/AgentPrivateRoute';
import PremiumSection from "./components/Sections/SearchSections/PremiumSection"
import Privacypolicy from "./components/Home/Privacypolicy"
import Searchlisting from './components/Home/Searchlisting';
import Buy from './components/Home/Buy';
import Agentlogin from './components/AgentData/Agentlogin';
import ForgotPassword from './components/Home/ForgotPassword';
import Mallorca from './components/Home/Mallorca';
import PropertyDetails from './components/Home/PropertyDetails';
import SpanishBuy from './components/Home/SpanishBuy';
import Author from './components/Home/Author';
import CreateNewPassword from './components/Home/CreateNewPassword';
import Request from './components/Home/Request';
import RequestView from './components/Home/RequestView';
import Privacycenter from './components/Home/Privacycenter';
import BlogDetails from './components/Home/BlogDetails';
import Loadingpage from './components/Home/Loadingpage';
import Test from "./components/Sections/SearchSections/Test"
import Addlisting from './components/Home/Addlisting';
import AgentRegistration from './components/Home/AgentRegistration';
import AgentPropertyEdit from './components/Home/AgentPropertyEdit';
import Commercial from './components/Home/Commercial';
import ChartComponents from './components/Sections/SearchSections/ChartComponents';
import Maps from './components/Sections/LocationCoponents/Maps';
import PaginationComponent from './components/Sections/DashBoardSection/PaginationCom';
import Article from './components/Home/Article';




function App() {



  useEffect(() => {

    const user = JSON.parse(localStorage.getItem("data"))
    // setdata(user.data)
    window.scrollTo({
      top: 0, behavior: "smooth"
    })
  }, [])



  return (

    <>
      <ToastContainer />
      <Router>
        <ScrolTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutus' element={<About />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/agent-registration' element={<AgentRegistration />} />
          <Route path='/blog-details/:id' element={<BlogDetails />} />
          <Route path='/tester' element={<PaginationComponent />} />
          <Route path='/mallorca' element={<Mallorca />} />
          <Route path='/mallorca/:id' element={<Mallorca />} />
          <Route path='/add-listing' element={<Addlisting />} />
          <Route path='/add-listing-edit/:id' element={<AgentPropertyEdit />} />
          <Route path='/landing-page' element={<Loadingpage />} />
          <Route path='/property-details/:propertyId' element={<PropertyDetails />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/createNewpassword/:token' element={<CreateNewPassword />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/view-request/:propertyId' element={<RequestView />} />
          <Route path='/request-confirmation' element={<Request />} />
          <Route path='/author/:comapnyname' element={<Author />} />
          <Route path='/signin' element={<Signin />} />
          <Route path='/chart' element={<ChartComponents />} />
          <Route path='/buy' element={<Buy />} />
          <Route path='/buy/:townid' element={<Buy />} />
          <Route path='/commercial/:townName' element={<Commercial />} />
          <Route path='/commercial' element={<Commercial />} />
          <Route path='/agent-login' element={<Agentlogin />} />
          <Route path='/rent' element={<Searchlisting />} />
          <Route path='/rent/:townName' element={<Searchlisting />} />
          <Route path='/privacy-policy' element={<Privacypolicy />} />
          <Route path='/privacy-policy/:id' element={<Privacypolicy />} />
          <Route path='/privacy-center' element={<Privacycenter />} />
          <Route path='/agent-dashboard' element={<PrivateRoute element={<Userdashboard />} />} />
          <Route path='/my-account' element={<PrivateRoute element={<UserEdit />} />} />
          <Route path='/buy-advice' element={<BuyAdvice />} />
          <Route path='/buy-advice/:id' element={<SpanishBuy />} />
          <Route path='/saved-searches' element={<PrivateRoute element={<Sevedsearches />} />} />
          <Route path='/my-bookmark' element={<PrivateRoute element={<Mybookmark />} />} />
          <Route path='/user-terms' element={<UserTerms />} />
          <Route path='/user-terms/:id' element={<UserTerms />} />
          <Route path='/user-terms-condition' element={<UserTerms />} />
          <Route path='/article/:id' element={<Article />} />

          {/* <Route path='/terms-conditions' element={<AgentPrivateRoute element={<AgentTerms />} />} /> */}
          <Route path='/terms-conditions' element={<AgentTerms />} />
          <Route path='/terms-conditions/:id' element={<AgentTerms />} />


          <Route path='/code-of-conduct/:id' element={<Codeofconduct />} />

          <Route path='/why-us' element={<Whyus />} />


        </Routes>
      </Router>

    </>
  );
}

export default App;
