import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { baseUrl } from '../../../baseUrl'

import { exploredata } from '../../../Data/Data'

function ExploreSection() {

  const [description, setdescription] = useState(1);
  const [descriptionsouth, setdescriptionsouth] = useState(1);
  const [descriptionnorth, setdescriptionnorth] = useState(1);
  const [descriptionwest, setdescriptionwest] = useState(1);

  console.log(description);

  const [loading, setloading] = useState(false);

  const [mallorca, setMallorca] = useState([]);
  console.log(mallorca.mallorcaData);

  const mallorcadata = mallorca.mallorcaData?.filter(
    (item) => item.category == "Palma De Mallorca"
  );


  const southwestData = mallorca.mallorcaData?.filter(
    (item) => item.category == "The South West"
  );

  const southeastData = mallorca.mallorcaData?.filter(
    (item) => item.category == "The South East"
  );
  const westData = mallorca.mallorcaData?.filter(
    (item) => item.category == "The West"
  );
  const northData = mallorca.mallorcaData?.filter(
    (item) => item.category == "The North"
  )
  const northEastData = mallorca.mallorcaData?.filter(
    (item) => item.category == "North East"
  );

  const centralData = mallorca.mallorcaData?.filter(
    (item) => item.category == "Central"
  );


  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/mallorca-list?id=25`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        console.log("townlist==", res);
        if (res.status == 200) {
          setMallorca(res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  useEffect(() => {
    fetchtownList();
  }, []);







  return (
    <>
      <section className="explore-section">
        <div className="container-sm">
          <div className="heading-line" data-aos="fade-in">
            <h3>Explore Mallorca</h3>
            <NavLink to="/mallorca">View All Areas</NavLink>
          </div>
          <ul className="flex">

            {
              exploredata.map((item, index) => {
                return (


                  <li data-aos="fade-up" key={index}>
                    <figure>
                      <NavLink to={`/mallorca/${item.id}`}>
                        <img src={item.img} alt="img loading..." />
                        <div className="figcaption">
                          <h4>{item.heading}</h4>
                        </div>
                      </NavLink>
                    </figure>
                  </li>

                )
              })
            }


          </ul>
        </div>
      </section>
    </>
  )
}

export default ExploreSection
