import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom"; import axios from "axios";
import { baseUrl } from "../../../baseUrl";
import loaderImg from "../../../assets/images/loader.svg";
import moment from "moment";
import ChartComponents from "../../Sections/SearchSections/ChartComponents";
function LeadReport() {


  const [popup, setpopup] = useState(false);
  const [data, setData] = useState(1);
  const [agentData, setAgentData] = useState([]);
  const [userData, setUserData] = useState();
  const token = JSON.parse(localStorage.getItem("token"));
  const [loading, setloading] = useState(false);
  const userinfo = JSON.parse(localStorage.getItem("user"));
  const [agentlist, setagentlist] = useState([]);
  const [currentpae, setcurrentpae] = useState(1);
  const [currentpage2, setcurrentpage2] = useState(1);
  const page = [];
  const page2 = [];
  const [forPagination, setForPagination] = useState([]);
  const [forPagination2, setForPagination2] = useState([]);
  const [leadReportdata, setLeadReportData] = useState();
  const [currentPage, setcurrentPage] = useState(1);

  const [entries, setEntries] = useState();

  const [startingdate, setstartingDate] = useState("");
  const [endingdate, setEndingDate] = useState("");


  console.log(forPagination2, "<<<<<<<<<<<<<");


  const myaccountLeadReport = async () => {
    setloading(false);

    await axios
      .get(baseUrl + `/agent/lead-property?page=${currentPage}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          setLeadReportData(res.data.data);
          setForPagination2(res.data.data?.enquiry);
          //console.log("townlist==", res leadReportdata?.enquiry?);
          setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  useEffect(() => {
    myaccountLeadReport()
  }, [currentPage])



  const [itemsPerPage, setitemsPerPage] = useState(20);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];
  for (let i = 1; i <= Math.ceil(forPagination2?.last_page); i++) {
    pages.push(i);
  }
  console.log(pages);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", leadReportdata);


  return (
    <>
      <div
        class="main-wrapper availableProperty leadReport"
        style={{ display: "block" }}
        id="menuFive"
      >
        <h2>Lead Reports</h2>
        <div class="row">
          <div class="col-md-8">
            <p>Leads from - to -</p>
          </div>
          <div class="col-md-4">
            <div class="input-group input-daterange">
              {" "}
              <b>Date Range</b>
              <input
                type="date"
                class="start-date form-control"
                onChange={(e) => setstartingDate(e.target.value)}
              // value="2022-04-05"
              />
              <span class="input-group-addon">to</span>
              <input
                type="date"
                class="end-date form-control"
                // value="2023-04-19"
                onChange={(e) => setEndingDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <ChartComponents startingdate={startingdate} endingdate={endingdate} />
        </div>

        <div class="container">
          <table
            className="table1 tablesorter tablesorter-blue tablesorter72d6f05d2d007"
            data-sortlist="[[0,0],[2,0]]"
          >
            <thead>
              <tr className="tablesorter-headerRow">
                <th className="tablesorter-header tablesorter-headerAsc">
                  Property
                </th>
                <th className="tablesorter-header tablesorter-headerAsc">
                  Name
                </th>
                <th className="tablesorter-header tablesorter-headerAsc">
                  Email
                </th>
                <th className="tablesorter-header tablesorter-headerAsc">
                  Phone No.
                </th>
                <th className="tablesorter-header tablesorter-headerAsc">
                  Date & Time
                </th>
                <th className="tablesorter-header tablesorter-headerAsc">
                  Enquiry Source
                </th>
              </tr>
            </thead>
            <tbody>
              {leadReportdata?.enquiry?.data ? (
                leadReportdata?.enquiry?.data?.map((item) => {
                  return (
                    <tr>
                      <td>
                        {item?.property_id ? item.property_id : ""}
                      </td>
                      <td>
                        {item?.name ? item?.name : "Not Added"}
                      </td>
                      <td>
                        {item?.email ? item?.email : "Not Added"}
                      </td>
                      <td>
                        {item?.contact
                          ? item?.contact
                          : "Not Added"}
                      </td>
                      <td>
                        {item?.date_time
                          ? moment(item?.date_time).format(
                            "DD MMM, YYYY"
                          )
                          : "Not Added"}
                      </td>
                      <td>
                        {item?.request_viewing
                          ? item?.request_viewing
                          : "Not Added"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No Data</td>
                  <td>No Data</td>
                  <td>No Data</td>
                  <td>No Data</td>
                  <td>No Data</td>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="bottomPagenation">
          <div
            class="dataTables_info"
            id="datatable-tabletools_info"
          >
            Showing{" "}
            {leadReportdata?.enquiry?.data?.length
              ? leadReportdata?.enquiry?.data?.length
              : "0"}{" "}
            of {leadReportdata?.enquiry ? leadReportdata?.enquiry?.total : ""} entries
          </div>
          <div className="pagenationTable">
            {/* <button>First</button> */}
            <button
              onClick={handlePrevbtn}
              disabled={currentPage == pages[0] ? true : false}
            >
              « Previous
            </button>
            {pages.map((number, index) => {
              if (index > currentPage - 3 && index < currentPage + 3) {
                return (
                  <><p>
                    <span
                      key={number}
                      id={number}
                      onClick={() => setcurrentPage(number)}
                      className={`${currentPage == number ? "active_page" : ""
                        }`}
                    >
                      {number}{" "}
                    </span>
                  </p>

                  </>
                );
              }
            })}
            <button
              onClick={handleNextbtn}
              disabled={currentPage == pages[pages.length - 1] ? true : false}
            >
              Next »
            </button>{" "}
            {/* <button>Last</button> */}
          </div>
        </div>
      </div>

    </>
  )
}

export default LeadReport