import Layout from "../Layout/Layout";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import loader from "../../assets/images/loader.svg";

function AgentTerms() {
  const { id } = useParams();
  console.log(id);

  const [loading, setloading] = useState(false);

  const [privacy, setprivacy] = useState([]);
  //   console.log("pppp", privacy[0]);
  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/cookies/?id=${id ? id : 2}`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        console.log("townlist==", res);
        if (res.status == 200) {
          setprivacy(res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  useEffect(() => {
    fetchtownList();
  }, []);

  var des = privacy[0] ? privacy[0].description : "";


  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loader} alt="loading..." />{" "}
          </div>
        ) : (
          <section className="comman-contents">
            <div className="container-sm">
              <div className="center-heading">
                <h1>Agent Terms & Conditions</h1>
              </div>
              <div className="inner-content">
                <div dangerouslySetInnerHTML={{ __html: des }} />
              </div>
            </div>
          </section>
        )}
      </Layout>
    </>
  );
}

export default AgentTerms;
