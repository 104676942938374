import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Layout from "../Layout/Layout";
import "../../Styles/mainstyle/select2.min.css";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import loaderImg from "../../assets/images/loader.svg";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Maps from "../Sections/LocationCoponents/Maps";
import swal from 'sweetalert';



function Addlisting() {
  const [showSellform, setShowSellform] = useState(false);
  const [form, setform] = useState(3);
  const [town, settown] = useState();
  const [pool, setpool] = useState([]);
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [tags, setTags] = useState([]);
  const [outside, setoutside] = useState([]);
  const [verifyToken, setverifyToken] = useState();
  const [nearLocation, setnearLocation] = useState([]);
  const [multiTown, setmultiTown] = useState([]);
  const userinfo = JSON.parse(localStorage.getItem("user"));
  const user = JSON.parse(localStorage.getItem("data"));
  const token = JSON.parse(localStorage.getItem("token"));
  const [imageArray, setimageArray] = useState([]);
  const [Ref, setRef] = useState("");
  const [price2, setprice] = useState("");
  const [postcodePin, setpostcode] = useState("");
  const [propertyType, setProperty] = useState([]);
  const [bedsNum, setbedsNum] = useState("");
  const [bathsNum, setbathsNum] = useState("");
  const [buildSize, setbuildSize] = useState("");
  const [summaryDes, setsummary] = useState("");
  const [plotSize, setplotsize] = useState("");
  const [OptionalDesc, setOptionalDesc] = useState("");
  const [type, setType] = useState("");
  const [subproperty, setsubproperty] = useState("");
  const [serverError, setserverError] = useState("");
  console.log(Ref, price2, postcodePin, plotSize, bathsNum, bedsNum, buildSize);
  const [succes, setsucces] = useState("");
  const navigate = useNavigate();
  var ima = [];
  const [numbererror, setNumberError] = useState("");
  const [numbererror1, setNumberError1] = useState("");
  const [numbererror2, setNumberError2] = useState("");

  //alert(isNaN(bedsNum))

  const [selectedimages, setselectedimages] = useState([]);

  const multiplefileChange = (e) => {
    setimageArray(e.target.files);

    const selectedfiles = e.target.files;
    const selectedfilesArray = Array.from(selectedfiles);

    const imageArray = selectedfilesArray.map((file) => {
      return file;
    });

    setselectedimages((prevImage) => prevImage.concat(imageArray));
  };

  const deleteHandleImage = (image) => {
    setselectedimages(selectedimages.filter((e) => e !== image));
  };
  console.log(imageArray.length, imageArray, selectedimages);

  // for(let i =0 ;i < imageArray.length ;i++){
  //   setimagefiles(imageArray[i])
  //   }
  const io = {};

  // imageArray?.map((img)=>io.push(img) )
  //  console.log(io , "ddsds");

  // const towndata = town.map((item ,i)=> {
  //   return settown2({ id :item.id , cout: item.town})
  // })
  // console.log(town , "map==" ,towndata ,town2);

  const handleSell = () => {
    setform(1)
    setShowSellform(true);
    setType("sale");
  };

  const handleCommercial = () => {
    setShowSellform(true);
    setType("Commercial");
  };

  const handleRent = () => {
    setShowSellform(true);
    setType("month");
    setform(1)

  };

  const handlePrev = () => {
    if (form == 1) {
      setShowSellform(false);
      window.scrollTo(0, 0);
    } else if (form == 2) {
      setform(1);
    } else if (form == 3) {
      setform(1);
    } else if (form == 3) {
      setform(1);
    }
  };
  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/town-list`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        console.log("townlist==", res);
        if (res.status == 200) {
          settown(res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  var countries = [];
  const poolarray = [];
  const nearLocationarray = [];
  const outsidearray = [];
  const tagsarray = [];
  const propertytyearray = [];

  multiTown?.map((item) => countries.push(item.town.trim()));

  tags?.map((item) => tagsarray.push(item.Country.trim()));
  nearLocation?.map((item) => nearLocationarray.push(item.Country.trim()));
  pool?.map((item) => poolarray.push(item.Country.trim()));
  outside?.map((item) => outsidearray.push(item.Country.trim()));
  var image23 = Array.from(imageArray).map((item) => item);
  propertyType?.map((item) => propertytyearray.push(item.Country.trim()));

  console.log("item.Country", countries, JSON.stringify(propertytyearray));

  const submitForm = (e) => {
    //console.log(e.target);
    e.preventDefault();
    // console.log( e.target.imagefile.files);
    // for()
    setloading2(true);

    var FormData = require("form-data");
    var data = new FormData();
    data.append("ref", Ref);
    data.append("price", price2);
    data.append("price_freq", type);
    data.append("property_type", JSON.stringify(propertytyearray));
    data.append("town", JSON.stringify(countries));
    data.append("postcode", postcodePin);
    data.append("beds", bedsNum);
    data.append("baths", bathsNum);
    data.append("built", buildSize);
    data.append("plot", plotSize);
    data.append("pool", JSON.stringify(poolarray));
    data.append("outside_space", JSON.stringify(outsidearray));
    data.append("near_by", JSON.stringify(nearLocationarray));
    data.append("amenities", JSON.stringify(tagsarray));
    data.append("subtype_property", subproperty);
    data.append("summary", summaryDes);
    data.append("feat_desc", OptionalDesc);
    // data.append('image[]', imageArray , imageArray.name);
    // for (let i = 0; i < tagsarray.length; i++) {
    //   data.append("amenities[]", tagsarray[i]);
    // }

    for (let i = 0; i < selectedimages.length; i++) {
      data.append("image[]", selectedimages[i]);
    }

    if (form == 4) {
      axios
        .request({
          method: "post",
          url: baseUrl + "/agent/add-property",
          data: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log(response.data.message);
            setsucces(response.data.message);
            setloading2(false);
            // e.reset();
            //data.reset();
            swal({
              title: "Bella Islas says",
              text: response.data.message,
              icon: "success",
              button: "OK",
              // timer: 3000,
            });
            setTimeout(() => {
              setsucces("");
              navigate("/agent-dashboard");
            }, 3000);
            // navigate("/agent-dashboard")
            // setCurrentPass(response.data.message)
          } else {
            // setCurrentPass("")
          }

          console.log(response.status);

          // e.target.reset();
        })
        .catch((error) => {
          console.log(error.message);
          if (error) {
            setloading2(false);

            setserverError(error.message);
            //setsucces(error.message)
          } else {
            //  setErrorMessage("")
          }
        });
    }
  };

  useEffect(() => {
    if (userinfo) {
      //setUserType(userinfo.user_type)
      if (userinfo.user_type == "Agent") {
        // setUsername(userinfo.username)
      } else if (userinfo.user_type == "User") {
        // setUsername(userinfo.name)
      }
    }

    if (token) {
      setverifyToken(token);
    }
  }, []);
  const { id } = useParams();
  const [editdata, seteditdata] = useState();
  console.log(id);
  const editdetailsFetch = async () => {
    await axios
      .get(baseUrl + `/agent/edit-property?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          seteditdata(res.data);
        }
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchtownList();
    editdetailsFetch();
    setTimeout(() => {
      setErrorMessage("");
    }, 8000);
  }, [errorMessage]);

  const invalue = useRef(null);

  const data = [
    { Country: "Alaro", id: 1 },
    { Country: "Selectb", id: 2 },
    { Country: "SelectC", id: 3 },
    { Country: "SelectD", id: 4 },
    { Country: "SelectE", id: 5 },
  ];
  const data2 = [
    { Country: "Apartment", id: 1 },
    { Country: "Villa", id: 2 },
    { Country: "House", id: 3 },
    { Country: "Land", id: 4 },
    { Country: "Penthouse", id: 5 },
    { Country: "Country House", id: 6 },
    { Country: "Ground Flat", id: 7 },
    { Country: "Town House", id: 8 },
    { Country: "Finca", id: 9 },
    { Country: "Garage", id: 10 },
  ];
  const data3 = [
    { Country: "Office", id: 1 },
    { Country: "Building", id: 2 },
    { Country: "Industrial", id: 3 },
    { Country: "Commercial", id: 4 },
    { Country: "Other Business", id: 5 },

  ];

  const locationDetails = [
    { Country: "Near Golf", id: 1 },
    { Country: "Near Beach", id: 2 },
    { Country: "Near Shops", id: 3 },
    { Country: "Near Transport", id: 4 },
  ];
  const poolDetails = [
    { Country: "Communal", id: 1 },
    { Country: "Private", id: 2 },
    { Country: "Pool", id: 3 },
  ];

  const outsideDetails = [
    { Country: "Communal Garden", id: 1 },
    { Country: "Private Garden", id: 2 },
    { Country: "Terrace", id: 3 },
  ];

  const tagsDetails = [
    { Country: "Air Conditioning", id: 1 },
    { Country: "Beach", id: 2 },
    { Country: "Sea View", id: 3 },
    { Country: "Lift", id: 4 },
    { Country: "Garage", id: 5 },
    { Country: "Parking", id: 6 },
    { Country: "Swimming Pool", id: 7 },
    { Country: "Disabled Access", id: 7 },
  ];

  const handleSubProperty = (e) => {
    setsubproperty(e.target.value);
  };
  console.log("ddd", subproperty);

  const handleValue = (e) => {
    // console.log(e);
    console.log(e);
    setmultiTown("click", e);
  };
  const handleNextform = () => {
    if (
      form == 1
        ? Ref != "" &&
        price2 != "" &&
        postcodePin !== "" &&
        bathsNum !== "" &&
        bedsNum !== "" &&
        buildSize !== "" &&
        propertyType[0] &&
        multiTown[0] &&
        summaryDes !== ""
        : form > 0
    ) {
      setform(form + 1);
      window.scrollTo(0, 0);
    } else {
      setErrorMessage("Fill All the Fields !");
    }
    console.log("clicked");
  };
  // const [error2, seterror2] = useState({
  //   Reference: "",
  //   Postcode: "",
  //   Multiselect: "",
  //   Price: "",
  //   bedrooms: "",
  //   bathrooms: "",
  //   Build: "",
  //   Property_Type: "",
  //   Description: ""
  // });
  // const logins_field = (name) => {
  //   switch (name) {
  //     case 'Reference':
  //       errorfield.about =
  //         Ref == ''
  //           ? "required"
  //           : "";
  //       break;
  //     case 'Postcode':
  //       errorfield.about =
  //         postcodePin == ''
  //           ? "required"
  //           : "";
  //       break;
  //     case 'Multiselect':
  //       errorfield.about =
  //         !multiTown[0]
  //           ? "required"
  //           : "";
  //       break;
  //     case 'Price':
  //       errorfield.about =
  //         price2 == ''
  //           ? "required"
  //           : "";
  //       break;
  //     case 'bedrooms':
  //       errorfield.about =
  //         bedsNum == ''
  //           ? "required"
  //           : "";
  //       break;
  //     case 'bathrooms':
  //       errorfield.about =
  //         bathsNum == ''
  //           ? "required"
  //           : "";
  //       break;
  //     case 'Build':
  //       errorfield.about =
  //         buildSize == ''
  //           ? "required"
  //           : "";
  //       break;
  //     case 'Property_Type':
  //       errorfield.about =
  //         !propertyType[0]
  //           ? "required"
  //           : "";
  //       break;
  //     case 'Description':
  //       errorfield.about =
  //         summaryDes == ''
  //           ? "required"
  //           : "";
  //       break;
  //     default:
  //       break;
  //   }
  //   // setform_logins({ ...form_logins, [name]: value })
  //   seterror2(error2)
  // }
  // const checkfields = [
  //   { name: "Reference" },
  //   { name: "Postcode" },
  //   { name: "Multiselect" },
  //   { name: "Price" },
  //   { name: "bedrooms" },
  //   { name: "bathrooms" },
  //   { name: "Build" },
  //   { name: "Property_Type" },
  //   { name: "Description" }
  // ]

  const onDragStart = (e, index) => {
    console.log("onDragStart", e, index);
  };

  // const onDragEnter =(e: React.DragEvent<HTMLDivElement> ,index: number)=>{

  // }

  // const onDragOver =(e:React.DragEvent <HTMLDivElement> ,index:number)=>{

  // }

  return (
    <>
      <Layout>
        {/* <!-- add listing type section start here --> */}
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loaderImg} alt="loading..." />{" "}
          </div>
        ) : (
          <section className="add_listing">
            {showSellform !== true ? (
              <div className="choose-listing">
                <div className="container-sm">
                  <div className="wrap flex">
                    <h3>Choose listing type</h3>
                    <div className="col_" data-id="forSale">
                      <div className="face front">
                        <div className="content">
                          <div className="icon" onClick={() => handleSell()}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="29"
                              height="26"
                              viewBox="0 0 576 512"
                            >
                              <path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z" />
                            </svg>
                          </div>
                          <p>For Sale</p>
                        </div>
                      </div>
                      <div className="face back" onClick={() => handleSell()}>
                        <h6>Choose type</h6>
                      </div>
                    </div>
                    <div className="col_" data-id="toRent">
                      <div className="face front">
                        <div className="content">
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="26"
                              viewBox="0 0 448 512"
                            >
                              <path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" />
                            </svg>
                          </div>
                          <p>To Rent</p>
                        </div>
                      </div>
                      <div className="face back" onClick={() => handleRent()}>
                        <h6>Choose type</h6>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            ) :
              (
                <div className="multisteps">
                  <div className="container-sm">
                    <div
                      className="multistep-form"
                      id="forSale"
                      style={{ display: "block" }}
                    >
                      <h2>Add Property Details</h2>
                      <form
                        action=""
                        onSubmit={(e) => {
                          if (selectedimages[0]) {
                            submitForm(e);
                          } else {
                            setErrorMessage("Fill All the Fields !");
                          }
                        }}
                      >
                        <div style={{ textAlign: "center", fontSize: "16px" }}>
                          {succes}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "15px",
                            color: "red",
                          }}
                        >
                          {errorMessage ? errorMessage : ""}
                          {serverError ? serverError : ""}
                        </div>
                        <div className="steps ">
                          <ul className="tablist">
                            <li
                              data-id="stepsOnes"
                              className={`${form == "1" ? "active" : ""}`}
                              onClick={() => {
                                if (
                                  (Ref != "" &&
                                    price2 != "" &&
                                    postcodePin != "" &&
                                    bathsNum != "" &&
                                    bedsNum != "" &&
                                    buildSize != "" &&
                                    propertyType[0] &&
                                    multiTown[0] &&
                                    summaryDes != "") ||
                                  form > 1
                                ) {
                                  setform(1);
                                } else {
                                  setErrorMessage("Fill All the Fields !");
                                }
                              }}
                            >
                              <span className="number ">1</span> Basic Information
                            </li>
                            <li
                              data-id="stepsTwos"
                              className={`${form == "2" ? "active" : ""}`}
                              onClick={() => {
                                if (
                                  (Ref != "" &&
                                    price2 != "" &&
                                    postcodePin != "" &&
                                    bathsNum != "" &&
                                    bedsNum != "" &&
                                    buildSize != "" &&
                                    propertyType[0] &&
                                    multiTown[0] &&
                                    summaryDes != "") ||
                                  form > 2
                                ) {
                                  setform(2);
                                } else {
                                  setErrorMessage("Fill All the Fields !");
                                }
                              }}
                            >
                              <span className="number">2</span> Additional
                              Property Details
                            </li>
                            <li
                              data-id="stepsThrees"
                              className={`${form == "3" ? "active" : ""}`}
                              onClick={() => {
                                if (
                                  (Ref != "" &&
                                    price2 != "" &&
                                    postcodePin != "" &&
                                    bathsNum != "" &&
                                    bedsNum != "" &&
                                    buildSize != "" &&
                                    propertyType[0] &&
                                    multiTown[0] &&
                                    summaryDes != "") ||
                                  form > 3
                                ) {
                                  setform(3);
                                } else {
                                  setErrorMessage("Fill All the Fields !");
                                }
                              }}
                            >
                              <span className="number">3</span> Property Location
                            </li>
                            <li
                              data-id="stepsFours"
                              className={`${form == "4" ? "active" : ""}`}
                              onClick={() => {
                                if (
                                  Ref != "" &&
                                  price2 != "" &&
                                  postcodePin != "" &&
                                  bathsNum != "" &&
                                  bedsNum != "" &&
                                  buildSize != "" &&
                                  propertyType[0] &&
                                  multiTown[0] &&
                                  summaryDes != ""
                                ) {
                                  setform(4);
                                } else {
                                  setErrorMessage("Fill All the Fields !");
                                }
                              }}
                            >
                              <span className="number">4</span> Images &amp; Video
                            </li>
                          </ul>
                        </div>
                        {form == "1" ? (
                          <div
                            class="formWrappers active"
                            style={{ display: "block" }}
                            id="stepsOnes"
                          >
                            <div class="input-wrap flex">
                              <label for="job_title">Property Address</label>
                              <div class="form_group half">
                                <input
                                  type="text"
                                  // id="ref"
                                  value={Ref}
                                  onChange={(e) => setRef(e.target.value)}
                                  placeholder="Reference"
                                  name="Reference"
                                />
                              </div>
                              <div class="form_group half pdrt0">
                                <input
                                  type="text"
                                  // id="postcode"
                                  value={postcodePin}

                                  onChange={(e) => {
                                    if (isNaN(e.target.value)) {
                                      setNumberError1(
                                        "Enter Number "
                                      );
                                    } else {
                                      setNumberError1("");
                                      setpostcode(e.target.value)
                                    }
                                  }}
                                  placeholder="Postcode"
                                  name="Postcode"
                                />
                                <span>{numbererror}</span>
                              </div>
                              <div class="form_group">
                                {/* <input type="text" placeholder="Town, village or city" />  */}

                                <Multiselect
                                  className="multi-select"
                                  style={{ color: "black" }}
                                  placeholder="Town, village or city"
                                  // defaultValue="Town, village or city"
                                  displayValue="town"
                                  // isMulti ={false}
                                  //id="townSelect"
                                  selectedValues={multiTown}
                                  value={multiTown}
                                  onSelect={(e) => setmultiTown([...e])}
                                  options={town}
                                  closeOnSelect={true}
                                />
                              </div>
                            </div>
                            <div class="input-wrap flex">
                              <label for="job_title">
                                BASIC PROPERTY INFORMATION
                              </label>
                              <div class="form_group">
                                <input
                                  type="text"
                                  //id="price"
                                  value={price2}
                                  onChange={(e) => {
                                    if (isNaN(e.target.value)) {
                                      setNumberError1(
                                        "Enter Number "
                                      );
                                    } else {
                                      setNumberError1("");
                                      setprice(e.target.value);
                                    }
                                  }}
                                  placeholder="Price"
                                />
                                <span>{numbererror1}</span>
                              </div>
                              <div class="form_group half">
                                <input
                                  type="text"
                                  value={bedsNum}
                                  //id="beds"
                                  onChange={(e) => {
                                    if (isNaN(e.target.value)) {
                                      setNumberError2(
                                        "Enter Number "
                                      );
                                    } else {
                                      setNumberError2("");
                                      setbedsNum(e.target.value);
                                    }
                                  }}
                                  placeholder="Number of bedrooms"
                                />
                                <span>{numbererror2}</span>
                              </div>
                              <div class="form_group half pdrt0">
                                <input
                                  type="text"
                                  value={bathsNum}
                                  onChange={(e) => {
                                    if (isNaN(e.target.value)) {
                                      setNumberError(
                                        "Enter Number "
                                      );
                                    } else {
                                      setNumberError("");
                                      setbathsNum(e.target.value);
                                    }
                                  }}
                                  placeholder="Number of bathrooms"
                                />
                              </div>
                              <div class="form_group half">
                                <input
                                  value={buildSize}
                                  type="text"
                                  //id="buildSize"
                                  onChange={(e) => setbuildSize(e.target.value)}
                                  placeholder="Build size in m2"
                                />
                              </div>
                              <div class="form_group">
                                {/* <input
                                type="text"
                                //id="propertyType"
                                onChange={(e) => setProperty(e.target.value)}
                                placeholder="Property Type"
                              /> */}
                                <Multiselect
                                  className="multi-select"
                                  placeholder="Property Type"
                                  defaultValue="Select"
                                  displayValue="Country"
                                  selectedValues={propertyType}
                                  onSelect={(e) => setProperty([...e])}
                                  // onChange={handleOnchange}
                                  options={data2}
                                  closeOnSelect={true}
                                />
                              </div>
                            </div>
                            <div class="optional">
                              <label for="optional">(optional)</label>
                              <div class="field " style={{ paddingTop: "1rem" }}>
                                <div class="md-checkbox">
                                  <input
                                    type="radio"
                                    name="subtype-property[]"
                                    id="cbopt-3odoaL3"
                                    value="New Build"
                                    onChange={(e) => handleSubProperty(e)}
                                    autocomplete="chrome-off"
                                  />
                                  <label for="cbopt-3odoaL3">New Build </label>
                                </div>
                                <div class="md-checkbox">
                                  <input
                                    type="radio"
                                    name="subtype-property[]"
                                    id="cbopt-RU93lgT"
                                    value="Exclusive"
                                    onChange={(e) => handleSubProperty(e)}
                                    autocomplete="chrome-off"
                                  />
                                  <label for="cbopt-RU93lgT">Exclusive </label>
                                </div>
                                <div class="md-checkbox">
                                  <input
                                    type="radio"
                                    name="subtype-property[]"
                                    id="cbopt-dMlX2XD"
                                    onChange={(e) => handleSubProperty(e)}
                                    value="Tourist License"
                                    autocomplete="chrome-off"
                                  />
                                  <label for="cbopt-dMlX2XD">
                                    Tourist License{" "}
                                  </label>
                                </div>
                                <div class="md-checkbox">
                                  <input
                                    type="radio"
                                    onChange={(e) => handleSubProperty(e)}
                                    name="subtype-property[]"
                                    id="cbopt-gC1ndR6"
                                    value="New Project"
                                    autocomplete="chrome-off"
                                  />
                                  <label for="cbopt-gC1ndR6">New Project </label>
                                </div>
                              </div>
                            </div>
                            <div class="input-wrap flex">
                              <label for="job_title">Property Description</label>
                              <div class="form_group">
                                <textarea
                                  name="summary"
                                  value={summaryDes}
                                  placeholder="Description"
                                  // id="summary"
                                  onChange={(e) => setsummary(e.target.value)}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        ) : form == "2" ? (
                          <div
                            className="formWrappers active"
                            style={{ display: "block" }}
                            id="stepsTwos"
                          >
                            <div
                              className="input-wrap flex"
                              style={{ marginBottom: "20px" }}
                            >
                              <label for="job_title">
                                Property Details <span>(optional)</span>
                              </label>
                              <div className="form_group">
                                <input
                                autoComplete={false}
                                  type="text"
                                  onChange={(e) => setplotsize(e.target.value)}
                                  placeholder="Plot size in m2"
                                />
                              </div>
                            </div>
                            <div className="input-wrap flex">
                              <label for="job_title">
                                Features <span>(optional)</span>
                              </label>
                              <div className="form_group">
                                {/* <input type="text" placeholder="Location" /> */}
                                <Multiselect
                                  className="multi-select"
                                  placeholder="Location"
                                  defaultValue="Select"
                                  displayValue="Country"
                                  onSelect={(e) => setnearLocation([...e])}
                                  // onChange={handleOnchange}
                                  options={locationDetails}
                                />
                              </div>
                              <div className="form_group">
                                <span>(optional)</span>

                                <Multiselect
                                  className="multi-select"
                                  placeholder="Pool"
                                  defaultValue="Town"
                                  displayValue="Country"
                                  onSelect={(e) => setpool([...e])}
                                  // onChange={handleOnchange}
                                  options={poolDetails}
                                />
                                {/* <input type="text" placeholder="Pool" /> */}
                              </div>
                              <div className="form_group">
                                <span>(optional)</span>

                                {/* <input type="text" placeholder="Outside Space" /> */}

                                <Multiselect
                                  className="multi-select"
                                  placeholder="Outside Space"
                                  defaultValue="Town"
                                  displayValue="Country"
                                  onSelect={(e) => setoutside([...e])}
                                  // onChange={handleOnchange}
                                  options={outsideDetails}
                                />
                              </div>
                              <div className="form_group">
                                <span>(optional)</span>

                                <Multiselect
                                  className="multi-select"
                                  placeholder="Tags"
                                  defaultValue="Town"
                                  displayValue="Country"
                                  onSelect={(e) => setTags([...e])}
                                  // onChange={handleOnchange}
                                  options={tagsDetails}
                                />
                                {/* <input type="text" placeholder="Tags" /> */}
                              </div>
                            </div>
                            <div className="input-wrap flex">
                              <label for="job_title">
                                Features <span>(optional)</span>
                              </label>
                              <div className="form_group">
                                <textarea
                                  name="summary"
                                  placeholder="Description"
                                  onChange={(e) =>
                                    setOptionalDesc(e.target.value)
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        ) : form == "3" ? (
                          <div
                            className="formWrappers active"
                            style={{ display: "block" }}
                            id="stepsThrees"
                          >
                            <div
                              className="input-wrap flex"
                              style={{ marginBottom: "20px" }}
                            >
                              <label for="job_title">SET MAP LOCATION</label>
                            
                              <div className="form_group">

                                <div className="map_setpostion" >

                                  <Maps />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : form == "4" ? (
                          <div
                            className="formWrappers active"
                            style={{ display: "block" }}
                            id="stepsFours"
                          >
                            <div className="input-wrap flex">
                              <label for="job_title">IMAGE GALLERY</label>
                              <div className="form_group">
                                <div className="upload__box">
                                  <div className="upload__btn-box">
                                    <label className="upload__btn">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 384 512"
                                      >
                                        <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" />
                                      </svg>
                                      <input
                                        type="file"
                                        multiple
                                        id="imagefile"
                                        onChange={(e) => multiplefileChange(e)}
                                        data-max_length="20"
                                        className="upload__inputfile"
                                      />
                                    </label>
                                    <p>
                                      Maximum file size: 20 MB. Up to 50 files
                                      allowed.
                                    </p>
                                  </div>
                                  <div className="upload__img-wrap">
                                    {Array.from(selectedimages).map(
                                      (item, index) => {
                                        return (
                                          <span
                                            draggable={true}
                                            key={index}
                                            onDragStart={(e) =>
                                              onDragStart(e, index)
                                            }
                                          >
                                            <img
                                              style={{
                                                width: "220px",
                                                margin: "0 2px",
                                                height: "115px",
                                              }}
                                              src={
                                                item
                                                  ? URL.createObjectURL(item)
                                                  : null
                                              }
                                            />
                                            <span
                                              className="delete_bt"
                                              onClick={() =>
                                                deleteHandleImage(item)
                                              }
                                            >
                                              x
                                            </span>
                                          </span>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {loading2 == true ? (
                              <div className="loading_div">
                                <div>
                                  <img src={loaderImg} />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="button-wrapper">
                          <button
                            type="button"
                            className="prev"
                            onClick={() => {
                              handlePrev();
                              window.scrollTo(0, 0);
                            }}
                          >
                            Previous
                          </button>
                          {form == "4" ? (
                            selectedimages[0] ? (
                              <button type="submit" className="save">
                                Submit
                              </button>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  className="save"
                                  onClick={(e) => {
                                    setErrorMessage("Select Image !");
                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  Submit
                                </button>
                              </>
                            )
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                window.scrollTo(0, 0);
                                handleNextform();
                              }}
                              className="save"
                            >
                              Save and Continue
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                    <div className="multistep-form" id="toRent"></div>
                  </div>
                </div>
              )


            }
          </section>
        )}
        {/* <!-- add listing type section end here --> */}
      </Layout>
    </>
  );
}

export default Addlisting;
