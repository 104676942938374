import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../baseUrl";
import swal from "sweetalert";

const emailRegex = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
function GetIntouch(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  console.log("error", errorMessage);
  const [CallAgentName, setCallAgentName] = useState("");
  const [CallAgentEmail, setCallAgentEmail] = useState("");
  const [CallAgentNumber, setCallAgentPhone] = useState("");

  const [error2, seterror2] = useState({
    username: "",
    number: "",
    resetemailadd: "",
  });
  const logins_field = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        error2.username = value.length > 4 ? "" : "Enter A Valid Name !";
        break;
      case "number":
        error2.number =
          value.length > 13 || isNaN(value)
            ? "Minimum 8 characters required"
            : "";
        break;
      case "email":
        error2.resetemailadd = emailRegex.test(value) ? "" : "Email not valid.";
        break;
      default:
        break;
    }
    if (name == "name") {
      setCallAgentName(value);
    }
    if (name == "number") {
      setCallAgentPhone(value);
    }
    if (name == "email") {
      setCallAgentEmail(value);
    }
    seterror2(error2);
  };

  const setshow = props.setfn;
  const handleSubmit = (event) => {
    event.preventDefault();
    const f_name = event.target.f_name.value;
    const l_name = event.target.l_name.value;
    const email = event.target.email.value;
    const number = event.target.number.value;
    const message = event.target.message.value;

    const data = { f_name, l_name, email, number, message };

    if (f_name !== "" && email !== "" && number !== "") {
      axios
        .post(baseUrl + "/contactus", data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            swal({
              title: "Bella Islas says",
              text: response.data.message,
              icon: "success",
              button: "OK",
              // timer: 3000,
            });
            if (f_name !== "") {
              // setshowPopup(false)
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
          swal({
            title: "Bella Islas says",
            text: error.message,
            icon: "error",
            button: "OK",
            // timer: 3000,
          });
        });
    } else {
      setErrorMessage("Please fill Name and Email !");
    }
  };

  return (
    <>
      <div className="getInTouch-popup active">
        <div className="wrapper">
          <div className="close" onClick={() => setshow(false)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <div className="head">
            <h3>
              Simply send us your details and one of our team will be in touch
            </h3>
          </div>
          <div className="content">
            <form action="#" onSubmit={handleSubmit}>
              <div className="form_group half">
                <input
                  type="text"
                  placeholder="First Name"
                  id="f_name"
                  onChange={(e) => logins_field(e)}
                  value={CallAgentName}
                  name="name"
                  style={
                    error2.username != ""
                      ? { borderBottom: "2px solid red" }
                      : {}
                  }
                />
                <div style={{ fontSize: "12px", color: "red" }}>
                  {error2.username}
                </div>
              </div>
              <div className="form_group half">
                <input type="text" placeholder="Last Name" id="l_name" />
              </div>
              <div className="form_group half">
                <input
                  type="text"
                  placeholder="Phone Number"
                  maxLength={13}
                  onChange={(e) => logins_field(e)}
                  value={CallAgentNumber}
                  name="number"
                  style={
                    error2.number != "" ? { borderBottom: "2px solid red" } : {}
                  }
                />
                 <div style={{ fontSize: "12px", color: "red" }}>
                  {error2.number}
                </div>
              </div>
              <div className="form_group half">
                <input
                  type="email"
                  placeholder="Email"
                  id="email"
                  onChange={(e) => logins_field(e)}
                  value={CallAgentEmail}
                  name="email"
                  style={
                    error2.resetemailadd != ""
                      ? { borderBottom: "2px solid red" }
                      : {}
                  }
                />
                 <div style={{ fontSize: "12px", color: "red" }}>
                  {error2.resetemailadd}
                </div>
              </div>
              <div className="form_group">
                <textarea
                  name=""
                  placeholder="Reason For Enquiry"
                  id="message"
                ></textarea>
              </div>
              <div className="form_group">
                <input
                  type="checkbox"
                  id="subscribe"
                  name="subscribe"
                  value="subscribe"
                />
                <label for="subscribe">
                  {" "}
                  Subscribe to the lastest insights
                </label>
                <div style={{ fontSize: "12px", color: "red" }}>
                  {errorMessage}
                </div>
                <div style={{ fontSize: "12px", color: "red" }}>
                  {errorMessageEmail}
                </div>
              </div>
              <div className="form_group">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetIntouch;
