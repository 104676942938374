import axios from "axios";
import React, { useRef } from "react";
import Layout from "../../Layout/Layout";
import { baseUrl } from "../../../baseUrl";
import { useEffect } from "react";
// import "../../../Styles/style4.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BuySection from "./BuySection";
import loaderImg from "../../../assets/images/loader.svg";

function Searchbar() {
  const [regionShow, setRegionShow] = useState(false);
  const [priceShow, setpriceShow] = useState(false);
  const [bedShow, setbedShow] = useState(false);
  const [propertyTypeShow, setpropertyTypeShow] = useState(false);
  const [filterShow, setfilterShow] = useState(false);
  const [dialogShow, setdialogShow] = useState(false);
  const [Showbookmark, setShowbookmark] = useState(false);
  const [details, setDetails] = useState([]);
  const login = JSON.parse(localStorage.getItem("login"));
  const [minprice, setMinprice] = useState("");
  const [maxprice, setMaxprice] = useState("");
  const [minbed, setminbed] = useState("");
  const [maxbed, setmaxbed] = useState("");
  const [town, settown] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [count, setcount] = useState(true);
  const [searchName, setsearchName] = useState("");
  const [loading, setloading] = useState(false);
  const { townid } = useParams();
  const [show, setShow] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [apartmant, setApartmant] = useState([]);
  const [MobileMenu, setMobileMenu] = useState(false);

  const [searchValue, setsearchValue] = useState(townid != null && townid != "" ? townid : "");

  const [params, setparams] = useState([]);
  const [town2, settown2] = useState();
  const [searchTown, setSearchTown] = useState("");
  const [alerts, setAlerts] = useState("");
  const page = [];
  const [forPagination, setForPagination] = useState([]);
  let totalPage = forPagination.length - 2;
  for (let i = 0; i < Math.ceil(totalPage); i++) {
    // if ((totalPage - 10) > -9) {
    //   totalPage = (totalPage - 10)
    //   page.push(i + 1)
    // }
    page.push(i + 1);
  }
  console.log(page, forPagination);

  let nf = new Intl.NumberFormat();

  const navigate = useNavigate();
  const islogin = JSON.parse(localStorage.getItem("login"));
  const [filterSearch, setfilterSearch] = useState([]);
  const [premiumDetails, setpremiumDetails] = useState([]);
  const [shorting, setshorting] = useState("");
  const [added_site, setadded_site] = useState("");
  const [subtype, setsubtype] = useState("");
  const [savesearcherror, setsavesearcherror] = useState("");

  const [searchfilterParams, setsearchfilterParams] = useState([]);
  console.log(details, ">..>>>>>>>");

  const url = "admin.bella.sdsstaging.co.uk/api/property?page=1";
  const handleSearch = () => {
    setdialogShow(!dialogShow);
  };

  // ${searchfilterParams.length !== 0 ?  `&amenities[]=${searchfilterParams.join("&amenities[]=")}`:""}
  const fetchproperty = async (elm) => {
    setDetails([]);
    setloading(false);
    setpremiumDetails([]);
    try {
      const res = await axios.get(
        baseUrl +
        `/property?page=${currentPage}&min_price=${minprice}&max_price=${maxprice}&min_beds=${minbed}&max_beds=${maxbed}&town=${elm != null && elm ? elm : townid ? townid : ""
        }& type=sale${shorting == "low"
          ? `&low=low`
          : shorting == "high"
            ? `&high=high`
            : shorting == "new"
              ? `&new=new`
              : shorting == "old"
                ? `&old=old`
                : ""
        }${params.length !== 0
          ? `&property_type[]=${params.join("&property_type[]=")}`
          : ""
        }${searchfilterParams.length !== 0
          ? `&amenities[]=${searchfilterParams.join("&amenities[]=")}`
          : ""
        }${added_site == "" ? "" : `&added_site=${added_site}`}${subtype == "" ? "" : `&subtype=${subtype}`
        }
    `,
        { headers: { Authorization: `No Auth` } }
      );
      const details = res.data;

      if (res.status == 200) {
        setloading(true);

        // setMinprice("")
      }
      console.log(details.data);
      setpremiumDetails(details.data);
      setDetails(details.data.xml.data);
      //setForPagination(details.data.xml.links);
      setForPagination(details.data.xml);
    } catch (error) {
      if (error) {
        setloading(true);
      }
      console.log(error);
    }
  };

  console.log(details, forPagination, "<<<<<<<<<<<<<<<,");
  const [data, setData] = React.useState([]);
  const Savedlength = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(baseUrl + "/saved/search-list", {
        headers: { Authorization: `Bearer ${token} ` },
      });
      const details = res.data.data.response;
      //console.log(details);
      setData(details);
    } catch (error) {
      console.log(error.message);
      // setError(error.message)
    }
  };

  const delateSearch = async (id) => {
    console.log(id);
    const token = JSON.parse(localStorage.getItem("token"));
    try {
      const res = await axios.get(baseUrl + `/ delete /search-list?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const details = res.data;
      // console.log("delate", details);
      if (details.success == true) {
        const token = JSON.parse(localStorage.getItem("token"));

        const res = await axios.get(baseUrl + "/saved/search-list", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const details = res.data.data.response;
        console.log(details);
        setData(details);
      }
    } catch (error) {
      console.log(error.message);
      // setError(error.message)
    }
  };

  const saveSearch = (event) => {
    //event.preventDefault();
    const min_price = minprice;
    const max_price = maxprice;
    const min_beds = minbed;
    const max_beds = maxbed;
    // const longitude = "36.778"
    const saved_name = searchName;
    const alert = alerts;
    const token = JSON.parse(localStorage.getItem("token"));
    if (minprice !== "" && maxprice !== "" && minbed !== "" && maxbed !== "" && searchName !== "" && searchValue != "") {
      // const latitude = "34.765"
      setsavesearcherror("")
      const data = {
        min_price,
        max_price,
        min_beds,
        max_beds,
        town,
        saved_name,
        alert,
      };
      axios
        .post(baseUrl + "/saved-search", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("resp>>>>", response);
          if (response.status === 200) {
            const token = JSON.parse(localStorage.getItem("token"));
            setdialogShow(false);
            try {
              const res = axios.get(baseUrl + "/saved/search-list", {
                headers: { Authorization: `Bearer ${token}` },
              });
              const details = res.data.data.response;
              setData(details);
            } catch (error) {
              console.log(error.message);
              // setError(error.message)
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setsavesearcherror("All fields required")
    }
  };


  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/town-list`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        console.log("townlist==", res);
        if (res.status == 200) {
          settown2(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const hidesearchboxhandle = (elm) => {
    setsearchValue(elm);
    setShow(false);
    fetchproperty(elm)
  };

  const handleCheckProperty = (e) => {
    const value = e.target.value;
    const check = e.target.checked;

    if (check) {
      setApartmant([...apartmant, value]);
    } else {
      setApartmant(apartmant.filter((vlu) => vlu !== value));
    }
  };

  const handlefilterProperty = (e) => {
    const value = e.target.value;
    const check = e.target.checked;

    if (check) {
      setfilterSearch([...filterSearch, value]);
    } else {
      setfilterSearch(filterSearch.filter((vlu) => vlu !== value));
    }
  };

  const propertyTypeSubmit = () => {
    setparams(apartmant);
    setpropertyTypeShow(false);
    fetchtownList();
  };

  const onChangeS = (e) => {
    setShow(true);
    setsearchValue(e.target.value);
  };

  const searchfilterHandle = () => {
    setsearchfilterParams(filterSearch);
    setfilterShow(false);
  };

  React.useEffect(() => {
    fetchtownList();
  }, []);

  useEffect(() => {
    Savedlength();
    fetchproperty();

    // if (count) {
    //   fetchproperty();
    //   setcount(false);
    // }
  }, [
    currentPage,
    minprice,
    maxprice,
    minbed,
    maxbed,
    params,
    searchfilterParams,
    shorting,
    subtype,
    added_site,
  ]);

  const [commemSet, setcommenState] = useState(false);

  console.log();
  useEffect(() => {
    document.addEventListener("click", () => {
      setShow(false);
    });
  }, []);

  const ref = useRef(null);
  const handleOutside = () => {
    // console.log(state);
    //document.getElementById("backGround_click").style.display = "none"
    setMobileMenu(false);
    setShow(false);
    setdialogShow(false);
    setpriceShow(false);
    setbedShow(false);
    setfilterShow(false);
    setpropertyTypeShow(false);
  };

  const [shortingold, setshortingold] = useState("");
  const [shortinghigh, setshortinghigh] = useState("");
  const [shortingnew, setshortingnew] = useState("");
  //const [currentPage, setCurrentPage] = useState(1);


  const [itemsPerPage, setitemsPerPage] = useState(20);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];
  for (let i = 1; i <= Math.ceil(forPagination?.last_page); i++) {
    pages.push(i);
  }
  console.log(pages);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  console.log(apartmant.filter((vlu) => vlu == "Apartment"));

  return (
    <>
      {/*     <!-- fillter section start here --> */}
      <section className="fillter-section">
        <div
          className="backGround_click"
          onClick={handleOutside}
          id="backGround_click"
        ></div>
        <div className="container_">
          <form action="" className={MobileMenu == true ? "active" :""} >
            <div className="form-group">
              <div className="select2 search_select">
                <form
                  action=""
                  onSubmit={(e) => handleSearch(e)}
                  className="flex formUnderForm flex-width m-0"
                  style={{ margin: "0px" }}
                >
                  <div className="form_group">
                    <input
                      type="text"
                      value={searchValue}
                      onClick={(e) => {
                        setTimeout(() => {
                          onChangeS(e);
                        }, 50);
                        setsearchValue(" ");
                        setTimeout(() => {
                          setsearchValue("");
                        }, 60);
                      }}
                      onChange={onChangeS}
                      id="town"
                      className="form-control search_input_width select2-input searchSelect"
                      placeholder="Property Listing"
                    />
                    {searchValue == "" ? (
                      ""
                    ) : (
                      <span
                        className="clear_btn4"
                        onClick={() => setsearchValue("")}
                      >
                        x
                      </span>
                    )}
                  </div>
                </form>

                <div className={`${show == true ? "search_country" : ""}`} style={{ position: "absolute", left: "0", width: "100%", zIndex: "1" }}>
                  {show === true
                    ? town2
                      ?.filter((value) => {
                        if (searchValue === "") {
                          return value;
                        } else if (
                          value.town
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        ) {
                          return value;
                        }
                      })
                      .map((item) => (
                        <p
                          key={item.id}
                          onClick={() => {
                            hidesearchboxhandle(item.town)
                          }}
                        >
                          {item.town}
                        </p>
                      ))
                    : ""}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="pricesearch">
                <div
                  className="price-btn"
                  onClick={() => {
                    setpriceShow(!priceShow);
                    setbedShow(false);
                    setfilterShow(false);
                    setpropertyTypeShow(false);
                  }}
                >
                  <span className="price-btn-text" id="price-btn-text">
                    {minprice !== ""
                      ? "€" +
                      minprice.substring(0, 2) +
                      "K" +
                      "-" +
                      "€" +
                      maxprice.substring(0, 3) +
                      "K"
                      : "Price"}{" "}
                  </span>
                </div>
                <div className="min-max-price">
                  {priceShow && priceShow == true ? (
                    <div className="innerprice active">
                      <div className="minprice">
                        <select
                          name="minPrice"
                          className="select js-example-basic-single2 pricefilterss select2-hidden-accessible"
                          id="minPrice"
                          title="Min Price"
                          value={minprice}
                          onChange={(e) => setMinprice(e.target.value)}
                        >
                          <option
                            value=""
                            data-select2-id="select2-data-5-owbi"
                          >
                            Min Price
                          </option>
                          <option
                            value="0"
                            data-select2-id="select2-data-18-u92m"
                          >
                            Request Price
                          </option>
                          <option
                            value="50000"
                            data-select2-id="select2-data-19-8vy1"
                          >
                            €50,000
                          </option>
                          <option
                            value="60000"
                            data-select2-id="select2-data-20-5tjj"
                          >
                            €60,000
                          </option>
                          <option
                            value="70000"
                            data-select2-id="select2-data-21-vqd0"
                          >
                            €70,000
                          </option>
                          <option
                            value="80000"
                            data-select2-id="select2-data-22-l2va"
                          >
                            €80,000
                          </option>
                          <option
                            value="90000"
                            data-select2-id="select2-data-23-wmkd"
                          >
                            €90,000
                          </option>
                          <option
                            value="100000"
                            data-select2-id="select2-data-24-9ztz"
                          >
                            €100,000
                          </option>
                          <option
                            value="110000"
                            data-select2-id="select2-data-25-ymin"
                          >
                            €110,000
                          </option>
                          <option
                            value="120000"
                            data-select2-id="select2-data-26-mei3"
                          >
                            €120,000
                          </option>
                          <option
                            value="125000"
                            data-select2-id="select2-data-27-b3r0"
                          >
                            €125,000
                          </option>
                          <option
                            value="130000"
                            data-select2-id="select2-data-28-zsvq"
                          >
                            €130,000
                          </option>
                          <option
                            value="140000"
                            data-select2-id="select2-data-29-m5qn"
                          >
                            €140,000
                          </option>
                          <option
                            value="150000"
                            data-select2-id="select2-data-30-faor"
                          >
                            €150,000
                          </option>
                          <option
                            value="160000"
                            data-select2-id="select2-data-31-xpw0"
                          >
                            €160,000
                          </option>
                          <option
                            value="170000"
                            data-select2-id="select2-data-32-cr5h"
                          >
                            €170,000
                          </option>
                          <option
                            value="175000"
                            data-select2-id="select2-data-33-l1dk"
                          >
                            €175,000
                          </option>
                          <option
                            value="180000"
                            data-select2-id="select2-data-34-77b4"
                          >
                            €180,000
                          </option>
                          <option
                            value="190000"
                            data-select2-id="select2-data-35-fgiv"
                          >
                            €190,000
                          </option>
                          <option
                            value="200000"
                            data-select2-id="select2-data-36-tr7k"
                          >
                            €200,000
                          </option>
                          <option
                            value="210000"
                            data-select2-id="select2-data-37-bnmy"
                          >
                            €210,000
                          </option>
                          <option
                            value="220000"
                            data-select2-id="select2-data-38-6pt3"
                          >
                            €220,000
                          </option>
                          <option
                            value="230000"
                            data-select2-id="select2-data-39-gngi"
                          >
                            €230,000
                          </option>
                          <option
                            value="240000"
                            data-select2-id="select2-data-40-d8ej"
                          >
                            €240,000
                          </option>
                          <option
                            value="250000"
                            data-select2-id="select2-data-41-mysy"
                          >
                            €250,000
                          </option>
                          <option
                            value="260000"
                            data-select2-id="select2-data-42-qe4j"
                          >
                            €260,000
                          </option>
                          <option
                            value="270000"
                            data-select2-id="select2-data-43-1dgk"
                          >
                            €270,000
                          </option>
                          <option
                            value="280000"
                            data-select2-id="select2-data-44-4mtd"
                          >
                            €280,000
                          </option>
                          <option
                            value="290000"
                            data-select2-id="select2-data-45-2s0r"
                          >
                            €290,000
                          </option>
                          <option
                            value="300000"
                            data-select2-id="select2-data-46-w5jj"
                          >
                            €300,000
                          </option>
                          <option
                            value="325000"
                            data-select2-id="select2-data-47-f5f6"
                          >
                            €325,000
                          </option>
                          <option
                            value="350000"
                            data-select2-id="select2-data-48-f91u"
                          >
                            €350,000
                          </option>
                          <option
                            value="375000"
                            data-select2-id="select2-data-49-z1vi"
                          >
                            €375,000
                          </option>
                          <option
                            value="400000"
                            data-select2-id="select2-data-50-wqwe"
                          >
                            €400,000
                          </option>
                          <option
                            value="425000"
                            data-select2-id="select2-data-51-p1b5"
                          >
                            €425,000
                          </option>
                          <option
                            value="450000"
                            data-select2-id="select2-data-52-hndq"
                          >
                            €450,000
                          </option>
                          <option
                            value="475000"
                            data-select2-id="select2-data-53-azwk"
                          >
                            €475,000
                          </option>
                          <option
                            value="500000"
                            data-select2-id="select2-data-54-loiu"
                          >
                            €500,000
                          </option>
                          <option
                            value="550000"
                            data-select2-id="select2-data-55-80v2"
                          >
                            €550,000
                          </option>
                          <option
                            value="600000"
                            data-select2-id="select2-data-56-myy5"
                          >
                            €600,000
                          </option>
                          <option
                            value="650000"
                            data-select2-id="select2-data-57-9ioh"
                          >
                            €650,000
                          </option>
                          <option
                            value="700000"
                            data-select2-id="select2-data-58-pf67"
                          >
                            €700,000
                          </option>
                          <option
                            value="800000"
                            data-select2-id="select2-data-59-x15t"
                          >
                            €800,000
                          </option>
                          <option
                            value="900000"
                            data-select2-id="select2-data-60-sw3l"
                          >
                            €900,000
                          </option>
                          <option
                            value="1000000"
                            data-select2-id="select2-data-61-939w"
                          >
                            €1,000,000
                          </option>
                          <option
                            value="1250000"
                            data-select2-id="select2-data-62-u14a"
                          >
                            €1,250,000
                          </option>
                          <option
                            value="1500000"
                            data-select2-id="select2-data-63-vn1z"
                          >
                            €1,500,000
                          </option>
                          <option
                            value="1750000"
                            data-select2-id="select2-data-64-faed"
                          >
                            €1,750,000
                          </option>
                          <option
                            value="2000000"
                            data-select2-id="select2-data-65-tktk"
                          >
                            €2,000,000
                          </option>
                          <option
                            value="2500000"
                            data-select2-id="select2-data-66-po0k"
                          >
                            €2,500,000
                          </option>
                          <option
                            value="3000000"
                            data-select2-id="select2-data-67-cix6"
                          >
                            €3,000,000
                          </option>
                          <option
                            value="4000000"
                            data-select2-id="select2-data-68-6xnx"
                          >
                            €4,000,000
                          </option>
                          <option
                            value="5000000"
                            data-select2-id="select2-data-69-xrdr"
                          >
                            €5,000,000
                          </option>
                          <option
                            value="7500000"
                            data-select2-id="select2-data-70-s7nm"
                          >
                            €7,500,000
                          </option>
                          <option
                            value="10000000"
                            data-select2-id="select2-data-71-2jm8"
                          >
                            €10,000,000
                          </option>
                          <option
                            value="15000000"
                            data-select2-id="select2-data-72-h2m1"
                          >
                            €15,000,000
                          </option>
                          <option
                            value="20000000"
                            data-select2-id="select2-data-73-4oao"
                          >
                            €20,000,000
                          </option>
                          <option
                            value=""
                            data-select2-id="select2-data-74-8s2a"
                          >
                            Min Price
                          </option>
                        </select>
                      </div>
                      <span className="toSeperator">-</span>
                      <div className="maxprice">
                        <select
                          name="maxPrice"
                          className="select js-example-basic-single2 pricefilterss select2-hidden-accessible"
                          title="Max Price"
                          onChange={(e) => setMaxprice(e.target.value)}
                          onClick={(e) => {
                            if (e.target.value !== "") {
                              setpriceShow(false);
                            }
                          }}
                        >
                          <option
                            value=""
                            data-select2-id="select2-data-8-evv4"
                          >
                            Max Price
                          </option>
                          <option value="0">Request Price</option>
                          <option
                            onClick={() =>
                              setTimeout(() => {
                                setpriceShow(false);
                              }, 30)
                            }
                            value="50000"
                          >
                            €50,000
                          </option>
                          <option value="60000">€60,000</option>
                          <option value="70000">€70,000</option>
                          <option value="80000">€80,000</option>
                          <option value="90000">€90,000</option>
                          <option value="100000">€100,000</option>
                          <option value="110000">€110,000</option>
                          <option value="120000">€120,000</option>
                          <option value="125000">€125,000</option>
                          <option value="130000">€130,000</option>
                          <option value="140000">€140,000</option>
                          <option value="150000">€150,000</option>
                          <option value="160000">€160,000</option>
                          <option value="170000">€170,000</option>
                          <option value="175000">€175,000</option>
                          <option value="180000">€180,000</option>
                          <option value="190000">€190,000</option>
                          <option value="200000">€200,000</option>
                          <option value="210000">€210,000</option>
                          <option value="220000">€220,000</option>
                          <option value="230000">€230,000</option>
                          <option value="240000">€240,000</option>
                          <option value="250000">€250,000</option>
                          <option value="260000">€260,000</option>
                          <option value="270000">€270,000</option>
                          <option value="280000">€280,000</option>
                          <option value="290000">€290,000</option>
                          <option value="300000">€300,000</option>
                          <option value="325000">€325,000</option>
                          <option value="350000">€350,000</option>
                          <option value="375000">€375,000</option>
                          <option value="400000">€400,000</option>
                          <option value="425000">€425,000</option>
                          <option value="450000">€450,000</option>
                          <option value="475000">€475,000</option>
                          <option value="500000">€500,000</option>
                          <option value="550000">€550,000</option>
                          <option value="600000">€600,000</option>
                          <option value="650000">€650,000</option>
                          <option value="700000">€700,000</option>
                          <option value="800000">€800,000</option>
                          <option value="900000">€900,000</option>
                          <option value="1000000">€1,000,000</option>
                          <option value="1250000">€1,250,000</option>
                          <option value="1500000">€1,500,000</option>
                          <option value="1750000">€1,750,000</option>
                          <option value="2000000">€2,000,000</option>
                          <option value="2500000">€2,500,000</option>
                          <option value="3000000">€3,000,000</option>
                          <option value="4000000">€4,000,000</option>
                          <option value="5000000">€5,000,000</option>
                          <option value="7500000">€7,500,000</option>
                          <option value="10000000">€10,000,000</option>
                          <option value="15000000">€15,000,000</option>
                          <option value="20000000">€20,000,000</option>
                          <option value="">Max Price</option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="bedsearch">
                <div
                  className="beds-btn"
                  onClick={() => {
                    setbedShow(!bedShow);
                    setpriceShow(false);

                    setfilterShow(false);
                    setpropertyTypeShow(false);
                  }}
                >
                  <span className="beds-btn-text" id="beds-btn-text">
                    {minbed !== ""
                      ? "" + minbed + "-" + "" + maxbed
                      : "Any Bed"}
                  </span>
                </div>
                <div className="min-max-beds">
                  {bedShow && bedShow == true ? (
                    <div className="innerbeds active">
                      <div className="minBedrooms">
                        <select
                          name="minBedrooms"
                          className="js-example-basic-single2 bedroomsfilterss select2-hidden-accessible"
                          id="minBedrooms"
                          title="Min Beds"
                          onChange={(e) => setminbed(e.target.value)}
                        >
                          <option value="" name="">
                            Min Beds
                          </option>
                          <option value="Studio" name="Studio">
                            Studio
                          </option>
                          <option value="1" name="1 Bed">
                            1 Bed
                          </option>
                          <option value="2" name="2 Beds">
                            2 Bed
                          </option>
                          <option value="3" name="3 Beds">
                            3 Bed
                          </option>
                          <option value="4" name="4 Beds">
                            4 Bed
                          </option>
                          <option value="5" name="5 Beds">
                            5 Bed
                          </option>
                          <option value="6" name="6 Beds">
                            6 Bed
                          </option>
                          <option value="7" name="7 Beds">
                            7 Bed
                          </option>
                          <option value="8" name="8 Beds">
                            8 Bed
                          </option>
                          <option value="9" name="9 Beds">
                            9 Bed
                          </option>
                          <option value="10" name="10 Beds">
                            10 Bed
                          </option>
                          <option value="" name="Any Beds">
                            Min Beds
                          </option>
                        </select>
                      </div>
                      <span className="toSeperator">-</span>
                      <div className="maxBedrooms">
                        <select
                          name="maxBedrooms"
                          className="js-example-basic-single2 bedroomsfilterss select2-hidden-accessible"
                          title="Max Beds"
                          data-select2-id="select2-data-11-43qq"
                          tabindex="-1"
                          aria-hidden="true"
                          onChange={(e) => {
                            setmaxbed(e.target.value);
                          }}
                          onClick={(e) => {
                            if (e.target.value !== "") {
                              setbedShow(false);
                            }
                          }}
                        >
                          <option
                            value=""
                            name=""
                            data-select2-id="select2-data-13-3tqy"
                          >
                            Max Beds
                          </option>
                          <option value="Studio" name="Studio">
                            Studio
                          </option>
                          <option value="1" name="1 Bed">
                            1 Bed
                          </option>
                          <option value="2" name="2 Beds">
                            2 Bed
                          </option>
                          <option value="3" name="3 Beds">
                            3 Bed
                          </option>
                          <option value="4" name="4 Beds">
                            4 Bed
                          </option>
                          <option value="5" name="5 Beds">
                            5 Bed
                          </option>
                          <option value="6" name="6 Beds">
                            6 Bed
                          </option>
                          <option value="7" name="7 Beds">
                            7 Bed
                          </option>
                          <option value="8" name="8 Beds">
                            8 Bed
                          </option>
                          <option value="9" name="9 Beds">
                            9 Bed
                          </option>
                          <option value="10" name="10 Beds">
                            10 Bed
                          </option>
                          <option value="" name="Any Beds">
                            Max Beds
                          </option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="form-group" style={{ zIndex: "111" }}>
              <div className="propertytypesearch">
                <span
                  className="c-select"
                  onClick={() => {
                    setpropertyTypeShow(!propertyTypeShow);
                    setfilterShow(false);
                    setShow(false);
                  }}
                >
                  Property type
                </span>
                {propertyTypeShow && propertyTypeShow == true ? (
                  <div className="propertytypelist active">
                    <ul className="propertytypelist-list">
                      <li>
                        <input
                          type="checkbox"
                          value="Apartment"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Apartment")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/05/apartment.png" />
                          <label>Apartment</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Country House"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Country House")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/country-house.png" />
                          <label>Country House</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Finca"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Finca")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/finca.png" />
                          <label>Finca</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Ground Flat"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Ground Flat")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/ground-flat.png" />
                          <label>Ground Flat</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="House"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "House")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/05/house.png" />
                          <label>House</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Land"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Land")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/05/land.png" />
                          <label>Land</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Penthouse"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Penthouse")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/penthouse.png" />
                          <label>Penthouse</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Town House"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Town House")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/townhouse.png" />
                          <label>Town House</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Villa"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Villa")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src="https://bellaislas.com/wp-content/uploads/2021/08/villa.png" />
                          <label>Villa</label>
                        </div>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value="Garage"
                          onChange={(e) => handleCheckProperty(e)}
                          className="checkboxfilter"
                          defaultChecked={
                            apartmant.filter((vlu) => vlu == "Garage")[0]
                              ? true
                              : false
                          }
                        />
                        <div className="customtypedata">
                          <img src={require("../../../assets/images/garage.png")} style={{ opacity: "1" }} />
                          <label>Garage</label>
                        </div>
                      </li>
                      <div className="doneFilter">
                        <button
                          type="button"
                          id="done_propertytype"
                          onClick={() => {
                            setpriceShow(false);
                            setfilterShow(false);
                            setbedShow(false);
                            propertyTypeSubmit();
                          }}
                        >
                          Done
                        </button>
                      </div>
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group" style={{ zIndex: "111" }}>
              <div className="customfiltersearch">
                <span
                  className="c-select"
                  onClick={() => {
                    setfilterShow(!filterShow);
                    setpropertyTypeShow(false);
                    setShow(false);
                  }}
                >
                  Filters{" "}
                </span>
                {filterShow && filterShow == true ? (
                  <div className="mainfiltersection active">
                    <div className="datefilter-Outer">
                      <div className="datefilter">
                        <div className="addedSite__select">
                          <label>Added to Site:</label>
                          <div className="select-outer">
                            <select
                              name="addedToSite"
                              onChange={(e) => setadded_site(e.target.value)}
                              className="select"
                              value={added_site}
                            >
                              <option value="">Anytime</option>
                              <option value="1">Last 24 hours</option>
                              <option value="3">Last 3 days</option>
                              <option value="7">Last 7 days</option>
                              <option value="14">Last 14 days</option>
                            </select>
                          </div>
                        </div>

                        <div className="search-subtype-property">
                          <label>Property Subtype:</label>
                          <select
                            name="subtype"
                            id="subtype-propertyy"
                            className="subtype-propertyy"
                            onChange={(e) => setsubtype(e.target.value)}
                            value={subtype}
                          >
                            <option value="">Select</option>
                            <option value="New Build">New Build</option>
                            <option value="Exclusive">Exclusive</option>
                            <option value="Tourist License">
                              Tourist License
                            </option>
                            <option value="New Project">New Project</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="tagsfilter-Outer">
                      <div className="tagsfilter">
                        <ul className="taglist-list" id="taglist-list">
                          <li>
                            <input
                              type="checkbox"
                              value="airplay"
                              onChange={(e) => handlefilterProperty(e)}
                              id="airplay"
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter(
                                  (vlu) => vlu == "airplay"
                                )[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <span className="material-symbols-outlined">
                                airplay
                              </span>
                              <label>Air conditioning</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Beach"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter((vlu) => vlu == "Beach")[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <span class="material-symbols-outlined">
                                beach_access
                              </span>
                              <label>Beach</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Disabled Access"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter(
                                  (vlu) => vlu == "Disabled Access"
                                )[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <i className="fa fa-wheelchair"></i>
                              <label>Disabled Access</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Garage"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter((vlu) => vlu == "Garage")[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="garage_logo customtypedata">
                              <i className="fa fa-car"></i>
                              <label>Garage</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Lift"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter((vlu) => vlu == "Lift")[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <span className="material-symbols-outlined">
                                forklift
                              </span>
                              <label>Lift</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Parking"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter(
                                  (vlu) => vlu == "Parking"
                                )[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <i className="fa fa-parking"></i>
                              <label>Parking</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Sea Views"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter(
                                  (vlu) => vlu == "Sea Views"
                                )[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <span className="material-symbols-outlined">
                                visibility
                              </span>
                              <label>Sea Views</label>
                            </div>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              value="Swimming Pool"
                              onChange={(e) => handlefilterProperty(e)}
                              name="propertytagstype[]"
                              className="checkboxtagfilter"
                              defaultChecked={
                                filterSearch.filter(
                                  (vlu) => vlu == "Swimming Pool"
                                )[0]
                                  ? true
                                  : false
                              }
                            />
                            <div className="customtypedata">
                              <i className="fa fa-swimmer"></i>
                              <label>Swimming Pool</label>
                            </div>
                          </li>
                          <div className="doneFilter">
                            <button
                              type="button"
                              id="done_filter"
                              onClick={() => {
                                setpriceShow(false);
                                setfilterShow(false);
                                setbedShow(false);
                                setpropertyTypeShow(false);
                                searchfilterHandle();
                              }}
                            >
                              Done
                            </button>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group">
              <button
                type="button"
                className="save-search"
                onClick={(e) => {
                  fetchproperty();
                  handleSearch(e);
                }}
              >
                Save Search
              </button>
            </div>
          </form>
          <p style={{ textAlign: "center", padding: "10px 0 0", fontSize: "17px", color: "red" }}>{savesearcherror}</p>

          {dialogShow && dialogShow == true ? (
            <div id="save-search-div" className="save-search-div active">
              <h2>Save your search and alerts</h2>
              <form action="" id="save-search-form" method="post">
                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
                  <label>What would you like to call this search?</label>
                  <input
                    aria-invalid="true"
                    id="save-search-name"
                    name="save-search-name"
                    value={searchName}
                    onChange={(e) => setsearchName(e.target.value)}
                    type="text"
                    required=""
                    className="MuiInput-input"
                  />
                  <label>Get alerts on my search criteria</label>
                  <select
                    name="alerts-frequency"
                    required=""
                    id="alerts-frequency"
                    value={alerts}
                    onChange={(e) => setAlerts(e.target.value)}
                  >
                    <option value="daily">Daily</option>
                    <option value="three-days">Every 3 Days</option>
                    <option value="weekly">Weekly</option>
                    <option value="never">Never</option>
                    <option value="instant">Instant</option>
                  </select>
                  {login && login == true ? (
                    <button
                      type="button"
                      name="submit"
                      id="save-search-submit"
                      onClick={() => saveSearch()}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      type="button"
                      name="submit"
                      id="save-search-submit"
                      onClick={() => navigate("/signin")}
                      style={{ width: "110px", maxWidth: "110px" }}
                    >
                      Register/Login
                    </button>
                  )}

                  <button
                    type="button"
                    id="cancel-search-submit"
                    style={{ margin: "0 5px" }}
                    onClick={(e) => handleSearch(e)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <div class="responsive_filter" onClick={()=>setMobileMenu(!MobileMenu)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="22"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
              ></path>
            </svg>
          </div>

          <div className="bookmark-btn-outer">
            {login && login == true ? (
              <>
                <button
                  type="button"
                  id="bookmark-btn"
                  onClick={() => setShowbookmark(!Showbookmark)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    {" "}
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                    ></path>{" "}
                  </svg>
                </button>
                <span className="bookmark-coumt">{data.length}</span>
              </>
            ) : (
              ""
            )}
          </div>
          {Showbookmark && Showbookmark == true ? (
            <div id="bookmarks" className="bookmarks active">
              <h4>
                <div className="bookmark-btn-outer">
                  <button
                    type="button"
                    id="bookmark-btn"
                    onClick={() => setShowbookmark(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      {" "}
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                      ></path>{" "}
                    </svg>
                  </button>
                  <span className="bookmark-coumt">{data.length}</span>
                </div>{" "}
                Saved searches:
              </h4>
              <p>All your saved searches in one place</p>
              <table id="tbUser" className="table_resp table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Price Range</th>
                    <th>Beds</th>
                    <th>Alert Frequency</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    // { console.log("jjjjjj", item) }
                    <tr colspan="6" key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.locations}</td>
                      <td>
                        {nf.format(item.min_price)}-{nf.format(item.max_price)}
                      </td>
                      <td>
                        {item.min_beds}-{item.max_beds}
                      </td>
                      <td>
                        <input
                          type="hidden"
                          name="saved-search-id"
                          id="saved-search-id"
                          class="saved-search-id"
                          value="49"
                        />
                        <select
                          name="alerts-frequency"
                          required=""
                          class="form-control"
                          id="alerts-frequency"
                        >
                          <option value="daily" selected="selected">
                            {item.alert}
                          </option>
                          <option value="three-days">Every 3 Days</option>
                          <option value="weekly">Weekly</option>
                          <option value="never">Never</option>
                          <option value="instant">Instant</option>
                        </select>
                      </td>
                      <td>
                        <a class="frq-link" href="/">
                          Search
                        </a>
                      </td>
                      <td>
                        <button
                          class="frqDeleteBtn"
                          data-id="49"
                          type="submit"
                          onClick={() => delateSearch(item.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#000"
                          >
                            {" "}
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>{" "}
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      {/* {    <!-- top fillter section end here -->} */}

      {loading !== true ? (
        <div className="loader_set">
          {" "}
          <img src={loaderImg} alt="loading..." />{" "}
        </div>
      ) : (
        <>
          <BuySection
            details={details}
            town={searchValue}
            premiumDetails={premiumDetails}
            setshorting={setshorting}
            shorting={shorting}

            setshortinghigh={setshortinghigh}
          />
          <div className="pagination_div">
            <button
              onClick={handlePrevbtn}
              disabled={currentPage == pages[0] ? true : false}
            >
              Prev
            </button>
            <p style={{ display: "flex" }}>
              {pages.map((number, index) => {
                if (index > currentPage - 6 && index < currentPage + 4) {
                  return (
                    <span
                      key={number}
                      id={number}
                      onClick={() => setcurrentPage(number)}
                      className={`${currentPage == number ? "active_page" : ""
                        }`}

                    >
                      {number}{" "}
                    </span>
                  );
                }
              })}
              <span>
                ...
              </span>
            </p>
            <button
              onClick={handleNextbtn}
              disabled={currentPage == pages[pages.length - 1] ? true : false}
            >
              Next
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default Searchbar;
