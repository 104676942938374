import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../baseUrl";
import loaderImg from "../../../assets/images/loader.svg";
import moment from "moment";
import swal from "sweetalert";
function PremiumFeatured() {
  const [premium, setpremium] = useState([]);

  let nf = new Intl.NumberFormat();
  const [entries, setEntries] = useState();
  const [popup, setpopup] = useState(false);
  const [data, setData] = useState(1);
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const token = JSON.parse(localStorage.getItem("token"));
  const [loading, setloading] = useState(false);

  const [agentlist, setagentlist] = useState([]);

  const [searhValue, setSearhValue] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [currentPage2, setcurrentPage2] = useState(1);

  const premiumpagination = [];
  const lastpage = premium.property?.last_page;
  for (let i = 0; i < Math.ceil(lastpage); i++) {
    premiumpagination.push(i + 1);
  }
  console.log(
    "premium>>>>>",
    premium?.property?.last_page,
    ",,,,,",
    premiumpagination
  );

  const myprimiumProperty = async () => {
    setpremium([]);
    await axios
      .get(baseUrl + `/agent/premium-features?page=${currentPage > currentPage2 ? currentPage : currentPage2 ? currentPage2 : "1"}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          setpremium(res.data?.data);
          if (res.data?.data.credit?.credit == 0 && data == 4) {
            setpopup(true);
          }
        }
        setloading(true);

      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  const [shorted, setshorted] = useState({ shorted: "id", reversed: false });
  const shortedbypostcode = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      premium.premium?.data?.sort(
        (valuA, valuB) => valuA.data?.postcode - valuB.data?.postcode
      );
    } else {
      premium.premium?.data?.sort(
        (valuA, valuB) => valuB.data?.postcode - valuA.data?.postcode
      );
    }
  };
  const shortedbyId = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      premium.property?.data?.sort(
        (valuA, valuB) => valuA.data.id - valuB.data.id
      );
    } else {
      premium.property?.data?.sort(
        (valuA, valuB) => valuB.data.id - valuA.data.id
      );
    }
  };
  const shortedbyPriceAvialble = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      premium.property?.data?.sort(
        (valuA, valuB) => valuA.data?.price - valuB.data?.price
      );
    } else {
      premium.property?.data?.sort(
        (valuA, valuB) => valuB.data?.price - valuA.data?.price
      );
    }
  };

  const shortedPrice = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      agentlist.data.sort(
        (valuA, valuB) => valuA.data.price - valuB.data.price
      );
    } else {
      agentlist.data.sort(
        (valuA, valuB) => valuB.data.price - valuA.data.price
      );
    }
  };
  const shortedPricePremium = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      premium.premium?.data?.sort(
        (valuA, valuB) => valuA.data.price - valuB.data.price
      );
    } else {
      premium.premium?.data?.sort(
        (valuA, valuB) => valuB.data.price - valuA.data.price
      );
    }
  };
  const shortedbyIdPremium = () => {
    setshorted({ shorted: "id", reversed: !shorted.reversed });
    if (shorted.reversed) {
      premium.premium?.data?.sort(
        (valuA, valuB) => valuA.data.id - valuB.data.id
      );
    } else {
      premium.premium?.data?.sort(
        (valuA, valuB) => valuB.data.id - valuA.data.id
      );
    }
  };

  const addpremium = async (id) => {
    if (premium.credit?.credit == 0 && data == 4) {
      setpopup(true);
      swal({
        title: "Bella Islas says",
        text: "You have No Created !",
        icon: "error",
        button: "OK",
        // timer: 3000,
      });
    } else {
      await axios
        .get(baseUrl + `/agent/add-premium?id=${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status == 200) {
            if (res?.data.message == "Sorry You don't have any credits.") {
              swal({
                title: "Bella Islas says",
                text: res.data.message,
                icon: "error",
                button: "OK",
                // timer: 3000,
              });
            } else {
              swal({
                title: "Bella Islas says",
                text: res.data.message,
                icon: "success",
                button: "OK",
                // timer: 3000,
              });
            }

            //setpremium(res.data?.data);
            myprimiumProperty();
            // console.log(res);
            // setloading(true);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            setloading(true);
          }
        });
    }
  };

  const deletepremium = async (id) => {
    await axios
      .get(baseUrl + `/agent/delete-premium?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          //setpremium(res.data?.data);
          swal({
            title: "Bella Islas says",
            text: res.data.message,
            // icon: "success",
            button: "OK",
            // timer: 3000,
          });
          myprimiumProperty();
          console.log(res);
          // setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
          swal({
            title: "Bella Islas says",
            text: error.message,
            icon: "error",
            button: "OK",
            // timer: 3000,
          });
        }
      });
  };

  const [premiumfilter, setpremiumfilter] = useState("");

  const premiumfilterHandle = (e) => {
    setpremiumfilter(e.target.value);
  };
  const [performanceSearchValue, setperformanceSearchValue] = useState("");
  const [totalListing, settotalListing] = useState("");
  const performancefilterHandle = (e) => {
    setperformanceSearchValue(e.target.value);
  };

  useEffect(() => {
    myprimiumProperty();
  }, [currentPage, currentPage2]);







  const [itemsPerPage, setitemsPerPage] = useState(20);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];
  const pages2 = [];
  for (let i = 1; i <= Math.ceil(premium?.property?.last_page); i++) {
    pages.push(i);
  }
  for (let i = 1; i <= Math.ceil(premium.premium?.last_page); i++) {
    pages2.push(i);
  }
  console.log(pages);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }








  const handleNextbtn2 = () => {
    setcurrentPage2(currentPage2 + 1);

    if (currentPage2 + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn2 = () => {
    setcurrentPage2(currentPage2 - 1);

    if ((currentPage2 - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };




  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", premium);

  return (
    <>
      {loading == false ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img src={loaderImg} style={{ width: "100px" }} />
        </div>
      ) : (
        <div
          class="main-wrapper availableProperty"
          style={{ display: "block" }}
          id="menuFour"
        >
          <h2>Premium Featured Listing</h2>
          <div className="credit_design">
            Credit Left :{" "}
            {premium.credit?.credit ? premium.credit?.credit : "0"} { }
          </div>
          <div class="tableFilter flex">
            <div class="dataTables_length" id="datatable-tabletools_length">
              <label>
                Show
                <select
                  name="datatable-tabletools_length"
                  aria-controls="datatable-tabletools"
                  class=""
                  onChange={(e) => setEntries(e.target.value)}
                >
                  <option>5</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option>All</option>
                </select>{" "}
                entries
              </label>
            </div>
            <div class="searchFilter">
              <form action="">
                <div class="form_group">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => premiumfilterHandle(e)}
                  />
                </div>
              </form>
            </div>
          </div>
          <div class="tableWrap">
            <table
              className="table1 tablesorter tablesorter-blue tablesorter72d6f05d2d007"
              data-sortlist="[[0,0],[2,0]]"
            >
              <thead>
                <tr className="tablesorter-headerRow">
                  <th
                    className="tablesorter-header tablesorter-headerAsc"
                    onClick={() => shortedbyIdPremium()}
                  >
                    Property
                  </th>
                  <th
                    className="tablesorter-header tablesorter-headerAsc"
                    onClick={shortedbypostcode}
                  >
                    Postcode
                  </th>
                  <th
                    className="tablesorter-header tablesorter-headerAsc"
                    onClick={() => shortedPricePremium()}
                  >
                    Price
                  </th>
                  <th className="tablesorter-header tablesorter-headerAsc">
                    First Visible
                  </th>
                  <th className="tablesorter-header tablesorter-headerAsc">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {premium?.premium?.data == [] ? (
                  "No Data"
                ) : (
                  <>
                    {premium?.premium?.data
                      ?.filter((item) => {
                        if (premiumfilter == "") {
                          return item;
                        } else {
                          return item.postcode.includes(premiumfilter);
                        }
                      })
                      ?.slice(0, entries ? entries : 5)
                      ?.map((item, index) => {
                        console.log(item);
                        return (
                          <tr key={item.id}>
                            <td>
                              <div class="propertyOuter">
                                <div class="property-img">
                                  <img src={item.data.images?.image[0].url} />
                                </div>
                                <div class="property-name">
                                  {item.data?.town
                                    ? item.data?.town?.replace(
                                      /[\[,"\]']+/g,
                                      "  "
                                    )
                                    : "Not Added"}
                                </div>
                              </div>
                            </td>
                            <td>
                              {item?.postcode ? item?.postcode : "Not Added"}
                            </td>
                            <td>
                              {" "}
                              €{" "}
                              {item?.price
                                ? nf.format(item?.price)
                                : "Not Added"}
                            </td>
                            <td>
                              {" "}
                              {item.data?.date
                                ? moment(item.data?.date).format("DD MMM, YYYY")
                                : "Not Added"}
                            </td>

                            <td>
                              <div class="table-controls">
                                <button
                                  class="remove-property-btn"
                                  onClick={() => deletepremium(item?.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke="#666"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"
                                    ></path>
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>

          <div className="bottomPagenation">
            <div class="dataTables_info" id="datatable-tabletools_info">
              Showing{" "}
              {premium?.premium?.data ? premium?.premium?.data?.length > currentPage2 * 5 ? currentPage2 * 5 : ((currentPage2 * 5) - 5) + premium?.premium?.data?.length
                : "0"}{" "}

              of {premium?.premium?.total
                ? premium?.premium?.total
                : "0"} entries
            </div>
            <div className="pagenationTable">
              {/* <button>First</button> */}
              <button
                onClick={handlePrevbtn2}
                disabled={currentPage2 == 1 ? true : false}
              >
                « Previous
              </button>
              {pages2.map((number, index) => {
                if (index > currentPage2 - 3 && index < currentPage2 + 3) {
                  return (
                    <><p>
                      <span
                        key={number}
                        id={number}
                        onClick={() => setcurrentPage2(number)}
                        className={`${currentPage2 == number ? "active_page" : ""
                          }`}
                      >
                        {number}{" "}
                      </span>
                    </p>

                    </>
                  );
                }
              })}
              <button
                onClick={handleNextbtn2}
                disabled={currentPage2 == (pages2.length) ? true : false}
              >
                Next »
              </button>{" "}
              {/* <button>Last</button> */}
            </div>
          </div>

          <h2>Available Properties</h2>

          <div class="tableWrap">
            <table
              className="table1 tablesorter tablesorter-blue tablesorter72d6f05d2d007"
              data-sortlist="[[0,0],[2,0]]"
            >
              <thead>
                <tr className="tablesorter-headerRow">
                  <th
                    className="tablesorter-header tablesorter-headerAsc"
                    onClick={() => shortedbyId()}
                  >
                    Property
                  </th>
                  <th
                    className="tablesorter-header tablesorter-headerAsc"
                    onClick={() => shortedbyId()}
                  >
                    Postcode
                  </th>
                  <th
                    className="tablesorter-header tablesorter-headerAsc"
                    onClick={() => shortedbyPriceAvialble()}
                  >
                    Price
                  </th>
                  <th className="tablesorter-header tablesorter-headerAsc">
                    First Visible
                  </th>
                  <th className="tablesorter-header tablesorter-headerAsc">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {premium?.property ? (
                  premium?.property?.data
                    .filter((item) => {
                      if (searhValue == "") {
                        return item;
                      } else {
                        return item.postcode.includes(searhValue);
                      }
                    })
                    ?.slice(0, entries ? entries : 5)
                    ?.map((item, index) => {
                      //console.log(item);
                      return (
                        <tr key={item.id}>
                          <td>
                            <div class="propertyOuter">
                              <div class="property-img">
                                <img src={item.data.images?.image[0]?.url} />
                              </div>
                              <div class="property-name">
                                {item.data?.town
                                  ? item.data?.town?.replace(
                                    /[\[,"\]']+/g,
                                    "  "
                                  )
                                  : "Not Added"}
                              </div>
                            </div>
                          </td>
                          <td>
                            {item?.postcode ? item?.postcode : "Not Added"}
                          </td>
                          <td>
                            {" "}
                            €{" "}
                            {item?.price ? nf.format(item?.price) : "Not Added"}
                          </td>
                          <td>
                            {" "}
                            {item.data?.date
                              ? moment(item.data?.date).format("DD MMM, YYYY")
                              : "Not Added"}
                          </td>

                          <td>
                            <div class="table-controls">
                              <button
                                class="add-property-btn"
                                onClick={() => {
                                  addpremium(item?.id);
                                }}
                              >
                                {" "}
                                <span class="add-icon">
                                  <i>+</i>
                                </span>{" "}
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td>No Data</td>
                    <td>No Data</td>
                    <td>No Data</td>
                    <td>No Data</td>
                    <td>No Data</td>
                    <td>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* <div class="dataTables_info" id="datatable-tabletools_info">
                      Showing 0 to 0 of 0 entries
                    </div> */}

          <div className="bottomPagenation">
            <div class="dataTables_info" id="datatable-tabletools_info">
              Showing{" "}
              {premium?.property?.data ? premium?.property?.data?.length > currentPage2 * 5 ? currentPage2 * 5 : ((currentPage2 * 5) - 5) + premium?.property?.data?.length
                : "0"}{" "}
              of {premium?.property?.total
                ? premium?.property?.total
                : "0"} entries
              {" "}
            </div>
            <div className="pagenationTable">
              {/* <button>First</button> */}
              <button
                onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false}
              >
                « Previous
              </button>
              {pages.map((number, index) => {
                if (index > currentPage - 3 && index < currentPage + 3) {
                  return (
                    <><p>
                      <span
                        key={number}
                        id={number}
                        onClick={() => setcurrentPage(number)}
                        className={`${currentPage == number ? "active_page" : ""
                          }`}
                      >
                        {number}{" "}
                      </span>
                    </p>

                    </>
                  );
                }
              })}
                {
                  currentPage != premium?.property?.last_page?
              <p>
                  <span>
                  ...
                </span>

             <span onClick={()=> setcurrentPage(premium?.property?.last_page)}>{premium?.property?.last_page}</span>
              </p> :""
                }
               
              <button
                onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              >
                Next »
              </button>{" "}
              {/* <button>Last</button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PremiumFeatured;
